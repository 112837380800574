import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  TextField,
  FormControlLabel,
  FormGroup,
  Modal,
  Box,
  Switch,
} from "@mui/material";
import "./bank-modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit,faAdd, faMinus, faRedo } from "@fortawesome/free-solid-svg-icons";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import ButtonUI from "../../../components/ui/button/button";
import { useDispatch, useSelector } from "react-redux";
import { svUpdateBankInformation } from "../../../services/bankinfo.service";

function BankEditModal({ bankEdit, open, setOpen, setRefreshData }) {
  const { t } = useTranslation("prepaidcate-page");
  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };
  const [isError, setIsError] = useState({
    bank_name: false,
    bank_account: false,
    bank_number: false,
    thumbnail: false,
  });
  const language = useSelector((state) => state.app.language);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  const [previews, setPreviews] = useState(thumbnailDefault);
  const [editData, setEditData] = useState(bankEdit);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = uploadPath + bankEdit.bank_image;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });
  }, []);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, bank_image: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      bank_name: editData.bank_name.trim().length < 1,
      bank_account: editData.bank_account.trim().length < 1,
      bank_number:
        editData.bank_number.trim().length < 10 ||
        editData.bank_number.trim().length > 15,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("thumbnail_name", editData.bank_image);
      }
      formData.append("id", editData.id)
      formData.append("bank_image", editData.bank_image || "");
      formData.append("bank_name", editData.bank_name || "");
      formData.append("bank_account", editData.bank_account || "");
      formData.append("bank_number", editData.bank_number || "");
      formData.append("display", editData.display ? 1 : 0);
      formData.append("priority", editData.priority);
      /* Display the key/value pairs*/
    /*  for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      return false;*/

      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdateBankInformation(editData.id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };
  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="bank-modal-title"
        aria-describedby="bank-modal-description"
      >
        <Box className="bank-edit-modal">
          <section id="bank-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขข้อมูลธนาคาร")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting ${
                      isError.thumbnail ? "error" : ""
                    }`}
                    label={t("รูปธนาคาร")}
                  >
                    <PreviewImageUI
                      className="edit-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                  </FieldsetUI>

                  <div className="bank-details">
                    <FieldsetUI
                      className={"bank-details-title"}
                      label={t("รายละเอียด")}
                    >
                      <div className="input-full">
                        <TextField
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                bank_name: e.target.value,
                              };
                            })
                          }
                          value={editData.bank_name}
                          className="text-field-custom"
                          fullWidth={true}
                          error={isError.bank_name}
                          id="ad-bank_name"
                          label="ชื่อธนาคาร"
                          size="small"
                        />
                      </div>
                      <div className="input-full">
                        <TextField
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                bank_account: e.target.value,
                              };
                            })
                          }
                          value={editData.bank_account}
                          className="text-field-custom"
                          fullWidth={true}
                          error={isError.bank_account}
                          id="ad-bank_account"
                          label="บัญชีธนาคาร"
                          size="small"
                        />
                      </div>
                      <div className="input-full">
                        <TextField
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                bank_number: e.target.value,
                              };
                            })
                          }
                          value={editData.bank_number}
                          className="text-field-custom"
                          fullWidth={true}
                          error={isError.bank_number}
                          id="ad-bank_number"
                          label="เลขบัญชีธนาคาร"
                          size="small"
                        />
                      </div>
                    </FieldsetUI>
                    <div className="group-settings">
                      <h3 className="bank-detail-title">
                        {t("ตั้งค่าธนาคาร")}
                      </h3>
                      <div className="setting-controls">
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {editData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      display: e.target.checked,
                                    })
                                  }
                                  checked={editData.display ? true : false}
                                />
                              }
                              label={t("การแสดงผล")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default BankEditModal;
