import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faYinYang, faRedo } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import { svPredictSumber } from "../../../services/productServices/berpredict.service";

import DataGridPredictSumber from "./datagrid/DataGridPredictSumber";
import PredictSumberEditModal from "./PredictSumberEditModal";

import "./predictsumber.scss";

export default function PredictSumberPage() {
  const { t } = useTranslation("predictsumber-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [spawnActive, setSpawnActive] = useState(true);
  const dispatch = useDispatch();

  const [predictSumberData, setPredictSumberData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svPredictSumber().then((res) => {
      if (res.status === 200) {
        const result = res.data.data;
        setPredictSumberData(result.sumbers);
        setFilteredData(result.sumbers);
      } else {
        console.log(res);
      }

      setSpawnActive(false);
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchData = (e) => {
    const text = e.target.value.toLowerCase();
    if (!text || text.trim() === "") {
      setFilteredData(predictSumberData);
    } else {
      const sumber = predictSumberData?.filter(
        (item) => item && (item.predict_sum.toString().includes(text) ||
        item.predict_name.includes(text) ||
        item.predict_description.includes(text))
      );
      setFilteredData(sumber);
    }
  };

  return (
    <section id="predictsumber-page">
      <HeadPageComponent
        h1={t("ผลรวมเบอร์")}
        icon={<FontAwesomeIcon icon={faYinYang} />}
        breadcrums={[{ title: "ผลรวมเบอร์", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <TextField
              label="ค้นหาผลรวม"
              id="outlined-size-small"
              size="small"
              onInput={(e) => searchData(e)}
            />
          </div>
        </div>

        {/* Package Table Here */}
        <DataGridPredictSumber
          setRefreshData={setRefreshData}
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setProductEdit={setProductEdit}
          setFilteredData={setFilteredData}
          setPredictSumberData={setPredictSumberData}
        />

        {/* sumber Modal Edit Here */}
        {openEdit && (
          <PredictSumberEditModal
            setRefreshData={setRefreshData}
            productEdit={productEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            setFilteredData={setFilteredData}
            setPredictSumberData={setPredictSumberData}
          />
        )}
      </div>
    </section>
  );
}
