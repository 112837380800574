import axios from "axios";

/* cate */
export const svPrepaidCates = () => {
  return axios
    .get(`prepaidcate/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreatePrepaidCate = (formData) => {
  return axios
    .post(`prepaidcate/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdatePrepaidCate = (id, formData) => {
  return axios
    .post(`prepaidcate/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeletePrepaidCate = (id) => {
  return axios
    .delete(`prepaidcate/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

/* network */
export const svPrepaidNet = () => {
  return axios
    .get(`prepaidnet/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreatePreNetwork = (formData) => {
  return axios
    .post(`prepaidnet/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdatePreNetwork = (id,formData) => {
  return axios
    .post(`prepaidnet/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

/* sim */
export const svPrepaidSims = () => {
  return axios
    .get(`prepaidsim/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreatePrepaidSim = (formData) => {
  return axios
    .post(`prepaidsim/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdatePrepaidSim = (id, formData) => {
  return axios
    .post(`prepaidsim/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeletePrepaidSim = (id) => {
  return axios
    .delete(`prepaidsim/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};