/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";

import OrderDataGrid from "./dataGrid/OrderDataGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faEnvelope,
  faInbox,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import ButtonUI from "../../components/ui/button/button";
import MailServiceDataGrid from "./dataGrid/MailServiceDataGrid";
import MailContactDataGrid from "./dataGrid/MailContactDataGrid";

function Tables({ orderData, mailServiceData, mailContactData }) {
  const linkToOrder = () => {
    window.location.href = "/orders";
  };

  return (
    <>
      <div className="grid-container-1fr-table">
        <Card className="item">
          <div className="header">
            <figure className="header-title">
              <FontAwesomeIcon icon={faCartPlus} />
              <p>รายการคำสั่งซื้อ</p>
            </figure>
            <div className="header-link">
              <Link to="/orders">
                <ButtonUI
                  onClick={() => linkToOrder}
                  on="add"
                  isLoading={false}
                  icon={<FontAwesomeIcon icon={faSquarePlus} />}
                >
                  ดูเพิ่มเติม
                </ButtonUI>
              </Link>
            </div>
          </div>
          <OrderDataGrid orderData={orderData} />
        </Card>
      </div>
      <div className="grid-container-1fr-1fr-table">
        <Card className="item">
          <div className="header">
            <figure className="header-title">
              <FontAwesomeIcon icon={faInbox} />
              <p>รายการแจ้งรับบริการ</p>
            </figure>
            <div className="header-link">
              <Link to="/inbox">
                <ButtonUI
                  onClick={() => linkToOrder}
                  on="add"
                  isLoading={false}
                  icon={<FontAwesomeIcon icon={faSquarePlus} />}
                >
                  ดูเพิ่มเติม
                </ButtonUI>
              </Link>
            </div>
          </div>
          <MailServiceDataGrid mailServiceData={mailServiceData} />
        </Card>
        <Card className="item">
          <div className="header">
            <figure className="header-title">
              <FontAwesomeIcon icon={faEnvelope} />
              <p>ข้อความติดต่อ</p>
            </figure>
            <div className="header-link">
              <Link to="/contactmessage">
                <ButtonUI
                  onClick={() => linkToOrder}
                  on="add"
                  isLoading={false}
                  icon={<FontAwesomeIcon icon={faSquarePlus} />}
                >
                  ดูเพิ่มเติม
                </ButtonUI>
              </Link>
            </div>
          </div>
          <MailContactDataGrid mailContactData={mailContactData} />
        </Card>
      </div>
    </>
  );
}

export default Tables;
