import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faBoxOpen,
  faRedo,
  faFile,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import {
  getFiberService,
  uploadTermsPDF,
} from "../../../services/productServices/fiber.service";
import DataGridFiber from "./DataGridFiber";

import "./fiber.scss";
import FiberModalAdd from "./FiberModalAdd";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

export default function Fiber() {
  const fileTitle = "เลือก PDF ข้อกำหนดและเงื่อนไข Fiber";
  const dispatch = useDispatch();
  const { t } = useTranslation("fiber-page");
  const language = useSelector((state) => state.app.language);
  const [fiberCateData, setFiberCateData] = useState([]);
  const [fiberProductData, setFiberProductData] = useState([]);
  const [filteredProduct, setFiteredProduct] = useState([]);
  const [fiberCate, setFiberCate] = useState("");
  const [benefitData, setBenefitData] = useState([]);
  const [privilegeData, setPrivilegeData] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [maxPriority, setMaxPriority] = useState(0);
  const [fileError, setFileError] = useState(false);
  const [filename, setFilename] = useState(fileTitle);
  const [filePDF, setFilePDF] = useState(null);

  const inputFile = useRef();

  useEffect(() => {
    setFilePDF(null);
    setFilename(fileTitle);
    setFileError(false);
    inputFile.current.value = "";

    dispatch(appActions.isSpawnActive(true));
    getFiberService().then((res) => {
      if (res.status === 200) {
        const result = res.data.data;
        const priorities = result.fiberProduct?.map((p) => p.priority);
        const maxPriority = Math.max(...priorities);

        setMaxPriority(maxPriority);
        setFiberCateData(result.fiberCate);
        setFiberProductData(result.fiberProduct);
        setBenefitData(result.benefits);
        setPrivilegeData(result.privileges);

        if (fiberCate) {
          const product = result.fiberProduct?.filter(
            (p) => p.fiber_cate_id === fiberCate
          );
          setFiteredProduct(product);
        } else {
          setFiteredProduct(result.fiberProduct);
        }
      } else {
        setFiberCateData([]);
        setFiteredProduct([]);
      }

      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const handleFiberCateChange = (value) => {
    setFiberCate(value);
    if (!value || value === "") {
      setFiteredProduct(fiberProductData);
    } else {
      const product = fiberProductData?.filter(
        (p) => p.fiber_cate_id === value
      );
      setFiteredProduct(product);
    }
  };

  const fiberAddHandle = () => {
    setFiberCate("");
    handleFiberCateChange("");
    setOpenModalAdd(true);
  };

  const onSelectFile = (event) => {
    setFileError(false);
    const file = event.target.files[0];
    const filename = file.name;
    setFilePDF(file);
    setFilename(filename);
  };

  const uploadFile = () => {
    if (!filePDF) {
      setFileError(true);
      inputFile.current.value = "";
      return false;
    }
    const formData = new FormData();
    formData.append("upload", filePDF);
    formData.append("uploadPath", "upload/terms");
    formData.append("fileName", "termsfiber.pdf");

    dispatch(appActions.isSpawnActive(true));
    uploadTermsPDF(formData).then((res) => {
      if (res.status === 201) {
        SwalUI({
          status: true,
        });
      } else {
        SwalUI({
          status: false,
        });
      }

      setFilePDF(null);
      setFilename(fileTitle);
      setFileError(false);
      inputFile.current.value = "";

      dispatch(appActions.isSpawnActive(false));
    });
  };

  return (
    <section id="fiber-page">
      <HeadPageComponent
        h1={t("FiberPage")}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "FiberPage", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={() => setRefreshData(refreshData + 1)}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("Fetch")}
              </ButtonUI>
            </h2>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="select-filter-page">
                {t("selectFiberCate")}
              </InputLabel>
              <Select
                labelId="select-filter-page"
                autoWidth
                id="filter-page-control"
                label="Page Control"
                className="input-page"
                size="small"
                onChange={(e) => handleFiberCateChange(e.target.value)}
                value={fiberCate}
              >
                <MenuItem value="">{t("None")}</MenuItem>
                {fiberCateData &&
                  fiberCateData?.map((cate) => (
                    <MenuItem key={cate.id} value={cate.id}>
                      {" "}
                      {cate.cate_title}{" "}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div className="action-right">
              <h2 className="head-title-file">
                <label
                  htmlFor="file-upload"
                  className={`custom-file-upload ${fileError ? "error" : ""}`}
                >
                  <FontAwesomeIcon icon={faFile} /> {filename}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="application/pdf"
                  ref={inputFile}
                  onChange={onSelectFile}
                />
                <ButtonUI
                  className="btn-fileupload"
                  onClick={uploadFile}
                  on="save"
                  width="lg"
                  isLoading={false}
                  icon={<FontAwesomeIcon icon={faFileImport} />}
                >
                  {t("Upload File")}
                </ButtonUI>
              </h2>
              <ButtonUI
                onClick={fiberAddHandle}
                style={{ width: "120px" }}
                className="btn-add-fiber"
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("AddProduct")}
              </ButtonUI>
            </div>
          </div>
        </div>

        {/* Fiber Table Here */}
        <DataGridFiber
          filteredProduct={filteredProduct}
          benefitData={benefitData}
          privilegeData={privilegeData}
          fiberCateData={fiberCateData}
          fiberCate={fiberCate}
          setFiteredProduct={setFiteredProduct}
          setFiberProductData={setFiberProductData}
          setMaxPriority={setMaxPriority}
        />

        {/* Fiber Modal Add Here */}
        {openModalAdd && (
          <FiberModalAdd
            t={t}
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            benefitData={benefitData}
            privilegeData={privilegeData}
            fiberCateData={fiberCateData}
            fiberCate={fiberCate}
            setFiteredProduct={setFiteredProduct}
            setFiberProductData={setFiberProductData}
            maxPriority={maxPriority}
            setMaxPriority={setMaxPriority}
          />
        )}
      </div>
    </section>
  );
}
