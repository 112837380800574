import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HeadPageComponent from "../../../../components/layout/headpage/headpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRedo, faMobile } from "@fortawesome/free-solid-svg-icons";
import ButtonUI from "../../../../components/ui/button/button";
import { appActions } from "../../../../store/app-slice";
import PreNetworkAddModal from "./PreNetworkAddModal";
import { svPrepaidNet } from "../../../../services/productServices/prepaid.service";
import DataGridPreNet from "./DataGridPreNet";
import "../prepaidcate.scss";

function PreNetwork() {
  const dispatch = useDispatch();
  const { t } = useTranslation("prepaidcate-page");
  const [spawnActive, setSpawnActive] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [prepaidnetData,  setPrepaidnetData] = useState([]); 

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svPrepaidNet().then((res) => {
      if (res.status === 200) {
        setPrepaidnetData(res.data.prenetworks);
      }
      else {
         setPrepaidnetData([]);
      }
      setSpawnActive(false)
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData]);

  const refreshDataHandler = () => {
    setSpawnActive(true);
    setRefreshData(refreshData + 1);
  };

  const setOpenAddHandle = () => {
    setOpenAdd(true);
  };

  return (
    <section id="prepaidsim-page">
      <HeadPageComponent
        h1={t("ตั้งค่าเครือข่าย")}
        icon={<FontAwesomeIcon icon={faMobile} />}
        breadcrums={[{ title: "ตั้งค่าเครือข่าย", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshDataHandler}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <ButtonUI
              onClick={setOpenAddHandle}
              style={{ width: "120px" }}
              className="btn-add-prepaidcate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มหมวดหมู่")}
            </ButtonUI>
          </div>
        </div>

        {/* prepaid network Table Here */}
        <DataGridPreNet
        filteredData={prepaidnetData} 
        setRefreshData={setRefreshData} 
        />

          {/* prepaid network Modal Add Here */}
          {openAdd && (
          <PreNetworkAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            setRefreshData={setRefreshData}
            // prepaidCateData={prepaidCateData}
            // maxPriority={maxPriority}
          />
        )}

      </div>
    </section>
  )
}

export default PreNetwork