import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBoxOpen, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import { getPackageProductService } from "../../../services/productServices/package.service";

import DataGridPackage from "./datagrid/DataGridPackage";
import PackageAddModal from "./PackageAddModal";
import PackageEditModal from "./PackageEditModal";

export default function PackagePage() {
  const { t } = useTranslation("packagecate-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [cateId, setCateId] = useState("");
  const [packageType, setPackageType] = useState("");
  const dispatch = useDispatch();

  const [packageCateData, setPackageCateData] = useState([]);
  const [packageProductData, setPackageProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(true));
    getPackageProductService().then((res) => {
      if (res.status === 200) {
        const result = res.data.data;
        setPackageCateData(result.packageCates);
        setPackageProductData(result.packages);

      } else {
        console.log(res);
      }

      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  useEffect(() => {
    handlePackageCateChange(cateId);
  }, [packageType, packageProductData]);

  const handlePackageCateChange = (value) => {
    setCateId(value);
    const filtered = packageProductData.filter((p) => {
      const isPackageCateMatch = !value || value === "" || p.package_cate_id === value;
      const isPackageTypeMatch = !packageType || packageType === "" || p.type === packageType;
  
      return isPackageCateMatch && isPackageTypeMatch;
    });
  
    setFilteredData(filtered);
  };

  const setOpenAddHandle = () => {
    setPackageType("");
    handlePackageCateChange("");
    setOpenAdd(true);
  };

  return (
    <section id="packagecate-page">
      <HeadPageComponent
        h1={t("แพ็คเกจเสริม")}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "แพ็คเกจเสริม", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={() => setRefreshData(refreshData + 1)}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="select-filter-page">
                {t("หมวดหมู่แพ็คเกจ")}
              </InputLabel>
              <Select
                labelId="select-filter-page"
                autoWidth
                id="filter-page-control"
                label="Page Control"
                className="input-page"
                size="small"
                onChange={(e) => handlePackageCateChange(e.target.value)}
                value={cateId}
              >
                <MenuItem value="">{t("None")}</MenuItem>
                {packageCateData &&
                  packageCateData?.map((cate) => (
                    <MenuItem key={cate.id} value={cate.id}>
                      {cate.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="select-filter-page">
                {t("ประเภทแพ็คเกจ")}
              </InputLabel>
              <Select
                labelId="select-filter-page"
                autoWidth
                id="filter-page-control"
                label="Page Control"
                className="input-page"
                size="small"
                onChange={(e) => setPackageType(e.target.value)}
                value={packageType}
              >
                <MenuItem value="">{t("None")}</MenuItem>
                <MenuItem value="true">{t("true")}</MenuItem>
                <MenuItem value="dtac">{t("dtac")}</MenuItem>
              </Select>
            </FormControl>
            <ButtonUI
              onClick={setOpenAddHandle}
              style={{ width: "120px" }}
              className="btn-add-packagecate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มแพ็คเกจ")}
            </ButtonUI>
          </div>
        </div>

        {/* Package Table Here */}
        <DataGridPackage
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setProductEdit={setProductEdit}
          setFilteredData={setFilteredData}
          setPackageProductData={setPackageProductData}
          cateId={cateId}
        />

        {/* packageProduct Modal Add Here */}
        {openAdd && (
          <PackageAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            setFilteredData={setFilteredData}
            setPackageProductData={setPackageProductData}
            packageCateData={packageCateData}
            cateId={cateId}
          />
        )}

        {/* package product Modal Edit Here */}
        {openEdit && (
          <PackageEditModal
            productEdit={productEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            setFilteredData={setFilteredData}
            packageCateData={packageCateData}
            setPackageProductData={setPackageProductData}
            cateId={cateId}
          />
        )}
      </div>
    </section>
  );
}
