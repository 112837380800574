import axios from "axios";

/* cate */
export const svBerluckyCates = () => {
  return axios
    .get(`berluckycate/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreateBerluckyCate = (formData) => {
  return axios
    .post(`berluckycate/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdateBerluckyCates = (id, formData) => {
  return axios
    .post(`berluckycate/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeleteBerluckyCate = (id) => {
  return axios
    .delete(`berluckycate/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

/* product */
export const svBerlucky = (cached) => {
  return axios
    .get(`berlucky/data?iscached=${cached}`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreateBerlucky = (formData) => {
  return axios
    .post(`berlucky/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdateBerlucky = (id, formData) => {
  return axios
    .post(`berlucky/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeleteBerlucky = (id) => {
  return axios
    .delete(`berlucky/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

/* Lucky Package */
export const svGetLuckyPackageData = () => {
  return axios
    .get(`berlucky/package/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreateLuckyPackage = (formData) => {
  return axios
    .post(`berlucky/package/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdateLuckyPackage = (formData) => {
  return axios
    .post(`berlucky/package/update/${formData.id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeleteLuckyPackage = (id) => {
  return axios
    .delete(`berlucky/package/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

/* Lucky Network */
export const svGetLuckyNetWorkData = () => {
  return axios
    .get(`berlucky/network/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdateBerNet = (id, formData) => {
  return axios.post(`berlucky/network/update/${id}`, formData).then((res) => res).catch((err) => err);
};

/* Excel */
export const svUploadExcel = (formData) => {
  return axios
    .post(`berlucky/importexcel`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svExportExcel = () => {
  return axios
    .get(`berlucky/exportexcel`, { responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((err) => err);
};
