import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  TextField,
  FormControlLabel,
  FormGroup,
  Modal,
  Box,
  Switch,
} from "@mui/material";
import "./bank-modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMinus, faRedo } from "@fortawesome/free-solid-svg-icons";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import ButtonUI from "../../../components/ui/button/button";
import { svCreateBankInformation } from "../../../services/bankinfo.service";

function BankAddModal({ open, setOpen, setRefreshData, maxPriority }) {
  const { t } = useTranslation("prepaidcate-page");
  const [previews, setPreviews] = useState({ src: "", file: null, name: null });
  const [isError, setIsError] = useState({
    bank_name: false,
    bank_account: false,
    bank_number: false,
    thumbnail: false,
  });
  const initBankData = {
    bank_name: "",
    bank_account: "",
    bank_number: "",
    bank_image: "",
    display: true,
    priority: maxPriority + 1,
  };

  const [addData, setAddData] = useState(initBankData);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  const setPreviewHandler = (data) => {
    setAddData({ ...addData, bank_image: data.file ? data.file.name : "" });
    setPreviews(data);
  };
  //ตัวจัดการลำดับความสำคัญ จะได้ใช้
  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setAddData((prevState) => {
        return { ...prevState, priority: addData.priority + 1 };
      });
    } else if (addData.priority > 1) {
      setAddData((prevState) => {
        return { ...prevState, priority: addData.priority - 1 };
      });
    }
  };
  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      thumbnail: previews.file === null || !previews.file,
      bank_name: addData.bank_name.trim().length < 1,
      bank_account: addData.bank_account.trim().length < 1,
      bank_number:
        addData.bank_number.trim().length < 10 ||
        addData.bank_number.trim().length > 15,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("bank_image", addData.bank_image);
      }
      formData.append("bank_name", addData.bank_name);
      formData.append("bank_account", addData.bank_account);
      formData.append("bank_number", addData.bank_number);
      formData.append("display", addData.display ? 1 : 0);
      formData.append("priority", addData.priority);
      /* Display the key/value pairs*/
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      // return false;
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svCreateBankInformation(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };
  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="bank-modal-title"
        aria-describedby="bank-modal-description"
      >
        <Box className="bank-add-modal">
          <section id="bank-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("เพิ่มธนาคาร")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting ${
                      isError.thumbnail ? "error" : ""
                    }`}
                    label={t("รูปธนาคาร")}
                  >
                    <PreviewImageUI
                      className="add-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                  </FieldsetUI>

                  <div className="bank-details">
                    <FieldsetUI
                      className={"bank-details-title"}
                      label={t("รายละเอียด")}
                    >
                      <div className="input-full">
                        <TextField
                          onChange={(e) =>
                            setAddData((prevState) => {
                              return {
                                ...prevState,
                                bank_name: e.target.value,
                              };
                            })
                          }
                          value={addData.bank_name}
                          className="text-field-custom"
                          fullWidth={true}
                          error={isError.bank_name}
                          id="ad-bank_name"
                          label="ชื่อธนาคาร"
                          size="small"
                        />
                      </div>
                      <div className="input-full">
                        <TextField
                          onChange={(e) =>
                            setAddData((prevState) => {
                              return {
                                ...prevState,
                                bank_account: e.target.value,
                              };
                            })
                          }
                          value={addData.bank_account}
                          className="text-field-custom"
                          fullWidth={true}
                          error={isError.bank_account}
                          id="ad-bank_account"
                          label="บัญชีธนาคาร"
                          size="small"
                        />
                      </div>
                      <div className="input-full">
                        <TextField
                          onChange={(e) =>
                            setAddData((prevState) => {
                              return {
                                ...prevState,
                                bank_number: e.target.value,
                              };
                            })
                          }
                          value={addData.bank_number}
                          className="text-field-custom"
                          fullWidth={true}
                          error={isError.bank_number}
                          id="ad-bank_number"
                          label="เลขบัญชีธนาคาร"
                          size="small"
                        />
                      </div>
                    </FieldsetUI>
                    <div className="group-settings">
                      <h3 className="bank-detail-title">
                        {t("ตั้งค่าธนาคาร")}
                      </h3>
                      <div className="setting-controls">
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {addData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      display: e.target.checked,
                                    })
                                  }
                                  checked={addData.display ? true : false}
                                />
                              }
                              label={t("การแสดงผล")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={addValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default BankAddModal;
