import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { Suspense } from "react";
import "./App.scss";

/* Component pages */
import ConfigPage from "./pages/config/config";
import DashboardPage from "./pages/dashboard/dashboard";
import LoginPage from "./pages/login/login";
import RegisterPage from "./pages/register/register";
import NotFoundPage from "./pages/page404";
import ShowUIComponentPage from "./pages/showui/showui.js";
import { useSelector } from "react-redux";
import GuestRoutes from "./utils/guestRoutes";
import LangConfigPage from "./pages/langconfig";
import AdminPage from "./pages/admin/admin";
import BounceLoading from "./components/ui/loading/bounce/bounce";
import SpawnLoading from "./components/ui/loading/spawn/spawn";
import WebInfoPage from "./pages/webinfo/webinfo";
import Bankinfo from "./pages/bankinfo/Bankinfo.jsx";
import ProfileAdminPage from "./pages/admin/profile/profile";
import ForgetPasswordPage from "./pages/forgetpassword/forgetpassword";
import CategoryPage from "./pages/category/category";
import PostPage from "./pages/post/post";
import Article from "./pages/article/Article.jsx";
import Promotion from "./pages/promotion/Promotion.jsx";
import Another from "./pages/anotherarticle/Another.jsx";
import SlidePage from "./pages/slide/slide";
import MenuPage from "./pages/menu/menu";
import InboxPage from "./pages/inbox/inbox";
import ResetPasswordPage from "./pages/resetpassword/resetpassword";
import Fiber from "./pages/products/filber/Fiber.jsx";
import PackageCate from "./pages/products/package/PackageCate.jsx";
import PrepaidPage from "./pages/products/prepaid/PrepaidPage.jsx";
import PackagePage from "./pages/products/package/PackagePage.jsx";
import MoveCate from "./pages/products/move/MoveCate.jsx";
import MovePage from "./pages/products/move/MovePage.jsx";
import TravelPage from "./pages/products/travel/TravelPage.jsx";
import TravelCate from "./pages/products/travel/TravelCate.jsx";
import PrepaidCate from "./pages/products/prepaid/PrepaidCate.jsx";
import BerluckyCate from "./pages/products/berlucky/BerluckyCate.jsx";
import Berlucky from "./pages/products/berlucky/Berlucky.jsx";
import PredictNumbCatePage from "./pages/berluckysettings/predictnumbcate/PredictNumbCatePage.jsx";
import PredictGradePage from "./pages/berluckysettings/predictgrade/PredictGradePage.jsx";
import PredictBerPage from "./pages/berluckysettings/predictber/PredictBerPage.jsx";
import PredictSumberPage from "./pages/berluckysettings/predictsumber/PredictSumberPage.jsx";
import Orders from "./pages/orders/Orders.jsx";
import ContactMsPage from "./pages/contactMessage/ContactMsPage.jsx";
import ShippingPage from "./pages/shipping/ShippingPage.jsx";
import LuckyPackage from "./pages/products/berlucky/luckypackage/LuckyPackage.jsx";
import LuckyNetWork from "./pages/products/berlucky/luckynetwork/LuckyNetWork.jsx";
import PreNetwork from "./pages/products/prepaid/prepaidnetwork/PreNetwork.jsx";

function App() {
  const pagesAllow = useSelector((state) => state.app.pages);
  const isDevMode = useSelector((state) => state.app.isDevMode);
  console.log(pagesAllow)
  return (
    <Suspense>
      {/* Animetion loading */}
      <BounceLoading />
      <SpawnLoading />
      <Routes>
        <Route element={<PrivateRoutes />}>
          {<Route path="/" element={<Navigate to="/dashboard" />} />}
          {pagesAllow.dashboard && (
            <Route path="/dashboard" element={<DashboardPage />} />
          )}
          {pagesAllow.orders && <Route path="orders" element={<Orders />} />}
          {pagesAllow.inbox && <Route path="inbox" element={<InboxPage />} />}
          {pagesAllow.contactMessage && (
            <Route path="contactmessage" element={<ContactMsPage />} />
          )}
          {pagesAllow.shipping && (
            <Route path="shipping" element={<ShippingPage />} />
          )}

          {pagesAllow.groups.products && (
            <>
              <Route path="luckypackage" element={<LuckyPackage />} />
              <Route path="berluckycate" element={<BerluckyCate />} />
              <Route path="luckynetwork" element={<LuckyNetWork />} />
              <Route path="berlucky" element={<Berlucky />} />

              <Route path="fiberproduct" element={<Fiber />} />
              <Route path="packagecate" element={<PackageCate />} />
              <Route path="prepaidcate" element={<PrepaidCate />} />
              <Route path="preNetwork" element={<PreNetwork />} />
              <Route path="prepaid" element={<PrepaidPage />} />
              <Route path="package" element={<PackagePage />} />
              <Route path="movecate" element={<MoveCate />} />
              <Route path="moveproduct" element={<MovePage />} />

              <Route path="travelsim" element={<TravelPage />} />
              <Route path="travelcate" element={<TravelCate />} />
            </>
          )}
          {pagesAllow.groups.products && (
            <>
              <Route path="predictnumbcate" element={<PredictNumbCatePage />} />
              <Route path="grade" element={<PredictGradePage />} />
              <Route path="predict" element={<PredictBerPage />} />
              <Route path="sumber" element={<PredictSumberPage />} />
            </>
          )}

          {pagesAllow.slides && <Route path="slides" element={<SlidePage />} />}
          {pagesAllow.menu && <Route path="menu" element={<MenuPage />} />}
          {pagesAllow.category && (
            <Route path="category" element={<CategoryPage />} />
          )}
          {pagesAllow.posts && <Route path="posts" element={<PostPage />} />}
          {pagesAllow.article && <Route path="article" element={<Article />} />}
          {pagesAllow.groups.promotion && <Route path="promotion" element={<Promotion />} />}
          {pagesAllow.another && <Route path="another" element={<Another />} />}
          {/* {pagesAllow.reports && <Route path="reports" element={<DashboardPage />} /> } */}
          {pagesAllow.webinfo && (
            <Route path="webinfo" element={<WebInfoPage />} />
          )}
          {pagesAllow.languages && (
            <Route path="languages" element={<LangConfigPage />} />
          )}
          {pagesAllow.bankinfo && (
            <Route path="bankinfo" element={<Bankinfo/>}/>
          )}
          {pagesAllow.admins && <Route path="admins" element={<AdminPage />} />}
          {pagesAllow.configs && (
            <Route path="configs" element={<ConfigPage />} />
          )}
          {pagesAllow.profile && (
            <Route path="profile" element={<ProfileAdminPage />} />
          )}
          {isDevMode && (
            <Route path="componentui" element={<ShowUIComponentPage />} />
          )}
        </Route>
        <Route element={<GuestRoutes />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgetpassword" element={<ForgetPasswordPage />} />
          <Route path="resetpassword/:token" element={<ResetPasswordPage />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}
export default App;
