import axios from "axios";

export const svBankAll = () => {
    return axios
        .get(`bankinfo/data`)
        .then((res) => res)
        .catch((err) => err);
};

export const svCreateBankInformation = (formData)=>{
    return axios
        .post(`bankinfo/create`, formData)
        .then((res) => res)
        .catch((err) => err);
}
export const svUpdateBankInformation = (id, formData)=>{
    return axios
    .post(`bankinfo/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
}

export const svDeleteBankInformation =(id)=>{
    return axios
    .delete(`bankinfo/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
} 