import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { Outlet, Navigate } from "react-router-dom";
import { authActions } from "../store/auth-slice";
import MainLayout from "../components/main";
import axios from "axios";
import { appActions } from "../store/app-slice";
import { svBerlucky } from "../services/productServices/berlucky.service";

const PrivateRoutes = () => {
  // const storeBerlucky = useSelector((state) => state.app.berluckyAll);

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const authToken = useSelector((state) => state.auth.authToken);
  const adminId = useSelector((state) => state.auth.adminId);
  const language = useSelector((state) => state.app.language);
  const multilingual = useSelector((state) => state.app.features.multilingual);

  // useEffect(() => {
  //   if (window.location.pathname !== "/berlucky") {
  //     dispatch(appActions.setBerluckyLoading(true));
  //     svBerlucky(false).then((res) => {
  //       console.log('berlucky has been loaded successfully!')
  //       const result = res.data.data;
  //       const prodMaped = result.products?.map((p) => ({ ...p, default_cate: p.default_cate?.split(',')?.filter(f => f !== '') }))
  //       dispatch(appActions.setBerluckyAll(prodMaped));
  //       dispatch(appActions.setBerluckyLoading(false));
  //     })
  //   }
  // }, []);

  useEffect(() => {
    if (window.location.pathname !== "/berlucky") {
      const source = axios.CancelToken.source();
      (async () => {
        try {
          const response = await axios.get(
            `berlucky/privatedata?iscached=${false}`,
            { cancelToken: source.token } // Method 2
          );
    
          if (response.status === 200) {
            console.log('berlucky has been loaded successfully!')
            const result = response.data.data;
            const prodMaped = result.products?.map((p) => ({ ...p, default_cate: p.default_cate?.split(',')?.filter(f => f !== '') }))
            dispatch(appActions.setBerluckyAll(prodMaped));
            dispatch(appActions.setBerluckyLoading(false));
          } else {
            console.error(`HTTP error! Status: ${response.status}`);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      })();
    
      return () => {
        // Cancel the request when the component unmounts
        source.cancel();
      };

    }
  }, []);

  const mailInboxInit = () => {
    axios.get("mail/data").then(
      (res) => {
        const unreadData = res.data.unreadData;
        dispatch(appActions.getMailInbox(unreadData));
      },
      (err) => {
        console.log(err);
        dispatch(appActions.getMailInbox({ mails: [], contactMs: [] }));
      }
    );
  };

  const orderPendingInit = () => {
    axios.get("order/orderpending").then(
      (res) => {
        const order_pending = res.data.order_pending;
        dispatch(appActions.getOrderPending(order_pending));
      },
      (err) => {
        console.log(err);
        dispatch(appActions.getOrderPending(0));
      }
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      tokenHandler();
      mailInboxInit();
      orderPendingInit();
    }
  }, [isLoggedIn]);

  const userInfoHandler = () => {
    dispatch(appActions.isBounceActive(true));
    return axios({
      url: `account/settings`,
      method: "post",
    })
      .then(
        (response) => {
          let data = response.data.data;
          dispatch(
            authActions.authSettings({ data: data, multilingual: multilingual })
          );
          dispatch(appActions.frontOffifcePageInit(data.menu));
          if (language === "en-US") {
            dispatch(appActions.changeLanguage(data.defaultLanguage));
          }
        },
        (error) => {
          dispatch(authActions.logout());
        }
      )
      .then(() => {
        dispatch(appActions.isBounceActive(false));
      });
  };

  const tokenHandler = () => {
    /* เช็คทุก 3 วินาที token expired จะสั่ง logout */
    try {
      if (authToken !== undefined) {
        let tokenInfo = jwt_decode(authToken);
        let tokenExpiration = tokenInfo.exp * 1000;
        let checkTokenExpire = setInterval(() => {
          if (
            localStorage.getItem("authToken") &&
            tokenExpiration < new Date().getTime()
          ) {
            clearInterval(checkTokenExpire);
            dispatch(authActions.logout());
          }
        }, 1000);

        if (!adminId) {
          userInfoHandler();
        } else {
          setTimeout(() => {
            dispatch(appActions.isBounceActive(false));
          }, 1500);
        }
      } else {
        console.log("invalid credentials");
        dispatch(authActions.logout());
      }
    } catch (error) {
      console.log("invalid authorization");
      dispatch(authActions.logout());
    }
  };

  if (isLoggedIn && !adminId) {
    return <></>;
  }

  return (
    <MainLayout>
      {isLoggedIn ? <Outlet /> : <Navigate to="/login" />}
    </MainLayout>
  );
};

export default PrivateRoutes;
