import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Modal, Select, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { appActions } from "../../../store/app-slice";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

import { svUpdatePredictBer } from "../../../services/productServices/berpredict.service";

function PredictBerEditModal({
  open,
  setOpen,
  productEdit,
  setRefreshData,
}) {
  const [isError, setIsError] = useState({
    prophecy_numb: false,
    prophecy_name: false,
    prophecy_desc: false,
    prophecy_percent: false,
    prophecy_color: false,
  });

  const { t } = useTranslation("predictber-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);

  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      prophecy_name: editData.prophecy_name.toString().trim().length < 1,
      prophecy_numb: editData.prophecy_numb.toString().trim().length < 1,
      prophecy_desc: editData.prophecy_desc.toString().trim().length < 1,
      prophecy_percent: editData.prophecy_percent.toString().trim().length < 1,
      prophecy_color: editData.prophecy_color.toString().trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();

      formData.append("prophecy_id", editData.prophecy_id);
      formData.append("prophecy_numb", editData.prophecy_numb);
      formData.append("prophecy_name", editData.prophecy_name || "");
      formData.append("prophecy_desc", editData.prophecy_desc);
      formData.append("prophecy_percent", editData.prophecy_percent);
      formData.append("prophecy_color", editData.prophecy_color);

      saveHandler(formData);
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePredictBer(editData.prophecy_id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="predictber-edit-modal">
          <section id="predictber-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขข้อมูลทำนายเบอร์")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="predictber-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              prophecy_numb: !isNaN(e.target.value) ? e.target.value : prevState.prophecy_numb,
                            };
                          })
                        }
                        inputProps={
                          { readOnly: true, }
                        }
                        value={editData.prophecy_numb}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.prophecy_numb}
                        id="ad-prophecy_numb"
                        label="เกรด (00 - 99)"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              prophecy_name: e.target.value,
                            };
                          })
                        }
                        value={editData.prophecy_name}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.prophecy_name}
                        id="ad-prophecy_name"
                        label="ความหมาย"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              prophecy_desc: e.target.value,
                            };
                          })
                        }
                        value={editData.prophecy_desc}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.prophecy_desc}
                        id="ad-prophecy_desc"
                        label="รายละเอียด"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              prophecy_percent: e.target.value,
                            };
                          })
                        }
                        type="number"
                        value={editData.prophecy_percent}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.prophecy_percent}
                        id="ad-prophecy_percent"
                        label="เปอร์เซ็นต์"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <FormControl
                        sx={{ m: 1 }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="color-select">
                          Color
                        </InputLabel>
                        <Select
                          labelId="color-select"
                          label="Color"
                          value={editData.prophecy_color}
                          error={isError.prophecy_color}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                prophecy_color: e.target.value,
                              };
                            })
                          }
                        >
                          <MenuItem value={"red"}><p style={{ color: 'red' }}>แดง</p></MenuItem>
                          <MenuItem value={"orange"}><p style={{ color: 'orange' }}>ส้ม</p></MenuItem>
                          <MenuItem value={"mediumseagreen"}><p style={{ color: 'mediumseagreen' }}>เขียว</p></MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default PredictBerEditModal;
