import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControlLabel, FormGroup, Modal, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { appActions } from "../../../store/app-slice";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

import { svUpdatePredictGrade } from "../../../services/productServices/berpredict.service";

function PredictGradeEditModal({
  open,
  setOpen,
  productEdit,
  setRefreshData,
}) {
  const [isError, setIsError] = useState({
    grade_name: false,
    grade_max: false,
    grade_min: false,
  });

  const { t } = useTranslation("predictgrade-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);

  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      grade_name: editData.grade_name.trim().length < 1,
      grade_max: editData.grade_max.toString().trim().length < 1,
      grade_min: editData.grade_min.toString().trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();

      formData.append("grade_id", editData.grade_id);
      formData.append("grade_name", editData.grade_name);
      formData.append("grade_description", editData.grade_description || "");
      formData.append("grade_max", editData.grade_max);
      formData.append("grade_min", editData.grade_min);

      saveHandler(formData);
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePredictGrade(editData.grade_id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="predictgrade-edit-modal">
          <section id="predictgrade-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขข้อมูลเกรด")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="predictgrade-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              grade_name: e.target.value,
                            };
                          })
                        }
                        inputProps={
                          { readOnly: true, }
                        }
                        value={editData.grade_name}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.grade_name}
                        id="ad-grade_name"
                        label="เกรด"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              grade_description: e.target.value,
                            };
                          })
                        }
                        inputProps={
                          { readOnly: true, }
                        }
                        value={editData.grade_description}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-grade_description"
                        label="คำอธิบาย"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              grade_max: !isNaN(e.target.value) ? e.target.value : prevState.grade_max,
                            };
                          })
                        }
                        value={editData.grade_max}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.grade_max}
                        id="ad-grade_max"
                        label="คะแนนสูงสุด"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              grade_min: !isNaN(e.target.value) ? e.target.value : prevState.grade_min,
                            };
                          })
                        }
                        value={editData.grade_min}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.grade_min}
                        id="ad-grade_min"
                        label="คะแนนต่ำสุด"
                        size="small"
                        multiline
                      />
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default PredictGradeEditModal;
