import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faAdd,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Grid, Container, Popper, Fade } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ButtonUI from "../../components/ui/button/button";
import SwalUI from "../../components/ui/swal-ui/swal-ui";
import { resolveResultByField } from "../../thaiaddr/th-address.js";
import { svGetProductData, svCreateOrder } from "../../services/orders.service";

const initialState = {
  entities: [],
  searchKey: "",
  search: "",
  form: {
    address: "",
    subDistrict: "",
    district: "",
    province: "",
    postCode: "",
  },
};

const SEARCH_ACTION = "SEARCH_ACTION";
const FORM_CHANGE_ACTION = "FORM_CHANGE_ACTION";
const SELECT_ADDRESS = "SELECT_ADDRESS";

function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case SEARCH_ACTION:
      return {
        ...state,
        entities: resolveResultByField(payload.type, payload.search),
        searchKey: payload.type,
        search: payload.search,
      };
    case FORM_CHANGE_ACTION:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.name]: payload.value,
        },
      };
    case SELECT_ADDRESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...payload,
        },
      };
    default:
      throw new Error();
  }
}

export default function OrderAddModal({ open, setOpen, setRefreshData }) {
  const prodItems = {
    title: "",
    type_id: "",
    product_cate_id: "",
    product_id: "",
    product_price: 0,
    quantity: 1,
    amount: 0,
    discount: 0,
    productOption: [],
  };
  const [isError, setIsError] = useState({
    name: false,
    phone: false,
    postcode: false,
    subdistrict: false,
    district: false,
    province: false,
    berlucky: { status: false, rowNo: 0 },
  });
  const initProductData = {
    name: "",
    email: "",
    phone: "",
    address: "",
    zipcode: "",
    subdistrict: "",
    district: "",
    province: "",
    total_price: 0,
    shipping_cost: 0,
    order_carrier: "",
    tracking_number: "",
    order_status: "pending",
    shipping_cost: 0,
    product_items: [prodItems],
    deleteState: false,
  };

  const { t } = useTranslation("orders-page");
  const webPath = useSelector((state) => state.app.webPath);
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const [isFetching, setIsFetching] = useState(false);
  const [addData, setAddData] = useState(initProductData); // การเปลี่ยนแปลงใน addData จะไม่ส่งผลต่อ addData
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [products, setProducts] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [berluckyError, setBerluckyError] = useState("");


  useEffect(() => {
    svGetProductData().then((res) => {
      setProducts(res.data.productData);
      setFetching(false);
    });
  }, []);

  useEffect(() => {
    totalPriceHandler();
  }, [addData.product_items, addData.deleteState]);

  const totalPriceHandler = () => {
    const total_price = addData.product_items?.reduce((total, item) => {
      // console.log(total, item);
      return (
        total +
        item.product_price * item.quantity -
        (item.product_price * item.discount) / 100
      );
    }, 0);

    setAddData((prev) => ({ ...prev, total_price: total_price }));
  };

  const handlerFormChange = (event) => {
    dispatch({
      type: FORM_CHANGE_ACTION,
      payload: {
        name: event.target.name,
        search: event.target.value,
      },
    });
    dispatch({
      type: SEARCH_ACTION,
      payload: {
        type: event.target.name,
        search: event.target.value,
      },
    });
    setAnchorEl(event.currentTarget);
  };

  const handlerClick = (obj) => {
    setAnchorEl(null);
    dispatch({
      type: SELECT_ADDRESS,
      payload: { ...obj },
    });
  };

  const onBlur = () => {
    setAnchorEl(null);
  };

  const handlerStatus = (e) => {
    if (e.target.checked) {
      setAddData((prev) => ({ ...prev, order_status: "success" }));
    } else {
      setAddData((prev) => ({ ...prev, order_status: "pending" }));
    }
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const quantityHandler = (isAdding, prod, ind) => {
    let details = [];
    if (isAdding) {
      if (prod.quantity + 1 > prod.amount) return false;
      details = addData.product_items?.map((i, index) => {
        if (index === ind) {
          return {
            ...i,
            quantity: i.quantity + 1,
          };
        }
        return i;
      });
    } else {
      details = addData.product_items?.map((i, index) => {
        if (index === ind && i.quantity > 1) {
          return {
            ...i,
            quantity: i.quantity - 1,
          };
        }
        return i;
      });
    }

    setAddData((prev) => ({ ...prev, product_items: details }));
  };

  const onDeleteItem = (prod, ind) => {
    const items = addData.product_items;
    items?.splice(ind, 1);
    setAddData((prev) => ({
      ...prev,
      product_items: items,
      deleteState: !prev.deleteState,
    }));
  };

  const handlerProductCateChange = (e, ind) => {
    const type = e.target.value;
    let option = [];

    if (type === 4) {
      option = products.prepaidsim;
    } else if (type === 6) {
      option = products.travelsim;
    }

    const details = addData.product_items?.map((i, index) => {
      if (index === ind) {
        return {
          ...i,
          title: "",
          type_id: type,
          productOption: option,
          product_cate_id: "",
          product_id: "",
          product_price: 0,
          discount: "",
        };
      }

      return i;
    });

    // setTypeSelected(type)
    setAddData((prev) => ({ ...prev, product_items: details }));
  };

  const handleLuckyInput = (event, ind) => {
    let details = [];
    let berluckyPhone = event.target.value;

      if (/^[a-zA-Z]*$/.test(berluckyPhone)) {
       
        setIsError((prev) => ({
          ...prev,
          berlucky: {
            ...prev.berlucky,
            status: true,
            rowNo: ind,
          },
        }));
        setBerluckyError("เบอร์โทรต้องเป็นตัวเขลเท่านั้น");
        // console.log("เบอร์โทรต้องเป็นตัวเขลเท่านั้น",berluckyPhone);
      } else if (berluckyPhone.length < 10) {
        setIsError((prev) => ({
          ...prev,
          berlucky: {
            ...prev.berlucky,
            status: true,
            rowNo: ind,
          },
        }));
        setBerluckyError("เบอร์โทรต้องการ 10 ตัว");
        // console.log("เบอร์โทรต้องการ 10 ตัว");
        // console.log(isError.berlucky,"เบอร์โทรต้องการ 10 ตัว");
      } else if (berluckyPhone.length == 10) {
        setIsError((prev) => ({
          ...prev,
          berlucky: {
            ...prev.berlucky,
            status: false,
          },
        }));
      }
  
    details = addData.product_items?.map((i, index) => {
      if (index === ind) {
        if (event) {
          return {
            ...i,
            title: event.target.value,
            product_cate_id: "",
            product_id: "",
            quantity: 1,
            amount: 1,
            discount: 0,
            thumbnail: "",
          };
        } else {
          return {
            ...i,
            title: "",
            product_cate_id: "",
            product_id: "",
            product_price: 0,
            discount: "",
            quantity: 1,
            amount: 0,
          };
        }
      }

      return i;
      
    });

    if (berluckyPhone.length == 10) {
      if (!addData.product_items[ind + 1]) {
        // เพิ่มแถวของ product items
        details.push(prodItems);
        setIsError((prev) => ({
          ...prev,
          berlucky: {
            ...prev.berlucky,
            status: false,
          },
        }));
      }
    }
    if (/^\d*$/.test(berluckyPhone)) {
      setAddData((prev) => ({ ...prev, product_items: details }));
      // console.log("ไม่มีสตริง");
    }
  };
  const handleLuckyPriceInput = (event, ind) => {
    let details = [];
    let berluckyPrice = event.target.value;

    details = addData.product_items?.map((i, index) => {
      if (index === ind) {
        if (event) {
          return {
            ...i,
            product_price: berluckyPrice
          };
        }
      }

      return i;
      
    });
    
    setAddData((prev) => ({ ...prev, product_items: details }));
  };

  const handlerProductSelectChange = (value, ind) => {
    let details = [];
    details = addData.product_items?.map((i, index) => {
      if (index === ind) {
        if (value) {
          return {
            ...i,
            title: value.title,
            product_cate_id: value.p_cate_id,
            product_id: value.id,
            product_price: value.price,
            quantity: 1,
            amount: value.amount,
            discount: value.discount,
            thumbnail: value.thumbnail_link || "",
          };
        } else {
          return {
            ...i,
            title: "",
            product_cate_id: "",
            product_id: "",
            product_price: "",
            discount: "",
            quantity: 1,
            amount: 0,
          };
        }
      }
      return i;
    });

    if (value) {
      if (!addData.product_items[ind + 1]) {
        // เพิ่มแถวของ product items
        details.push(prodItems);
      }
    }

    setAddData((prev) => ({ ...prev, product_items: details }));
  };

  const addValidators = () => {
    let isValid = true;
    if (addData.name.trim() === "") {
      setIsError((prev) => ({ ...prev, name: true }));
      isValid = false;
      return false;
    } else {
      setIsError((prev) => ({ ...prev, name: false }));
    }

    const prod_items = addData.product_items?.filter(
      (item, ind) =>
        ind !== addData.product_items.length - 1 ||
        (item.type_id && item.product_id)
    );

    if (prod_items.length < 1) {
      SwalUI({
        status: false,
        description: "ไม่ได้เลือกสินค้า",
        timer: 2000,
      });

      return false;
    }

    if (isValid) {
      const address = state.form;

      const form = {
        name: addData.name,
        email: addData.email,
        phone: addData.phone,
        address: addData.address,
        zipcode: address.postCode,
        district: address.district,
        subdistrict: address.subDistrict,
        province: address.province,
        product_items: prod_items,
        total_price: addData.total_price,
        shipping_cost: addData.shipping_cost,
        order_status: addData.order_status,
        order_carrier: addData.order_carrier,
        tracking_number: addData.tracking_number,
      };

      // console.log(form);
      // return false;

      onCreateOrder(form);
    }
  };

  const onCreateOrder = (formOrder) => {
    setIsFetching(true);
    svCreateOrder(formOrder).then((res) => {
      setOpen(false);
      setIsFetching(false);
      if (res.status === 201) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={onCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="orderadd-modal">
          <div className="card-control">
            <div className="card-head">
              <div className="head-action">
                <Typography className="head-title">
                  <FontAwesomeIcon icon={faPlus} /> {t("เพิ่มรายการ")}
                </Typography>
              </div>
            </div>
            <div className="card-body">
              <Box
                className="orderadd-details"
                sx={{
                  marginTop: "1rem",
                  width: "100%",
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: isSmallScreen
                    ? "repeat(1, 1fr)"
                    : "repeat(2, 1fr)",
                }}
              >
                <TextField
                  size="small"
                  label="ชื่อ"
                  fullWidth
                  name="name"
                  variant="outlined"
                  value={addData.name}
                  error={isError.name}
                  onChange={(e) =>
                    setAddData((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
                <TextField
                  size="small"
                  label="อีเมล"
                  fullWidth
                  name="email"
                  variant="outlined"
                  value={addData.email}
                  onChange={(e) =>
                    setAddData((prev) => ({ ...prev, email: e.target.value }))
                  }
                />
                <TextField
                  size="small"
                  label="เบอร์โทร"
                  fullWidth
                  name="phone"
                  variant="outlined"
                  value={addData.phone}
                  onChange={(e) =>
                    setAddData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                />
                <TextField
                  size="small"
                  label="รหัสไปรษณีย์*"
                  fullWidth
                  variant="outlined"
                  name="postCode"
                  value={state.form.postCode}
                  onBlur={onBlur}
                  onChange={handlerFormChange}
                />
                <TextField
                  size="small"
                  label="ตำบล/แขวง"
                  fullWidth
                  variant="outlined"
                  name="subDistrict"
                  value={state.form.subDistrict}
                  onBlur={onBlur}
                  onChange={handlerFormChange}
                />
                <TextField
                  size="small"
                  label="อำเภอ/เขต"
                  fullWidth
                  variant="outlined"
                  name="district"
                  value={state.form.district}
                  onBlur={onBlur}
                  onChange={handlerFormChange}
                />
                <TextField
                  size="small"
                  label="จังหวัด"
                  fullWidth
                  variant="outlined"
                  name="province"
                  value={state.form.province}
                  onBlur={onBlur}
                  onChange={handlerFormChange}
                />
                <TextField
                  multiline
                  size="small"
                  label="รายละเอียดที่อยู่"
                  fullWidth
                  name="address"
                  variant="outlined"
                  value={addData.address}
                  onChange={(e) =>
                    setAddData((prev) => ({ ...prev, address: e.target.value }))
                  }
                />
                {state.entities.length > 0 && (
                  <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    transition
                    keepMounted
                    style={{ zIndex: 1999 }}
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper style={{ maxHeight: 400, overflow: "auto" }}>
                          {state.entities.map((x, idx) => (
                            <MenuItem key={idx} onClick={() => handlerClick(x)}>
                              {x.subDistrict}
                              {">"}
                              {x.district}
                              {">"}
                              {x.province}
                              {">"}
                              {x.postCode}
                            </MenuItem>
                          ))}
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                )}
              </Box>

              {true && (
                <Box className="product-details">
                  <Typography variant="subtitle1">
                    รายละเอียดสินค้า : ทั้งหมด{" "}
                    {addData.product_items?.length - 1} รายการ
                  </Typography>
                  <TableContainer component={Paper} className="table">
                    <Table sx={{ minWidth: 850 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "230px" }}>
                            หมวดหมู่สินค้า
                          </TableCell>
                          <TableCell sx={{ width: "350px" }}>
                            ชื่อสินค้า/เบอร์
                          </TableCell>
                          <TableCell sx={{ width: "100px" }}>
                            ราคา (บาท)
                          </TableCell>
                          <TableCell>ส่วนลด (%)</TableCell>
                          {/* <TableCell sx={{ width: "150px" }}>
                            ราคาหลังหักส่วนลด (บาท)
                          </TableCell> */}
                          <TableCell sx={{ width: "150px" }}>จำนวน</TableCell>
                          <TableCell sx={{ width: "150px" }}>
                            จำนวนคงเหลือ
                          </TableCell>
                          <TableCell>แก้ไข</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addData.product_items?.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              height: "100px",
                            }}
                          >
                            <TableCell>
                              <FormControl
                                sx={{ width: "100%" }}
                                size="small"
                                className="form-control"
                              >
                                <InputLabel id="productcate-select">
                                  {fetching
                                    ? "กำลังโหลดสินค้า..."
                                    : "เลือกหมวดหมู่สินค้า"}
                                </InputLabel>
                                <Select
                                  disabled={fetching}
                                  labelId="productcate-select"
                                  label={
                                    fetching
                                      ? "กำลังโหลดสินค้า..."
                                      : "เลือกหมวดหมู่สินค้า"
                                  }
                                  value={item.type_id}
                                  error={false}
                                  onChange={(e) =>
                                    handlerProductCateChange(e, index)
                                  }
                                >
                                  <MenuItem value={3}>เบอร์มงคล</MenuItem>
                                  <MenuItem value={4}>ซิมเติมเงิน</MenuItem>
                                  <MenuItem value={6}>ซิมท่องเที่ยว</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>

                            <TableCell>
                              {item.type_id === 3 ? (
                                <>
                                  <TextField
                                    size="small"
                                    label="สินค้า"
                                    fullWidth
                                    name="berluckyTitle"
                                    variant="outlined"
                                    // value={berluckyProduct.berluckyPhone}
                                    value={item.title}
                                    // inputRef={berluckyTitle}
                                    onChange={
                                      (event) => {
                                        // console.log(isNaN(event.target.value))
                                        handleLuckyInput(event, index);
                                      }
                                      // handleLuckyInput(event, index)/ onKeyDown/ 
                                    }
                                    inputProps={{
                                      inputMode: "numeric",
                                      pattern: "[0-9]*",
                                      maxLength: "10",
                                      // Add step="1" if you want to ensure only whole numbers
                                      // step: '1',
                                    }}
                                  />
                                  {/* เพิ่มสไตล์เมื่ออินพุตไม่ถูกต้อง */}
                                  {isError.berlucky.status &&
                                    isError.berlucky.rowNo == index && (
                                      <div style={{ color: "red" }}>
                                        {berluckyError}
                                      </div>
                                    )}
                                </>
                              ) : (
                                <Autocomplete
                                  key={item.type_id}
                                  size="small"
                                  value={{ title: item.title }}
                                  disablePortal
                                  id="product-title"
                                  options={item.productOption}
                                  getOptionLabel={(option) => option.title}
                                  fullWidth
                                  onChange={(event, value) =>
                                    handlerProductSelectChange(value, index)
                                  }
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.id}>
                                        {option.title}
                                      </li>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="สินค้า" />
                                  )}
                                />
                              )}
                            </TableCell>
                            {item.type_id === 3 ? (
                              <TableCell>
                                <TextField
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  name="productPrice"
                                  // defaultValue={0}
                                  // value={berluckyProduct.berluckyPrice}
                                  value={item.product_price}
                                  // inputRef={textFieldRef}
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                  }}
                                  // ปิดการแสดงผลปุ่ม spinner
                                  InputProps={{
                                    inputProps: {
                                      min: 0,
                                    },
                                  }}
                                  onChange={(event) => {
                                    handleLuckyPriceInput(event, index);
                                  }}
                                />
                              </TableCell>
                            ) : (
                              <TableCell>{item.product_price}</TableCell>
                            )}

                            <TableCell>{item.discount}</TableCell>
                            {/* <TableCell>
                              {Math.floor(
                                item.product_price -
                                  (item.product_price * item.discount) / 100
                              )}
                            </TableCell> */}
                            {item.type_id === 3 || !item.type_id ? (
                              <TableCell>{item.quantity}</TableCell>
                            ) : (
                              <TableCell>
                                <div className="input-group-settings">
                                  <div className="inp">
                                    <button
                                      onClick={() =>
                                        quantityHandler(false, item, index)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faMinus} />
                                    </button>

                                    <span
                                      className="title"
                                      style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {item.quantity}
                                    </span>
                                    <button
                                      onClick={() =>
                                        quantityHandler(true, item, index)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faAdd} />
                                    </button>
                                  </div>
                                </div>
                              </TableCell>
                            )}
                            <TableCell>
                              {item.type_id !== 3 ? item.amount : ""}
                            </TableCell>
                            <TableCell>
                              {addData.product_items.length > 1 &&
                                index !== addData.product_items.length - 1 && (
                                  <ButtonUI
                                    isLoading={false}
                                    onClick={() => onDeleteItem(item, index)}
                                    className="btn-delete"
                                    on="delete"
                                    width="free"
                                  >
                                    {""}
                                  </ButtonUI>
                                )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={9} align="right">
                            ราคารวม :{" "}
                            {addData.total_price + addData.shipping_cost}
                            {" บาท"}
                            (ค่าจัดส่ง {addData.shipping_cost} บาท)
                            <br />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="group-settings">
                    <div className="setting-controls">
                      <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) => handlerStatus(e)}
                                checked={
                                  addData.order_status === "success"
                                    ? true
                                    : false
                                }
                                color="success"
                              />
                            }
                            label={
                              t("สถานะคำสั่งซื้อ") +
                              ` : ${addData.order_status}`
                            }
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div>
                      <div className="inp-group">
                        <TextField
                          style={{ width: "100%" }}
                          value={addData.tracking_number}
                          label="เลขติดตามพัสดุ"
                          id="outlined-size-small"
                          size="small"
                          onChange={(e) =>
                            setAddData((prev) => ({
                              ...prev,
                              tracking_number: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="inp-group">
                        <FormControl
                          sx={{ width: "100%" }}
                          size="small"
                          className="form-control"
                        >
                          <InputLabel id="carry-select">
                            ตัวเลือกขนส่ง
                          </InputLabel>
                          <Select
                            labelId="carry-select"
                            label="ตัวเลือกขนส่ง"
                            value={addData.order_carrier}
                            error={false}
                            onChange={(e) =>
                              setAddData((prev) => ({
                                ...prev,
                                order_carrier: e.target.value,
                              }))
                            }
                          >
                            <MenuItem value={"EMS"}>จัดส่งแบบ EMS</MenuItem>
                            <MenuItem value={"Kerry"}>จัดส่งแบบ KERRY</MenuItem>
                            <MenuItem value={"Flash "}>
                              จัดส่งแบบ Flash
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </Box>
              )}
            </div>
            <div className="btn-action">
              <ButtonUI
                isLoading={isFetching}
                onClick={addValidators}
                className="btn-save"
                on="save"
                width="md"
              >
                {t("บันทึก")}
              </ButtonUI>
              <ButtonUI
                onClick={onCloseModal}
                icon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ fontSize: "48px" }}
                  />
                }
                className="btn-cancel"
                on="cancel"
                width="md"
              >
                {t("ปิด")}
              </ButtonUI>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
