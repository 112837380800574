import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faBoxOpen,
  faRedo,
  faFile,
  faFileImport,
  faDownload,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import DataGridBerluckyProduct from "./datagrid/DataGridBerluckyProduct";
import BerluckyAddModal from "./BerluckyAddModal";
import dayjs from "dayjs";
import { appActions } from "../../../store/app-slice";
import { svBerlucky } from "../../../services/productServices/berlucky.service";
import {
  svUploadExcel,
  svExportExcel,
} from "../../../services/productServices/berlucky.service";

import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import "./berlucky.scss";
import { Link } from "react-router-dom";

export default function Berlucky() {
  const dispatch = useDispatch();
  const { t } = useTranslation("berlucky-page");
  const language = useSelector((state) => state.app.language);
  const storeBerlucky = useSelector((state) => state.app.berluckyAll);
  const berluckyLoading = useSelector((state) => state.app.berluckyLoading);
  const [berluckyCateData, setBerluckyCateData] = useState([]);
  const [bercateOption, setBercateOption] = useState([]);
  const [bernetworksOption, setBernetworksOption] = useState([]);
  const [berluckyDuplicateProductsData, setBerluckyDuplicateProductsData] = useState([]);
  const [berluckyProductData, setBerluckyProductData] = useState([]);
  const [filteredProduct, setFiteredProduct] = useState([]);
  const [berluckyCate, setBerluckyCate] = useState("");
  const [packageOptions, setPackageOptions] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [spawnActive, setSpawnActive] = useState(true);
  const [filename, setFilename] = useState("เลือกไฟล์");
  const [fileExcel, setFileExcel] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const inputFile = useRef();

  useEffect(() => {
    const isCached = storeBerlucky.length > 0 ? true : false;
    dispatch(appActions.isSpawnActive(spawnActive));
    setFileExcel(null);
    setFilename("เลือกไฟล์");
    setFileError(false);
    inputFile.current.value = "";

    svBerlucky(isCached).then((res) => {
      if (res.status === 200) {
        const result = res.data.data;

        let prodMaped = isCached
          ? storeBerlucky
          : result.products?.map((p) => ({
              ...p,
              default_cate: p.default_cate?.split(",")?.filter((f) => f !== ""),
            }));
        const cateOption = result.cates.filter((cate) => cate.status === 0);
        const netWorksOption = result.bernetworks;

        // แบ่งกลุ่มตามค่าที่เหมือนกัน
        let grouped = prodMaped.reduce((acc, obj) => {
          acc[obj.product_phone] = acc[obj.product_phone] || [];
          acc[obj.product_phone].push(obj);
          return acc;
        }, Object.create(null));

        prodMaped = Object.values(grouped)
          .filter((group) => group !== null) //ไม่เอา Obj ที่เป็น null
          .sort((a, b) => b.length - a.length) // เรียงลำดับ
          .flat() // รวม array จาก grouped
          .filter((item) => typeof item === "object"); // เอาเฉพาะ item ที่เป็น object
        console.log(prodMaped);
        setBerluckyProductData(prodMaped);
        setBernetworksOption(netWorksOption);
        setBercateOption(cateOption);
        setBerluckyCateData(result.cates);
        setPackageOptions(result.packages);
        dispatch(appActions.setBerluckyAll(prodMaped));

        const seen = new Set();
        const duplicateProducts = [];

        for (const product of prodMaped) {
          if (seen.has(product.product_phone)) {
            duplicateProducts.push(product);
          } else {
            seen.add(product.product_phone);
          }
        }
        setBerluckyDuplicateProductsData(duplicateProducts);

        if (berluckyCate) {
          const product = prodMaped?.filter((p) =>
            p.default_cate.includes(berluckyCate.toString())
          );
          setFiteredProduct(product);
        } else {
          setFiteredProduct(prodMaped);
        }
      } else {
        setBerluckyCateData([]);
        setFiteredProduct([]);
      }

      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const handleBerluckyCateChange = (value) => {
    setSearchInput("");
    setBerluckyCate(value);
    if (!value || value === "") {
      setFiteredProduct(berluckyProductData);
    } else {
      const product = berluckyProductData?.filter((p) => {
        return (
          p.product_category?.split(",").includes(value.toString()) ||
          p.default_cate?.includes(value.toString())
        );
      });
      setFiteredProduct(product);
    }
  };

  const berluckyAddHandle = () => {
    setBerluckyCate("");
    handleBerluckyCateChange("");
    setOpenModalAdd(true);
  };

  const onSelectFile = (event) => {
    setFileError(false);
    const file = event.target.files[0];
    const filename = file.name;
    setFileExcel(file);
    setFilename(filename);
  };

  const uploadFile = () => {
    if (!fileExcel) {
      setFileError(true);
      inputFile.current.value = "";
      return false;
    }
    const formData = new FormData();
    formData.append("excel_file", fileExcel);

    dispatch(appActions.isSpawnActive(true));
    svUploadExcel(formData)
      .then((res) => {
        if (res.status === 201) {
          SwalUI({
            status: res.data.status,
            description: res.data.description,
          });
        } else {
          SwalUI({
            status: false,
            description: "Somethink went wrong!",
          });
        }

        dispatch(appActions.isSpawnActive(false));
      })
      .then(() => {
        dispatch(appActions.setBerluckyAll([]));
        setTimeout(() => {
          setSpawnActive(true);
          setRefreshData((prev) => prev + 1);
        }, 2000);
      });
  };

  const onExportFile = () => {
    dispatch(appActions.isSpawnActive(true));
    svExportExcel().then((res) => {
      if (res.status === 200) {
        try {
          const blob = new Blob([res.data], {
            type: "application/octet-stream",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          const exportDate = dayjs().format("DD-MM-YYYY");

          link.href = url;
          link.download = `exportproducts_${exportDate}.xlsx`;
          link.click();

          // ลบ URL
          URL.revokeObjectURL(url);
        } catch (err) {
          console.error(err);
        }
      }
      dispatch(appActions.isSpawnActive(false));
    });
  };

  const searchData = (e) => {
    setSearchInput(e.target.value);
    const text = e.target.value.toLowerCase();
    if (!text || text.trim() === "") {
      if (berluckyCate) {
        const product = berluckyProductData?.filter((p) =>
          p.default_cate.includes(berluckyCate.toString())
        );
        setFiteredProduct(product);
      } else {
        setFiteredProduct(berluckyProductData);
      }
    } else {
      const berlucky = berluckyProductData?.filter(
        (item) =>
          item &&
          (item.product_phone.toString().includes(text) ||
            item.product_sumber.toString().includes(text) ||
            item.product_price.toString().includes(text))
      );

      if (berluckyCate) {
        const product = berlucky?.filter((p) =>
          p.default_cate.includes(berluckyCate.toString())
        );
        setFiteredProduct(product);
      } else {
        setFiteredProduct(berlucky);
      }
    }
  };

  return (
    <section id="berlucky-page">
      <HeadPageComponent
        h1={t("เบอร์มงคล")}
        berluckyAmount={berluckyProductData.length}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "เบอร์มงคล", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <h2 className="head-title-file">
              <label
                htmlFor="file-upload"
                className={`custom-file-upload ${fileError ? "error" : ""}`}
              >
                <FontAwesomeIcon icon={faFile} /> {filename}
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".xls, .xlsx"
                ref={inputFile}
                onChange={onSelectFile}
              />
              <ButtonUI
                className="btn-fileupload"
                onClick={uploadFile}
                on="save"
                width="lg"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faFileImport} />}
              >
                {t("Upload Excel")}
              </ButtonUI>
            </h2>
            <h2 className="head-title">
              <ButtonUI
                onClick={onExportFile}
                on="delete"
                width="lg"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faDownload} />}
              >
                {t("Export Excel")}
              </ButtonUI>
            </h2>
            <h2 className="head-title">
              <Link to="/luckynetwork" underline="none">
                <ButtonUI
                  on="create"
                  width="lg"
                  isLoading={false}
                  icon={<FontAwesomeIcon icon={faGear} />}
                >
                  {t("ตั้งค่าเครือข่าย")}
                </ButtonUI>
              </Link>
            </h2>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="select-filter-page">
                {t("หมวดหมู่เบอร์มงคล")}
              </InputLabel>
              <Select
                labelId="select-filter-page"
                autoWidth
                id="filter-page-control"
                label="Page Control"
                className="input-page"
                size="small"
                onChange={(e) => handleBerluckyCateChange(e.target.value)}
                value={berluckyCate}
              >
                <MenuItem value="">{t("None")}</MenuItem>
                {berluckyCateData &&
                  berluckyCateData?.map((cate) => (
                    <MenuItem key={cate.bercate_id} value={cate.bercate_id}>
                      {" "}
                      {cate.bercate_name}{" "}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div className="action-right">
              <TextField
                value={searchInput}
                label="ค้นหาเบอร์"
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchData(e)}
              />
              <ButtonUI
                onClick={berluckyAddHandle}
                style={{ width: "120px" }}
                className="btn-add-berlucky"
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("เพิ่มสินค้า")}
              </ButtonUI>
            </div>
          </div>
        </div>

        {/* berlucky Table Here */}
        <DataGridBerluckyProduct
          filteredProduct={filteredProduct}
          packageOptions={packageOptions}
          setRefreshData={setRefreshData}
          setSpawnActive={setSpawnActive}
          bercateOption={bercateOption}
          bernetworksOption={bernetworksOption}
          setBerluckyProductData={setBerluckyProductData}
          setFiteredProduct={setFiteredProduct}
          berluckyCate={berluckyCate}
          berluckyProductData={berluckyProductData}
          berluckyDuplicateProductsData={berluckyDuplicateProductsData}
        />

        {/* berlucky Modal Add Here */}
        {openModalAdd && (
          <BerluckyAddModal
            t={t}
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            packageOptions={packageOptions}
            bercateOption={bercateOption}
            bernetworksOption={bernetworksOption}
            setSpawnActive={setSpawnActive}
            setRefreshData={setRefreshData}
            setBerluckyProductData={setBerluckyProductData}
            berluckyProductData={berluckyProductData}
            setFiteredProduct={setFiteredProduct}
            berluckyCate={berluckyCate}
          />
        )}
      </div>
    </section>
  );
}
