const appConfigDEV = {
  isDevMode: true,
  language: "th",
  timeZone: "Asia/Bangkok",
  languageAvailable: ["th"],
  // apiPath: "http://localhost:8000/api/backoffice/v1/",
  // uploadPath: "http://localhost:8000/",
  // webPath: "http://localhost:8000/",
  apiPath:"http://192.168.1.54:8000/api/backoffice/v1/",
  uploadPath:"http://192.168.1.54:8000/",
  webPath:"http://192.168.1.54:8000/",
  pages: {
    dashboard: true,
    messages: false,
    contactMessage: true,
    inbox: true,
    orders: true,
    shipping: true,
    subscribe: false,
    productcate: false,
    members: false,
    slides: true,
    menu: false,
    category: true,
    posts: true,
    article: true,
    another: true,
    reports: false,
    webinfo: true,
    bankinfo: true,
    languages: false,
    admins: true,
    configs: true,
    profile: false,
    groups: {
      notify: true,
      article: true,
      promotion: true,
      products: true,
      prediction: true,
      report: false,
      system: true,
    },
    products: {
      berlucky: true,
      berluckycate: true,
      fiber: true,
      fibercate: true,
      package: true,
      packagecate: true,
      move: true,
      movecate: true,
      travel: true,
      travelcate: false,
    },
  },
  features: {
    multilingual: false,
    flexibleCategory: true,
    multipleImage: true,
    seo: true,
    price: false,
    tag: true,
    iframe: true,
    redirect: true,
    social: false,
    notify: true,
  },
};

const appConfigPROD = {
  isDevMode: false,
  language: "th",
  defaultLanguage: "th",
  timeZone: "Asia/Bangkok",
  languageAvailable: ["th"],
  apiPath: "https://www.simnetunlimited.com/api/backoffice/v1/",
  uploadPath: "https://simnetunlimited.com/",
  webPath: "https://simnetunlimited.com/",
  pages: {
    dashboard: true,
    messages: false,
    contactMessage: true,
    inbox: true,
    orders: true,
    shipping: true,
    subscribe: false,
    productcate: false,
    members: false,
    slides: true,
    menu: false,
    category: true,
    posts: true,
    article: true,
    another: true,
    reports: false,
    webinfo: true,
    bankinfo: true,
    languages: false,
    admins: true,
    configs: true,
    profile: false,
    groups: {
      notify: true,
      article: true,
      promotion: true,
      products: true,
      prediction: true,
      report: false,
      system: true,
    },
    products: {
      berlucky: true,
      berluckycate: true,
      fiber: true,
      fibercate: true,
      package: true,
      packagecate: true,
      move: true,
      movecate: true,
      travel: true,
      travelcate: false,
    },
  },
  features: {
    multilingual: false,
    flexibleCategory: true,
    multipleImage: true,
    seo: true,
    price: false,
    tag: true,
    iframe: true,
    redirect: true,
    social: false,
    notify: true,
  },
};

// export default appConfigDEV;
export default appConfigPROD;
