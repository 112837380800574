import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svCreatePredictNumbCate } from "../../../services/productServices/berpredict.service";

import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
const thumbnailDefault = {
  thumbnail: true,
  src: "",
  file: null,
  name: null,
  remove: false,
};

export default function PredictNumbCateAddModal({
  open,
  setOpen,
  setRefreshData,
  maxPriority,
}) {
  const { t } = useTranslation("packagecate-page");
  const [isFetching, setIsFetching] = useState(false);
  const [previews, setPreviews] = useState(thumbnailDefault);

  const [isError, setIsError] = useState({
    numbcate_name: false,
  });
  const [addData, setAddData] = useState({
    numbcate_title: "",
    numbcate_name: "",
    numbcate_want: "",
    numbcate_unwant: "",
    numbcate_display: true,
    numbcate_priority: maxPriority + 1,
  });

  const setPreviewHandler = (data) => {
    setAddData({ ...addData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setAddData((prevState) => {
        return { ...prevState, numbcate_priority: addData.numbcate_priority + 1 };
      });
    } else if (addData.numbcate_priority > 1) {
      setAddData((prevState) => {
        return { ...prevState, numbcate_priority: addData.numbcate_priority - 1 };
      });
    }
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      numbcate_name: addData.numbcate_name.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
      }

      formData.append("numbcate_title", addData.numbcate_title || "");
      formData.append("numbcate_name", addData.numbcate_name);
      formData.append("numbcate_want", addData.numbcate_want || "");
      formData.append("numbcate_unwant", addData.numbcate_unwant || "");
      formData.append("numbcate_display", addData.numbcate_display ? 1 : 0);
      formData.append("numbcate_priority", addData.numbcate_priority);

      saveHandler(formData);
    } 
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svCreatePredictNumbCate(formData).then((res) => {
      console.log(res)
      setOpen(false);
      setIsFetching(false);

      if (res.status === 201) {
        setRefreshData((prev) => prev + 1);
        SwalUI({ status: res.data.status, description: res.data.description });
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="predictnumbcate-add-modal">
          <section id="predictnumbcate-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("เพิ่มหมวดหมู่")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="edit-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                  </FieldsetUI>

                  <div className="predictnumbcate-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_title: e.target.value,
                            };
                          })
                        }
                        value={addData.numbcate_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.numbcate_title}
                        id="ad-numbcate_title"
                        label="ชื่อย่อ"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_name: e.target.value,
                            };
                          })
                        }
                        value={addData.numbcate_name}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.numbcate_name}
                        id="ad-numbcate_name"
                        label="ชื่อหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_want: e.target.value,
                            };
                          })
                        }
                        value={addData.numbcate_want}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-numbcate_want"
                        label="เลขที่ต้องการ"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_unwant: e.target.value,
                            };
                          })
                        }
                        value={addData.numbcate_unwant}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-numbcate_unwant"
                        label="เลขที่ไม่ต้องการ"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      numbcate_display: e.target.checked,
                                    })
                                  }
                                  checked={addData.numbcate_display ? true : false}
                                />
                              }
                              label={t("การแสดงผล")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {addData.numbcate_priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={addValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
