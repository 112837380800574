import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ButtonUI from "../../../../components/ui/button/button";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import LuckyPackageModalEdit from "./LuckyPackageModalEdit";

import { svDeleteLuckyPackage } from "../../../../services/productServices/berlucky.service";

const modalSwal = withReactContent(Swal);

function DataGridLuckyPackage({
  filteredData,
  benefitData,
  privilegeData,
  setFiteredData,
  setLuckyPackageData,
  setMaxPriority,
  setRefreshData,
}) {
  const { t } = useTranslation("berlucky-page");
  const webPath = useSelector((state) => state.app.webPath);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [productEdit, setProductEdit] = useState(null);

  const editHandle = (row) => {

    const preProduct = {

      ...row,
      imageTitle: row.thumbnail_title,
      imageAlt: row.thumbnail_alt,
      image: row.thumbnail_link,
      imageName: row.thumbnail_title,
      fixed_ip: row.fixed_ip ? true : false,
      display: row.display ? true : false,
      defaults: row.defaults ? true : false,
    };
    console.log(row);
    setProductEdit(preProduct);
    setOpenEditModal(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteLuckyPackage(row.id).then((res) => {
            const result = res.data;
            if (res.status === 200) {
              setRefreshData(prev => prev + 1)
              SwalUI({
                status: result.status,
                description: result.description,
              });
            } else {
              SwalUI({
                status: result.status,
                description: result.description,
              });
            }
          });
        }
      });
  };

  const columns = [
    {
      //   width: 150,
      flex: 1 / 2,
      field: "id",
      headerName: "[ ID แพ็คเกจ ]",
      headerAlign: "left",
      align: "center",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1,
      field: "title",
      headerName: "ชื่อแพ็คเกจ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1,
      field: "details",
      headerName: "ชื่อย่อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1,
      field: "price_per_month",
      headerName: "ราคาแพ็คเกจ/เดือน",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "internet_volume",
      headerName: "ปริมาณเน็ต (GB)",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 140,
      flex: 1,
      field: "call_credit",
      headerName: "โทรทุกเครือข่าย (นาที)",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 140,
      flex: 2,
      field: "benefit_ids",
      headerName: "สิทธิประโยชน์",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          <div style={{ display: "flex", gap: ".5rem", flexWrap: "wrap" }}>
            {params.row.thumbnail_link?.map((img,index) => (
              <figure key={index}>
                <img src={webPath + img} alt="" width={80} />{" "}
              </figure>
            ))}
          </div>
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการสินค้า",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            // rowHeight={75}
            getRowHeight={() => 'auto'} // set row height auto
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>

      {/* Edit Modal Here */}
      {openEditModal && (
        <LuckyPackageModalEdit
          t={t}
          open={openEditModal}
          setOpen={setOpenEditModal}
          productEdit={productEdit}
          benefitData={benefitData}
          privilegeData={privilegeData}
          setRefreshData={setRefreshData}
        />
      )}

      {/* Create Modal */}
    </Fragment>
  );
}

export default DataGridLuckyPackage;
