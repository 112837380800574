import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMinus, faRedo } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import CKeditorComponent from "../../../components/ui/ckeditor/ckeditor";
import { createMoveProductService } from "../../../services/productServices/move.service";
import "./move.scss";

const modalSwal = withReactContent(Swal);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MoveAddModal({
  open,
  setOpen,
  moveCateData,
  benefitData,
  maxPriority,
  setRefreshData,
}) {
  const { t } = useTranslation("move-page");
  const [previews, setPreviews] = useState({ src: "", file: null, name: null });
  const [isError, setIsError] = useState({
    thumbnail: false,
    title: false,
    price: false,
    move_cate_id: false,
    call_minutes: false,
    internet_volume: false,
    sim_gen: false,
    package_options: false,
  });
  const initProductData = {
    title: "",
    details: "",
    benefit_ids: "",
    price: "",
    discount: "",
    move_cate_id: "",
    imageName: "",
    thumbnail_title: "",
    thumbnail_alt: "",
    package_options: "",
    sim_gen: "",
    internet_volume: "",
    call_minutes: "",

    priority: maxPriority + 1,
    display: true,
    unlimited_wifi: false,
    recommended: false,
    voice_hd: false,
  };
  const dispatch = useDispatch();
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const language = useSelector((state) => state.app.language);
  const [addData, setAddData] = useState(initProductData);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [ckDetailsValue, setCkDetailsValue] = useState("");
  const [ckTermsValue, setCkTermsValue] = useState("");
  const [moreImage, setMoreImage] = useState([]);
  const scrollRef = useRef(null);

  /* Set CKeditor value */
  useEffect(() => {
    setAddData((prev) => {
      return {
        ...prev,
        details_content: ckDetailsValue,
        terms_content: ckTermsValue,
      };
    });
  }, [ckDetailsValue, ckTermsValue]);

  const setPreviewHandler = (data) => {
    setAddData({ ...addData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const setMoreImagePreviewHandler = (data) => {
    if (data.file === undefined) {
      const result = moreImage.filter((m, index) => index !== data.index);
      setMoreImage(result);
    } else {
      const result = moreImage.map((m, index) => {
        if (index === data.index) {
          m.src = data.src;
          m.file = data.file;
          m.name = data.file.name;
        }
        return m;
      });
      setMoreImage(result);
    }
  };

  const addMoreImage = (data) => {
    setMoreImage([
      ...moreImage,
      {
        src: data.src,
        file: data.file,
        name: data.file.name,
        index: moreImage.length,
        remove: true,
        title: "",
        alt: "",
      },
    ]);
  };

  const changeMoreImageData = (i, obj) => {
    const result = moreImage.map((m, index) => {
      return index === i ? obj : m;
    });
    setMoreImage(result);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setAddData((prevState) => {
        return { ...prevState, priority: addData.priority + 1 };
      });
    } else if (addData.priority > 1) {
      setAddData((prevState) => {
        return { ...prevState, priority: addData.priority - 1 };
      });
    }
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      thumbnail: previews.file === null || !previews.file,
      title: addData.title.trim().length < 1,
      price: !addData.price || addData.price === 0,
      move_cate_id: !addData.move_cate_id || addData.move_cate_id === "",
      call_minutes: addData.call_minutes.trim().length < 1,
      internet_volume: addData.internet_volume.trim().length < 1,
      sim_gen: !addData.sim_gen,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find(err => err === true)
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("thumbnail_name", addData.imageName);
      }

      formData.append(
        "thumbnail_title",
        addData.thumbnail_title ? addData.thumbnail_title : ""
      );
      formData.append(
        "thumbnail_alt",
        addData.thumbnail_alt ? addData.thumbnail_alt : ""
      );

      moreImage?.forEach((img, index) => {
        if (img.file) {
          formData.append("Images[]", img.file);
          formData.append("ImagesName[]", img.name);
          formData.append("ImagesTitle[]", img.title);
          formData.append("ImagesAlt[]", img.alt);
          formData.append("ImagesPosition[]", index);
        }
      });

      formData.append("title", addData.title);
      formData.append("details", addData.details ? addData.details : "");
      formData.append("price", addData.price);
      formData.append("discount", addData.discount);
      formData.append("call_minutes", addData.call_minutes);
      formData.append("internet_volume", addData.internet_volume);
      formData.append("sim_gen", addData.sim_gen);
      formData.append("details_content", ckDetailsValue ? ckDetailsValue : "");
      formData.append("terms_content", ckTermsValue ? ckTermsValue : "");
      formData.append(
        "benefit_ids",
        addData.benefit_ids ? addData.benefit_ids : ""
      );
      formData.append("move_cate_id", addData.move_cate_id);
      formData.append("package_options", addData.package_options);

      formData.append("unlimited_wifi", addData.unlimited_wifi ? 1 : 0);
      formData.append("voice_hd", addData.voice_hd ? 1 : 0);
      formData.append("display", addData.display ? 1 : 0);
      formData.append("recommended", addData.recommended ? 1 : 0);
      formData.append("priority", addData.priority);
      formData.append("language", language);
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    createMoveProductService(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="move-add-modal">
          <section id="move-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("เพิ่มสินค้า")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting ${
                      isError.thumbnail ? "error" : ""
                    }`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="add-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                    <div className="image-detail">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return { ...prevState, imageName: e.target.value };
                          })
                        }
                        value={addData.imageName}
                        className={`text-field-custom ${
                          !previews.file ? "inp-hidden" : ""
                        }`}
                        fullWidth={true}
                        error={false}
                        id="image-name"
                        label="Image name"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_title: e.target.value,
                            };
                          })
                        }
                        value={addData.thumbnail_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-title"
                        label="Image title"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_alt: e.target.value,
                            };
                          })
                        }
                        value={addData.thumbnail_alt}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-tag"
                        label="Alt description"
                        size="small"
                      />
                    </div>
                  </FieldsetUI>
                  <FieldsetUI
                    className="more-image-setting"
                    label={t("Add More Image")}
                  >
                    {moreImage.map((m, index) => (
                      <div className="image-control" key={index}>
                        <PreviewImageUI
                          className="add-more-image"
                          previews={{
                            src: m.src,
                            file: m.file,
                            index,
                            removeId: m.id,
                            remove: true,
                          }}
                          setPreviews={setMoreImagePreviewHandler}
                        />
                        <div className="image-detail">
                          {m.file && (
                            <TextField
                              onChange={(e) =>
                                changeMoreImageData(index, {
                                  ...m,
                                  name: e.target.value,
                                })
                              }
                              value={m.name}
                              className="text-field-custom"
                              fullWidth={true}
                              id={`image-name-${index}`}
                              label={`Image Name ${index + 1}`}
                              size="small"
                            />
                          )}
                          <TextField
                            onChange={(e) =>
                              changeMoreImageData(index, {
                                ...m,
                                title: e.target.value,
                              })
                            }
                            value={m.title}
                            className="text-field-custom"
                            fullWidth={true}
                            error={isError.thumbnail_title}
                            id="image-title"
                            label="Image title"
                            size="small"
                          />
                          <TextField
                            onChange={(e) =>
                              changeMoreImageData(index, {
                                ...m,
                                alt: e.target.value,
                              })
                            }
                            value={m.alt}
                            className="text-field-custom"
                            fullWidth={true}
                            error={isError.thumbnail_alt}
                            id="image-tag"
                            label="Alt description"
                            size="small"
                          />
                        </div>
                      </div>
                    ))}

                    <div className="image-control">
                      <PreviewImageUI
                        srcError={"/images/add-image.jpg"}
                        className="add-image"
                        previews={{ src: "", file: "", remove: false }}
                        setPreviews={addMoreImage}
                      />
                    </div>
                  </FieldsetUI>

                  <div className="move-details">
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={addData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อสินค้า"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={addData.details}
                        title={addData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียดสินค้า"
                        size="small"
                      />
                    </div>

                    <div
                      className="input-xl-half"
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              price: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.price,
                            };
                          })
                        }
                        value={addData.price}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.price}
                        id="ad-price"
                        label="ราคา/เดือน"
                        size="small"
                      />
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              discount: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.discount,
                            };
                          })
                        }
                        value={addData.discount}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.discount}
                        id="ad-discount"
                        label="ส่วนลด/บาท"
                        size="small"
                      />
                    </div>

                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              call_minutes: e.target.value,
                            };
                          })
                        }
                        value={addData.call_minutes}
                        title={addData.call_minutes}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.call_minutes}
                        id="ad-call_minutes"
                        label="นาทีการโทร(นาที, ไม่จำกัด)"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              internet_volume: e.target.value,
                            };
                          })
                        }
                        value={addData.internet_volume}
                        title={addData.internet_volume}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.internet_volume}
                        id="ad-internet_volume"
                        label="ปริมาณอินเทอร์เน็ต(GB,ไม่จำกัด)"
                        size="small"
                      />
                    </div>

                    <div
                      className="input-xl-half"
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <FormControl
                        sx={{ m: 1, width: "50%" }}
                        style={{ width: "50%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="simgen-select">
                          ประเภทซิม(4G, 5G)
                        </InputLabel>
                        <Select
                          labelId="simgen-select"
                          label="ประเภทซิม(4G, 5G)"
                          value={addData.sim_gen}
                          error={isError.sim_gen}
                          onChange={(e) =>
                            setAddData((prevState) => {
                              return {
                                ...prevState,
                                sim_gen: e.target.value,
                              };
                            })
                          }
                        >
                          <MenuItem value={"4G"}>4G</MenuItem>
                          <MenuItem value={"5G"}>5G</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        sx={{ m: 1, width: "50%" }}
                        style={{ width: "50%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="simgen-select">
                          หมวดหมู่ย้ายค่าย
                        </InputLabel>
                        <Select
                          labelId="simgen-select"
                          label="หมวดหมู่ย้ายค่าย"
                          value={addData.move_cate_id}
                          error={isError.move_cate_id}
                          onChange={(e) =>
                            setAddData((prevState) => {
                              return {
                                ...prevState,
                                move_cate_id: e.target.value,
                              };
                            })
                          }
                        >
                          {moveCateData &&
                            moveCateData?.map((cate) => (
                              <MenuItem key={cate.id} value={cate.id}>
                                {cate.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              package_options: e.target.value,
                            };
                          })
                        }
                        value={addData.package_options}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-package_options"
                        label="ตัวเลือก"
                        size="small"
                        placeholder=""
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <Autocomplete
                        multiple
                        size="small"
                        id="checkboxes-tags-demo"
                        options={benefitData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedBenefits}
                        onChange={(evt, value) => {
                          const setIds = value
                            .map((value) => value.id)
                            .join(",");
                          setSelectedBenefits(value);
                          setAddData((prev) => {
                            return { ...prev, benefit_ids: "," + setIds + "," };
                          });
                        }}
                        renderOption={(props, option, { selected }) => {
                          // const selected = selectedBenefits.some(benefit => benefit.id === option.id)
                          return (
                            <li {...props} key={option.id}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                value={option.id}
                              />
                              {option.title}
                            </li>
                          );
                        }}
                        style={{ width: "100%", margin: 0 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="สิทธิประโยชน์"
                            placeholder=""
                            style={{
                              width: "100%",
                              marginLeft: 0,
                              marginBottom: "5px",
                            }}
                          />
                        )}
                      />
                    </div>

                    <h3 className="move-detail-title">{t("Content")}</h3>
                    <div className="ck-add-moveproduct">
                      <label className="ck-add-move-details">
                        Details content
                      </label>
                      <CKeditorComponent
                        ckNameId="ck-add-moveproduct"
                        value={ckDetailsValue}
                        onUpdate={setCkDetailsValue}
                      />
                    </div>
                    <div className="ck-add-moveproduct">
                      <label className="ck-add-move-termsconditions">
                        ข้อกำหนดและเงื่อนไข
                      </label>
                      <CKeditorComponent
                        ckNameId="ck-add-moveproduct"
                        value={ckTermsValue}
                        onUpdate={setCkTermsValue}
                      />
                    </div>

                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      display: e.target.checked,
                                    })
                                  }
                                  checked={addData.display}
                                />
                              }
                              label="การแสดงผล"
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      voice_hd: e.target.checked,
                                    })
                                  }
                                  checked={addData.voice_hd ? true : false}
                                />
                              }
                              label={t("4G HD Voice")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      unlimited_wifi: e.target.checked,
                                    })
                                  }
                                  checked={addData.unlimited_wifi}
                                />
                              }
                              label={t("WiFi ไม่จำกัด")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      recommended: e.target.checked,
                                    })
                                  }
                                  checked={addData.recommended}
                                />
                              }
                              label="สินค้าแนะนำ"
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {addData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={addValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default MoveAddModal;
