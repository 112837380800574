import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faMinus,
  faPlus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Autocomplete, Checkbox, Modal, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";

import ButtonUI from "../../../../components/ui/button/button";
import { createFiberProductService } from "../../../../services/productServices/fiber.service";
import { svCreateLuckyPackage } from "../../../../services/productServices/berlucky.service";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";

const displayLabel = { inputProps: { "aria-label": "display switch" } };

export default function LuckyPackageModalAdd(props) {
  const { t, open, setOpen, benefitData, maxPriority, setRefreshData } = props;
  const [isError, setIsError] = useState({
    title: false,
    price_per_month: false,
    call_credit: false,
    internet_volume: false,
    internet_speed: false,
  });
  const initProductData = {
    title: "",
    details:"",
    price_per_month: "",
    call_credit: "",
    benefit_ids: "",
    internet_speed: "",
    internet_volume: "",

    priority: maxPriority + 1,
    display: true,
    language: "th",
  };

  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const [formData, setFormData] = useState(initProductData);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setFormData((prevState) => {
        return { ...prevState, priority: formData.priority + 1 };
      });
    } else if (formData.priority > 1) {
      setFormData((prevState) => {
        return { ...prevState, priority: formData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    if (formData.title.toString().trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, title: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, title: false };
      });
    }

    if (formData.details.toString().trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, details: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, details: false };
      });
    }

    if (!formData.price_per_month || formData.price_per_month === 0) {
      setIsError((prev) => {
        return { ...prev, price_per_month: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, price_per_month: false };
      });
    }

    if (formData.internet_volume.toString().trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, internet_volume: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, internet_volume: false };
      });
    }

    if (formData.call_credit.toString().trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, call_credit: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, call_credit: false };
      });
    }

    if (isValid) {
      saveHandler();
    }
  };

  const saveHandler = () => {
    setIsFetching(true);
    svCreateLuckyPackage(formData).then((res) => {
      setOpen(false);
      if (res.status === 201) {
        setRefreshData((prev) => prev + 1);
        SwalUI({ status: res.data.status, description: res.data.description });
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }

      setIsFetching(false);
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="luckypackage-add-modal">
          <section id="luckypackage-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faPlus} /> {t("แก้ไขแพ็คเกจ")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="slide-details">
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              title: e.target.value,
                            };
                          })
                        }
                        value={formData.title}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อแพ็คเกจ"
                        size="small"
                      />
                    </div>
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={formData.details}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.details}
                        id="ad-details"
                        label="ชื่อย่อ"
                        size="small"
                      />
                    </div>
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              price_per_month: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.price_per_month,
                            };
                          })
                        }
                        value={formData.price_per_month}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.price_per_month}
                        id="ad-price"
                        label="ราคาแพ็คเกจ/เดือน"
                        size="small"
                      />
                    </div>
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              internet_volume: e.target.value,
                            };
                          })
                        }
                        value={formData.internet_volume}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.internet_volume}
                        id="ad-internetvolume"
                        label="ปริมาณเน็ต (GB)"
                        size="small"
                      />
                    </div>
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              call_credit: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.call_credit,
                            };
                          })
                        }
                        value={formData.call_credit}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.call_credit}
                        id="ad-callcredit"
                        label="โทรทุกเครือข่าย (นาที)"
                        size="small"
                      />
                    </div>
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              internet_speed: e.target.value,
                            };
                          })
                        }
                        value={formData.internet_speed}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.internet_speed}
                        id="ad-internetspeed"
                        label="ความเร็วหลังเน็ตเต็มสปีดหมด"
                        size="small"
                        placeholder="6 Mbps"
                      />
                    </div>

                    <div className="input-full">
                      <Autocomplete
                        multiple
                        size="small"
                        id="checkboxes-tags-demo"
                        options={benefitData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedBenefits}
                        onChange={(evt, value) => {
                          const setIds = value
                            .map((value) => value.id)
                            .join(",");
                          setSelectedBenefits(value);
                          setFormData((prev) => {
                            return { ...prev, benefit_ids: "," + setIds + "," };
                          });
                        }}
                        renderOption={(props, option, { selected }) => {
                          // const selected = selectedBenefits.some(benefit => benefit.id === option.id)
                          return (
                            <li {...props} key={option.id}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                value={option.id}
                              />
                              {option.title}
                            </li>
                          );
                        }}
                        style={{ width: "100%", margin: 0 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="สิทธิประโยชน์"
                            placeholder=""
                            style={{
                              width: "100%",
                              marginLeft: 0,
                              marginBottom: "5px",
                            }}
                          />
                        )}
                      />
                    </div>

                    <div
                      className="input-full"
                      style={{ display: "flex", gap: "1rem" }}
                    >
                      <div className="input-group">
                        <ButtonUI
                          color="error"
                          onClick={(e) => priorityHandler(false)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </ButtonUI>
                        <span className="title">
                          {t("Priority")} {formData.priority}
                        </span>
                        <ButtonUI onClick={(e) => priorityHandler(true)}>
                          <FontAwesomeIcon icon={faAdd} />
                        </ButtonUI>
                      </div>
                      <div className="group">
                        <span>{t("การแสดงผล")}</span>
                        <Switch
                          {...displayLabel}
                          checked={formData.display}
                          onChange={(e) =>
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                display: e.target.checked,
                              };
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Box>

                <div className="btn-action">
                  <ButtonUI
                    // loader={true}
                    isLoading={isFetching}
                    onClick={editValidators}
                    className="btn-save"
                    on="save"
                    width="md"
                  >
                    {t("บันทึก")}
                  </ButtonUI>
                  <ButtonUI
                    onClick={() => setOpen(false)}
                    icon={<FontAwesomeIcon icon={faRedo} />}
                    className="btn-cancel"
                    on="cancel"
                    width="md"
                  >
                    {t("ยกเลิก")}
                  </ButtonUI>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
