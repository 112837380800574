import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBoxOpen, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import DataGridPackageCate from "./datagrid/DataGridPackageCate";
import { getPackageCateService } from "../../../services/productServices/package.service";

import PackageCateEditModal from "./PackageCateEditModal";
import PackageCateAddModal from "./PackageCateAddModal";

import "./package.scss";
export default function PackageCate() {
  const { t } = useTranslation("packagecate-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [packageType, setPackageType] = useState("");
  const dispatch = useDispatch();

  const [packageCateData, setPackageCateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(true));
    getPackageCateService().then((res) => {
      if (res.status === 200) {
        const maxPriority = res.data.maxPriority;
        const result = res.data.packages?.map((pac) => {
          return {
            ...pac,
            pin: pac.pin ? true : false,
            display: pac.display ? true : false,
          };
        });

        if (packageType) {
          const filtered = result.filter((p) => p.cate_type === packageType);
          setFilteredData(filtered);
        } else {
          setFilteredData(result);
        }

        setPackageCateData(result);
        setMaxPriority(maxPriority);
      } else {
        console.log("error");
      }
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const handlePackageTypeChange = (value) => {
    setPackageType(value);

    if (!value || value === "") {
      setFilteredData(packageCateData);
    } else {
      const filtered = packageCateData.filter((p) => p.cate_type === value);
      setFilteredData(filtered);
    }
  };
  
  const modalAddHandler = () => {
    setPackageType("")
    setFilteredData(packageCateData);
    setOpenAdd(true)
  }

  return (
    <section id="packagecate-page">
      <HeadPageComponent
        h1={t("หมวดหมู่แพ็คเกจ")}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "หมวดหมู่แพ็คเกจ", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={() => setRefreshData(refreshData + 1)}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="select-filter-catetype">
                {t("ประเภทหมวดหมู่")}
              </InputLabel>
              <Select
                labelId="select-filter-catetype"
                autoWidth
                id="filter-catetype-control"
                label="Cate Type Control"
                className="input-catetype"
                size="small"
                onChange={(e) => handlePackageTypeChange(e.target.value)}
                value={packageType}
              >
                <MenuItem value="">{t("None")}</MenuItem>
                <MenuItem value="true">{t("true")}</MenuItem>
                <MenuItem value="dtac">{t("dtac")}</MenuItem>
              </Select>
            </FormControl>
            <ButtonUI
              onClick={modalAddHandler}
              style={{ width: "120px" }}
              className="btn-add-packagecate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มหมวดหมู่")}
            </ButtonUI>
          </div>
        </div>

        {/* Cate Table Here */}
        <DataGridPackageCate
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setProductEdit={setProductEdit}
          setFilteredData={setFilteredData}
          setPackageCateData={setPackageCateData}
          setMaxPriority={setMaxPriority}
        />

        {/* packagecate Modal Add Here */}
        {openAdd && (
          <PackageCateAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            setFilteredData={setFilteredData}
            setPackageCateData={setPackageCateData}
            setMaxPriority={setMaxPriority}
            maxPriority={maxPriority}
          />
        )}

        {/* packagecate Modal Edit Here */}
        {openEdit && (
          <PackageCateEditModal
            productEdit={productEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            setFilteredData={setFilteredData}
            setPackageCateData={setPackageCateData}
            setMaxPriority={setMaxPriority}
          />
        )}
      </div>
    </section>
  );
}
