import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRedo } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ButtonUI from "../../../components/ui/button/button";
import { svCreateBerlucky } from "../../../services/productServices/berlucky.service";
import { appActions } from "../../../store/app-slice";

export default function BerluckyAddModal(props) {
  const {
    t,
    open,
    setOpen,
    packageOptions,
    bercateOption,
    bernetworksOption,
    setBerluckyProductData,
    berluckyProductData,
    setRefreshData,
  } = props;
  const [isError, setIsError] = useState({
    product_phone: false,
    product_price: false,
    default_cate: false,
    product_package: false,
    network_name: false,
  });
  const initProductData = {
    product_phone: "",
    product_price: "",
    default_cate: "",
    product_comment: "",
    product_discount: 0,
    product_package: "",
    product_sumber: 0,
    network_name:"",
    product_display: "yes",
    product_pin: "no",
    monthly_status: "no",
  };
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const [addData, setAddData] = useState(initProductData);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedCates, setSelectedCates] = useState([]);
  const modalSwal = withReactContent(Swal);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    // sumber
    const phone = addData.product_phone.split("").map(Number);
    const sumber = phone.reduce((sum, digit) => sum + digit, 0);
    setAddData((prev) => ({ ...prev, product_sumber: sumber }));
  }, [addData.product_phone]);

  const addValidators = () => {
    let isValid = true;

    const defaultCate = addData.default_cate.split(",");
    const isCateEmpty = defaultCate.every((cate) => cate === "");

    if (isCateEmpty) {
      setIsError((prev) => {
        return { ...prev, default_cate: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, default_cate: false };
      });
    }

    if (addData.product_phone.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, product_phone: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, product_phone: false };
      });
    }

    if (!addData.product_price || addData.product_price === 0) {
      setIsError((prev) => {
        return { ...prev, product_price: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, product_price: false };
      });
    }

    if(addData.network_name.trim().length < 1){
      setIsError((prev) => {
        return { ...prev, network_name: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, network_name: false };
      });
    }

    if (isValid) {
      saveHandler();
    }
  };

  const saveHandler = () => {
    setIsFetching(true);
    svCreateBerlucky(addData).then((res) => {
      setOpen(false);
      if (res.status === 200) {
        const newData = res.data.newProduct;
        const newProduct = {
          ...newData,
          default_cate: newData.default_cate?.split(","),
        };
        const productAll = [newProduct, ...berluckyProductData];
        setBerluckyProductData(productAll);
        dispatch(appActions.setBerluckyAll(productAll));

        setTimeout(() => {
          setRefreshData(prev => prev + 1)
        }, 1000)

        modalSwal.fire({
          position: "center",
          width: 450,
          icon: "success",
          title: "Successful",
          text: res.data.description,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        modalSwal.fire({
          position: "center",
          width: 450,
          icon: "error",
          title: "Failed",
          text: res.data.description,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      setIsFetching(false);
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="berlucky-add-modal">
          <section id="berlucky-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("เพิ่มเบอร์มงคล")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="berlucky-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              product_phone: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.product_phone,
                            };
                          })
                        }
                        value={addData.product_phone}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.product_phone}
                        id="ad-product_phone"
                        label="หมายเลข"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              product_comment: e.target.value,
                            };
                          })
                        }
                        value={addData.product_comment}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.product_comment}
                        id="ad-product_comment"
                        label="comment"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              product_price: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.product_price,
                            };
                          })
                        }
                        value={addData.product_price}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.product_price}
                        id="ad-product_price"
                        label="ราคา"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              product_discount: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.product_discount,
                            };
                          })
                        }
                        value={addData.product_discount}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.product_discount}
                        id="ad-product_discount"
                        label="ส่วนลด %"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <Autocomplete
                        multiple
                        size="small"
                        id="checkboxes-tags-demo1"
                        options={bercateOption}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.bercate_name}
                        value={selectedCates}
                        onChange={(evt, value) => {
                          const setIds = value
                            .map((value) => value.bercate_id)
                            .join(",");
                          setSelectedCates(value);
                          setAddData((prev) => {
                            return {
                              ...prev,
                              default_cate: "," + setIds + ",",
                            };
                          });
                        }}
                        renderOption={(props, option, { selected }) => {
                          // const selected = selectedPackages.some(benefit => benefit.id === option.id)
                          return (
                            <li {...props} key={option.id}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                value={option.bercate_id}
                              />
                              {option.bercate_name}
                            </li>
                          );
                        }}
                        style={{ width: "100%", margin: 0 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="หมวดหมู่เริ่มต้น"
                            error={isError.default_cate}
                            placeholder=""
                            style={{
                              width: "100%",
                              marginLeft: 0,
                              marginBottom: "5px",
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="input-full">
                      <FormControl
                        sx={{ m: 1, width: "50%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="simgen-select">แพ็คเกจ</InputLabel>
                        <Select
                          labelId="simgen-select"
                          label="แพ็คเกจ"
                          value={addData.product_package}
                          error={isError.product_package}
                          onChange={(e) =>
                            setAddData((prevState) => {
                              return {
                                ...prevState,
                                product_package: e.target.value,
                              };
                            })
                          }
                        >
                          {packageOptions &&
                            packageOptions?.map((p, ind) => (
                              <MenuItem key={ind} value={p.id}>
                                {p.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-full">
                      <FormControl
                        sx={{ m: 1, width: "50%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="network-select">เครือข่าย</InputLabel>
                        <Select
                          labelId="network-select"
                          label="เครือข่าย"
                          value={addData.network_name}
                          error={isError.network_name}
                          onChange={(e) =>
                            setAddData((prevState) => {
                              return {
                                ...prevState,
                                network_name: e.target.value,
                              };
                            })
                          }
                        >
                          {bernetworksOption &&
                           bernetworksOption?.map((net, ind) => (
                              <MenuItem key={ind} value={net.network_name}>
                                {net.network_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      product_display: e.target.checked
                                        ? "yes"
                                        : "no",
                                    })
                                  }
                                  checked={
                                    addData.product_display === "yes"
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={t("การแสดงผล")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      product_pin: e.target.checked
                                        ? "yes"
                                        : "no",
                                    })
                                  }
                                  checked={
                                    addData.product_pin === "yes" ? true : false
                                  }
                                />
                              }
                              label={t("VIP")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      monthly_status: e.target.checked
                                        ? "yes"
                                        : "no",
                                    })
                                  }
                                  checked={
                                    addData.monthly_status === "yes"
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={t("เบอร์รายเดือน")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>

                <div className="btn-action">
                  <ButtonUI
                    isLoading={isFetching}
                    onClick={addValidators}
                    className="btn-save"
                    on="save"
                    width="md"
                  >
                    {t("Save")}
                  </ButtonUI>
                  <ButtonUI
                    onClick={() => setOpen(false)}
                    icon={<FontAwesomeIcon icon={faRedo} />}
                    className="btn-cancel"
                    on="cancel"
                    width="md"
                  >
                    {t("Cancel")}
                  </ButtonUI>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
