import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControlLabel, FormGroup, Modal, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";

import ButtonUI from "../../../../components/ui/button/button";
import PreviewImageUI from "../../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../../components/ui/fieldset/fieldset";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import { svUpdatePreNetwork } from "../../../../services/productServices/prepaid.service";

function PreNetworkEditModal(props) {
  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };
  const { t, open, setOpen, productEdit, setRefreshData, webpath } = props;
  const [previews, setPreviews] = useState(thumbnailDefault);
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);
  const [isError, setIsError] = useState({
    network_name: false,
  });

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = webpath + productEdit.thumbnail;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });
  }, []);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      network_name: editData.network_name.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("thumbnail_name", editData.imageName);
      }

      formData.append("network_name", editData.network_name);
      formData.append("imagename", editData.thumbnail);
      formData.append("display", editData.display);
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePreNetwork(editData.id, formData).then((res) => {
      console.log(res);
      setOpen(false);
      if (res.status === 200) {
        setRefreshData((prev) => prev + 1);
        SwalUI({
          status: res.data.status,
          description: res.data.description,
        });
      } else {
        SwalUI({
          status: res.data.status,
          description: res.data.description,
        });
      }
      setIsFetching(false);
    });
  };

  return (
    <Modal
    disableEnforceFocus
    open={open}
    onClose={(e) => setOpen(false)}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box className="prepaidnet-edit-modal">
      <section id="prepaidcate-edit-page">
        <div className="card-control">
          <div className="card-head">
            <div className="head-action">
              <h2 className="head-title">
                <FontAwesomeIcon icon={faAdd} /> {t("แก้ไขเครือข่าย")}
              </h2>
            </div>
          </div>
          <div className="card-body" ref={scrollRef}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
            >
              <FieldsetUI
                className={`image-settingforprenet ${
                  isError.thumbnail ? "error" : ""
                }`}
                label={t("Image Info")}
              >
                <PreviewImageUI
                  className="add-image"
                  previews={previews}
                  setPreviews={setPreviewHandler}
                />
              </FieldsetUI>
              <div className="prepaidcate-details">
                <h3 className="prepaidcate-detail-title">
                  {t("รายละเอียด")}
                </h3>
                <div className="input-full">
                  <TextField
                    onChange={(e) =>
                      setEditData((prevState) => {
                        return {
                          ...prevState,
                          network_name: e.target.value,
                        };
                      })
                    }
                    value={editData.network_name}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.network_name}
                    id="ad-network_name"
                    label="ชื่อเครือข่าย"
                    size="small"
                  />
                </div>

                <div className="group-settings">
                  <h3 className="post-detail-title">{t("Settings")}</h3>
                  <div className="setting-controls">
                    <div className="switch-formforprenet">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) =>
                                setEditData({
                                  ...editData,
                                  display: e.target.checked,
                                })
                              }
                              checked={editData.display ? true : false}
                            />
                          }
                          label={t("การแสดงผล")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
          <div className="btn-action">
            <ButtonUI
              // loader={true}
              isLoading={isFetching}
              onClick={editValidators}
              className="btn-save"
              on="save"
              width="md"
            >
              {t("บันทึก")}
            </ButtonUI>
            <ButtonUI
              onClick={() => setOpen(false)}
              icon={<FontAwesomeIcon icon={faRedo} />}
              className="btn-cancel"
              on="cancel"
              width="md"
            >
              {t("ยกเลิก")}
            </ButtonUI>
          </div>
        </div>
      </section>
    </Box>
  </Modal>
  );
}

export default PreNetworkEditModal;
