import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { appActions } from "../../../store/app-slice";
import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import CKeditorComponent from "../../../components/ui/ckeditor/ckeditor";
import { svUpdateTravelSim } from "../../../services/productServices/travel.service";

const modalSwal = withReactContent(Swal);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TravelEditModal({
  open,
  setOpen,
  productEdit,
  benefitData,
  travelCateData,
  setRefreshData,
}) {
  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };
  const [isError, setIsError] = useState({
    thumbnail: false,
    title: false,
    price: false,
    quantity: false,
    travel_cate_id: false,
    lifetime: false,
    call_credit: false,
    free_call: false,
    package_options: false,
  });

  const { t } = useTranslation("travel-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  const [previews, setPreviews] = useState(thumbnailDefault);
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [moreImage, setMoreImage] = useState([]);
  const [moreImageRemove, setMoreImageRemove] = useState([]);
  const [ckDetailsValue, setCkDetailsValue] = useState(
    productEdit.details_content
  );
  const [ckTermsValue, setCkTermsValue] = useState(productEdit.terms_content);
  const scrollRef = useRef(null);

  useEffect(() => {
    const selectedBenefitIds = productEdit.benefit_ids?.split(",");
    const bOption = benefitData.filter((b) =>
      selectedBenefitIds?.includes(b.id.toString())
    );
    setSelectedBenefits(bOption);

    /* Set thumbnail */
    let thumbnail = uploadPath + productEdit.thumbnail_link;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });

    /* Set More Images */
    const moreImageArr = productEdit.images?.map((img, index) => {
      return {
        index: index + 1,
        id: img.id.toString(),
        src: uploadPath + img.image_link,
        srcDefault: uploadPath + img.image_link,
        title: img.image_title,
        alt: img.image_alt,
        language: img.language,
        name: null,
        file: null,
        remove: true,
      };
    });

    setMoreImage(moreImageArr);
  }, []);

  /* Set CKeditor value */
  useEffect(() => {
    setEditData((prev) => {
      return {
        ...prev,
        details_content: ckDetailsValue,
        terms_content: ckTermsValue,
      };
    });
  }, [ckDetailsValue, ckTermsValue]);

  const setMoreImagePreviewHandler = (data) => {
    if (data.file === undefined) {
      const result = moreImage.filter((m, index) => index !== data.index);
      setMoreImage(result);
    } else {
      const result = moreImage.map((m, index) => {
        if (index === data.index) {
          m.src = data.src;
          m.file = data.file;
          m.name = data.file.name;
        }
        return m;
      });
      setMoreImage(result);
    }

    if (data.removeId !== null) {
      setMoreImageRemove((prev) => [...prev, data.removeId]);
    }
  };

  const changeMoreImageData = (i, obj) => {
    const result = moreImage.map((m, index) => {
      return index === i ? obj : m;
    });
    setMoreImage(result);
  };

  const addMoreImage = (data) => {
    setMoreImage([
      ...moreImage,
      {
        src: data.src,
        file: data.file,
        name: data.file.name,
        index: moreImage.length,
        remove: true,
        title: "",
        alt: "",
      },
    ]);
  };

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;
    // if (
    //   (previews.file === undefined || previews.file === null) &&
    //   editData.image === ""
    // ) {
    //   setIsError({ ...isError, thumbnail: true });
    //   isValid = false;
    // }

    let error = {
      ...isError,
      title: editData.title.trim().length < 1,
      price: !editData.price || editData.price === 0,
      quantity: !editData.quantity || editData.quantity === 0,
      travel_cate_id: !editData.travel_cate_id || editData.travel_cate_id === "",
      call_credit: !editData.call_credit,
      lifetime: !editData.lifetime || editData.lifetime === 0,
      free_call: !editData.free_call || editData.free_call === "",
    };

    setIsError(() => error);
    const hasError = Object.values(error).find(err => err === true)
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("thumbnail_name", editData.imageName);
      }

      formData.append("thumbnail_link", editData.thumbnail_link || "");
      formData.append("thumbnail_title", editData.thumbnail_title || "");
      formData.append("thumbnail_alt", editData.thumbnail_alt || "");
      formData.append("moreImageRemove", moreImageRemove);

      moreImage?.forEach((img, index) => {
        if (img.file) {
          formData.append("Images[]", img.file);
          formData.append("ImagesName[]", img.name);
          formData.append("ImagesTitle[]", img.title);
          formData.append("ImagesAlt[]", img.alt);
          formData.append("ImagesPosition[]", index);
        } else {
          let linkName = img.srcDefault.split(uploadPath);
          formData.append(`EditImageTitle[]`, img.title);
          formData.append(`EditImageAlt[]`, img.alt);
          formData.append(`EditImageLink[]`, linkName[1] ? linkName[1] : "");
        }
      });

      formData.append("id", editData.id);
      formData.append("travel_cate_id", editData.travel_cate_id);
      formData.append("title", editData.title);
      formData.append("details", editData.details ? editData.details : "");
      formData.append("price", editData.price);
      formData.append("quantity", editData.quantity);
      formData.append("lifetime", editData.lifetime);
      formData.append("internet_details", editData.internet_details || "");
      formData.append("call_credit", editData.call_credit || "");
      formData.append("call_credit_details", editData.call_credit_details || "");
      formData.append("free_call", editData.free_call || "");
      formData.append("free_call_details", editData.free_call_details || "");
      formData.append("free_tiktok_details", editData.free_tiktok_details || "");
      
      formData.append("details_content", ckDetailsValue ? ckDetailsValue : "");
      formData.append("terms_content", ckTermsValue ? ckTermsValue : "");
      formData.append("package_options", editData.package_options);
      formData.append("priority", editData.priority);

      formData.append("unlimited_5g", editData.unlimited_5g ? 1 : 0);
      formData.append("free_wifi", editData.free_wifi ? 1 : 0);
      formData.append("free_tiktok", editData.free_tiktok ? 1 : 0);
      formData.append("free_socials", editData.free_socials ? 1 : 0);
      formData.append("language", language);

      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdateTravelSim(editData.id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="travel-edit-modal">
          <section id="travel-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขสินค้า")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="edit-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                    <div className="image-detail">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, imageName: e.target.value };
                          })
                        }
                        value={editData.imageName}
                        className={`text-field-custom ${
                          !previews.file ? "inp-hidden" : ""
                        }`}
                        fullWidth={true}
                        error={false}
                        id="image-name"
                        label="Image name"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_title: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-title"
                        label="Image title"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_alt: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_alt}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-tag"
                        label="Alt description"
                        size="small"
                      />
                    </div>
                  </FieldsetUI>
                  <FieldsetUI
                    className="more-image-setting"
                    label={t("Add More Image")}
                  >
                    {moreImage.map((m, index) => (
                      <div className="image-control" key={index}>
                        <PreviewImageUI
                          className="add-more-image"
                          previews={{
                            src: m.src,
                            file: m.file,
                            index,
                            removeId: m.id,
                            remove: true,
                          }}
                          setPreviews={setMoreImagePreviewHandler}
                        />
                        <div className="image-detail">
                          {m.file && (
                            <TextField
                              onChange={(e) =>
                                changeMoreImageData(index, {
                                  ...m,
                                  name: e.target.value,
                                })
                              }
                              value={m.name}
                              className="text-field-custom"
                              fullWidth={true}
                              id={`image-name-${index}`}
                              label={`Image Name ${index + 1}`}
                              size="small"
                            />
                          )}
                          <TextField
                            onChange={(e) =>
                              changeMoreImageData(index, {
                                ...m,
                                title: e.target.value,
                              })
                            }
                            value={m.title}
                            className="text-field-custom"
                            fullWidth={true}
                            error={isError.thumbnail_title}
                            id="image-title"
                            label="Image title"
                            size="small"
                          />
                          <TextField
                            onChange={(e) =>
                              changeMoreImageData(index, {
                                ...m,
                                alt: e.target.value,
                              })
                            }
                            value={m.alt}
                            className="text-field-custom"
                            fullWidth={true}
                            error={isError.thumbnail_alt}
                            id="image-tag"
                            label="Alt description"
                            size="small"
                          />
                        </div>
                      </div>
                    ))}

                    <div className="image-control">
                      <PreviewImageUI
                        srcError={"/images/add-image.jpg"}
                        className="add-image"
                        previews={{ src: "", file: "", remove: false }}
                        setPreviews={addMoreImage}
                      />
                    </div>
                  </FieldsetUI>

                  <div className="travel-details">
                    <h3 className="travel-detail-title">{t("รายละเอียด")}</h3>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={editData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อสินค้า"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={editData.details}
                        title={editData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียดสินค้า"
                        size="small"
                      />
                    </div>

                    <div
                      className="input-xl-half"
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              price: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.price,
                            };
                          })
                        }
                        value={editData.price}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.price}
                        id="ad-price"
                        label="ราคา/บาท"
                        size="small"
                      />
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              lifetime: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.lifetime,
                            };
                          })
                        }
                        value={editData.lifetime}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.lifetime}
                        id="ad-lifetime"
                        label="อายุการใช้งาน/วัน"
                        size="small"
                      />
                    </div>

                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              internet_details: e.target.value,
                            };
                          })
                        }
                        value={editData.internet_details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.call_minutes}
                        id="ad-internet_details"
                        label="รายละเอียด Internet"
                        size="small"
                        placeholder="NON-STOP INTERNET for 15GB at 5G/4G MAX SPEED"
                      />
                    </div>

                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              call_credit: e.target.value,
                            };
                          })
                        }
                        value={editData.call_credit}
                        // title={editData.internet_volume}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.call_credit}
                        id="ad-call_credit"
                        label="เครดิตการโทร/บาท"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              call_credit_details: e.target.value,
                            };
                          })
                        }
                        value={editData.call_credit_details}
                        // title={editData.internet_volume}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.call_credit_details}
                        id="ad-call_credit_details"
                        label="รายละเอียดเครดิตการโทร"
                        size="small"
                        placeholder="for local and international calls"
                      />
                    </div>

                    <div
                      className="input-xl-half"
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <FormControl
                        sx={{ m: 1, width: "50%" }}
                        style={{ width: "50%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="freecall-select">โทรฟรี</InputLabel>
                        <Select
                          labelId="freecall-select"
                          label="โทรฟรี"
                          value={editData.free_call}
                          error={isError.free_call}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                free_call: e.target.value,
                              };
                            })
                          }
                        >
                          <MenuItem value={"Free"}>Free</MenuItem>
                          <MenuItem value={"Unlimited call"}>
                            Unlimited call
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        sx={{ m: 1, width: "50%" }}
                        style={{ width: "50%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="travelcate-select">
                          หมวดหมู่ซิมท่องเที่ยว
                        </InputLabel>
                        <Select
                          labelId="travelcate-select"
                          label="หมวดหมู่ซิมท่องเที่ยว"
                          value={editData.travel_cate_id}
                          error={isError.travel_cate_id}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                travel_cate_id: e.target.value,
                              };
                            })
                          }
                        >
                          {travelCateData &&
                            travelCateData?.map((cate) => (
                              <MenuItem key={cate.id} value={cate.id}>
                                {cate.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              free_call_details: e.target.value,
                            };
                          })
                        }
                        value={editData.free_call_details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.free_call_details}
                        id="ad-free_call_details"
                        label="รายละเอียดโทรฟรี"
                        size="small"
                        placeholder="among TrueMove H numbers"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              free_tiktok_details: e.target.value,
                            };
                          })
                        }
                        value={editData.free_tiktok_details}
                        className="text-field-custom"
                        fullWidth={false}
                        error={false}
                        id="ad-free_tiktok_details"
                        label="รายละเอียด Free Tiktok"
                        size="small"
                        placeholder="Free 10 GB"
                      />
                    </div>
                    <div
                      className="input-xl-half"
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              quantity: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.quantity,
                            };
                          })
                        }
                        value={editData.quantity}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.quantity || editData.quantity < 1}
                        id="ad-quantity"
                        label="จำนวนคงเหลือ"
                        size="small"
                      />
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              quantity_sold: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.quantity_sold,
                            };
                          })
                        }
                        inputProps={
                          { readOnly: true, }
                        }
                        value={editData.quantity_sold}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.quantity_sold}
                        id="ad-quantity_sold"
                        label="จำนวนที่ขายไป"
                        size="small"
                      />
                    </div>
                    {false && (
                      <div className="input-full">
                        <Autocomplete
                          multiple
                          size="small"
                          id="checkboxes-tags-demo"
                          options={benefitData}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          value={selectedBenefits}
                          onChange={(evt, value) => {
                            const setIds = value
                              .map((value) => value.id)
                              .join(",");
                            setSelectedBenefits(value);
                            setEditData((prev) => {
                              return {
                                ...prev,
                                benefit_ids: "," + setIds + ",",
                              };
                            });
                          }}
                          renderOption={(props, option, { selected }) => {
                            // const selected = selectedBenefits.some(benefit => benefit.id === option.id)
                            return (
                              <li {...props} key={option.id}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                  value={option.id}
                                />
                                {option.title}
                              </li>
                            );
                          }}
                          style={{ width: "100%", margin: 0 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="สิทธิประโยชน์"
                              placeholder=""
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginBottom: "5px",
                              }}
                            />
                          )}
                        />
                      </div>
                    )}

                    <h3 className="travel-detail-title">{t("Content")}</h3>
                    <div className="ck-edit-travel">
                      <label className="ck-edit-travel-details">
                        Details content
                      </label>
                      <CKeditorComponent
                        ckNameId="ck-edit-travel"
                        value={ckDetailsValue}
                        onUpdate={setCkDetailsValue}
                      />
                    </div>
                    <div className="ck-edit-travel">
                      <label className="ck-edit-travel-termsconditions">
                        ข้อกำหนดและเงื่อนไข
                      </label>
                      <CKeditorComponent
                        ckNameId="ck-edit-travel"
                        value={ckTermsValue}
                        onUpdate={setCkTermsValue}
                      />
                    </div>

                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      unlimited_5g: e.target.checked,
                                    })
                                  }
                                  checked={editData.unlimited_5g ? true : false}
                                />
                              }
                              label={t("5G Unlimite")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      free_wifi: e.target.checked,
                                    })
                                  }
                                  checked={editData.free_wifi ? true : false}
                                />
                              }
                              label={t("Free WiFi")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      free_tiktok: e.target.checked,
                                    })
                                  }
                                  checked={editData.free_tiktok ? true : false}
                                />
                              }
                              label={t("Free Tiktok")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      free_socials: e.target.checked,
                                    })
                                  }
                                  checked={editData.free_socials ? true : false}
                                />
                              }
                              label={t("Free Socials")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {editData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default TravelEditModal;
