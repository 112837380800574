import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faBoxOpen,
  faRedo,
  faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";


import { appActions } from "../../store/app-slice";
import { svBankAll } from "../../services/bankinfo.service";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import ButtonUI from "../../components/ui/button/button";
import DataGridBank from "./datagrid/DataGridBank";
import BankAddModal from "./modal/BankAddModal";
import BankEditModal from "./modal/BankEditModal";

// import "./prepaidcate.scss";

function Bankinfo() {
  const { t } = useTranslation("prepaidcate-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [bankEdit, setBankEdit] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();

  const [bankData, setBankData] = useState([]);
  const [prepaidNetData, setPrepaidNetData] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);

  const [spawnActive, setSpawnActive] = useState(true);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svBankAll().then((res) => {
      if (res.status === 200) {
        const result = res.data;
        console.log(result.bankinfo);
        setBankData(result.bankinfo);
        setMaxPriority(result.maxPriority);
      } else {
        console.log(res);
      }

      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const setOpenAddHandle = () => {
    setOpenAdd(true);
  };

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  return (
    <section id="prepaidcate-page">
      <HeadPageComponent
        h1={t("ข้อมูลธนาคาร")}
        icon={<FontAwesomeIcon icon={faBuildingColumns} />}
        breadcrums={[{ title: "ข้อมูลธนาคาร", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>

            <ButtonUI
              onClick={setOpenAddHandle}
              style={{ width: "120px" }}
              className="btn-add-prepaidcate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มธนาคาร")}
            </ButtonUI>
          </div>
        </div>

        {/* Cate Table Here */}
        <DataGridBank
          filteredData={bankData}
          setOpenEdit={setOpenEdit}
          setBankEdit={setBankEdit}
          setRefreshData={setRefreshData}
        />

        {/* Product Modal Add Here */}
        {openAdd && (
          <BankAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            setRefreshData={setRefreshData}
            maxPriority={maxPriority}
          />
        )}

        {/*  Product Modal Edit Here */}
        {openEdit && (
          <BankEditModal
            bankEdit={bankEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </section>
  );
}

export default Bankinfo;
