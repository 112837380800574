import axios from "axios";

/* predict numbcate */
export const svPredictNumbcate = () => {
  return axios
    .get(`predict/numbcate/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreatePredictNumbCate = (formData) => {
  return axios
    .post(`predict/numbcate/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdatePredictNumbCate = (id, formData) => {
  return axios
    .post(`predict/numbcate/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeletePredictNumbcate = (id) => {
  return axios
    .delete(`predict/numbcate/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////

/* predict grade */
export const svPredictGrade = () => {
  return axios
    .get(`predict/grade/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdatePredictGrade = (id, formData) => {
  return axios
    .post(`predict/grade/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////

/* predict ber */
export const svPredictBer = () => {
  return axios
  .get(`predict/ber/data`)
  .then((res) => res)
  .catch((err) => err);
};

export const svUpdatePredictBer = (id, formData) => {
  return axios
  .post(`predict/ber/update/${id}`, formData)
  .then((res) => res)
  .catch((err) => err);
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////

/* predict sum ber */
export const svPredictSumber = () => {
  return axios
    .get(`predict/sumber/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdatePredicSumber = (id, formData) => {
  return axios
  .post(`predict/sumber/update/${id}`, formData)
  .then((res) => res)
  .catch((err) => err);
};