import axios from "axios";

/* Sim */
export const svTravelSim = () => {
  return axios
    .get(`travel/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdateTravelSim = (id, formData) => {
  return axios
    .post(`travel/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreateTravelSim = (formData) => {
  return axios
    .post(`travel/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeleteTravelSim = (id) => {
  return axios
    .delete(`travel/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};