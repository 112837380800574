import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { appActions } from "../../../store/app-slice";
import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svUpdatePrepaidSim } from "../../../services/productServices/prepaid.service";

function PrepaidEditModal({
  open,
  setOpen,
  productEdit,
  prepaidCateData,
  setRefreshData,
}) {
  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };
  const [isError, setIsError] = useState({
    thumbnail: false,
    title: false,
    price: false,
    prepaid_cate_id: false,
    quantity: false,
  });

  const { t } = useTranslation("prepaid-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [previews, setPreviews] = useState(thumbnailDefault);
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = uploadPath + productEdit.thumbnail_link;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });
  }, []);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      title: editData.title.trim().length < 1,
      price: !editData.price || editData.price === 0,
      quantity: !editData.quantity || editData.quantity === 0,
      prepaid_cate_id:
        !editData.prepaid_cate_id || editData.prepaid_cate_id === "",
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("thumbnail_name", editData.imageName);
      }
      formData.append("thumbnail_link", editData.thumbnail_link || "");
      formData.append("thumbnail_title", editData.thumbnail_title || "");
      formData.append("thumbnail_alt", editData.thumbnail_alt || "");
      formData.append("id", editData.id);
      formData.append("prepaid_cate_id", editData.prepaid_cate_id);
      formData.append("title", editData.title);
      formData.append("details", editData.details ? editData.details : "");
      formData.append("price", editData.price);
      formData.append("quantity", editData.quantity);
      formData.append("priority", editData.priority);
      formData.append("language", language);

      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePrepaidSim(editData.id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="prepaid-edit-modal">
          <section id="prepaid-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขสินค้า")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="edit-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                    <div className="image-detail">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, imageName: e.target.value };
                          })
                        }
                        value={editData.imageName}
                        className={`text-field-custom ${
                          !previews.file ? "inp-hidden" : ""
                        }`}
                        fullWidth={true}
                        error={false}
                        id="image-name"
                        label="Image name"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_title: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-title"
                        label="Image title"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_alt: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_alt}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-tag"
                        label="Alt description"
                        size="small"
                      />
                    </div>
                  </FieldsetUI>

                  <div className="prepaid-details">
                    <h3 className="prepaid-detail-title">{t("รายละเอียด")}</h3>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={editData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อสินค้า"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={editData.details}
                        title={editData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียดสินค้า"
                        size="small"
                      />
                    </div>

                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              price: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.price,
                            };
                          })
                        }
                        value={editData.price}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.price}
                        id="ad-price"
                        label="ราคา/บาท"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <FormControl size="small" className="form-control">
                        <InputLabel id="prepaidcate-select">
                          หมวดหมู่ซิมเติมเงิน
                        </InputLabel>
                        <Select
                          labelId="prepaidcate-select"
                          label="หมวดหมู่ซิมเติมเงิน"
                          value={editData.prepaid_cate_id}
                          error={isError.prepaid_cate_id}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                prepaid_cate_id: e.target.value,
                              };
                            })
                          }
                        >
                          {prepaidCateData &&
                            prepaidCateData?.map((cate) => (
                              <MenuItem key={cate.id} value={cate.id}>
                                {cate.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      className="input-xl-half"
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              quantity: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.quantity,
                            };
                          })
                        }
                        value={editData.quantity}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.quantity || editData.quantity < 1}
                        id="ad-quantity"
                        label="จำนวนคงเหลือ"
                        size="small"
                      />
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              quantity_sold: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.quantity_sold,
                            };
                          })
                        }
                        inputProps={
                          { readOnly: true, }
                        }
                        value={editData.quantity_sold}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.quantity_sold}
                        id="ad-quantity_sold"
                        label="จำนวนที่ขายไป"
                        size="small"
                      />
                    </div>

                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        {false && (
                          <>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          unlimited_5g: e.target.checked,
                                        })
                                      }
                                      checked={
                                        editData.unlimited_5g ? true : false
                                      }
                                    />
                                  }
                                  label={t("5G Unlimite")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          free_wifi: e.target.checked,
                                        })
                                      }
                                      checked={
                                        editData.free_wifi ? true : false
                                      }
                                    />
                                  }
                                  label={t("Free WiFi")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          free_tiktok: e.target.checked,
                                        })
                                      }
                                      checked={
                                        editData.free_tiktok ? true : false
                                      }
                                    />
                                  }
                                  label={t("Free Tiktok")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          free_socials: e.target.checked,
                                        })
                                      }
                                      checked={
                                        editData.free_socials ? true : false
                                      }
                                    />
                                  }
                                  label={t("Free Socials")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                          </>
                        )}
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {editData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default PrepaidEditModal;
