import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Autocomplete, Checkbox, Modal, Switch } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ButtonUI from "../../../components/ui/button/button";
import { updatePackageCateService } from "../../../services/productServices/package.service";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

import "./package.scss";
const modalSwal = withReactContent(Swal);

export default function PackageCateEditModal({
  productEdit,
  open,
  setOpen,
  setFilteredData,
  setPackageCateData,
  setMaxPriority,
}) {
  const { t } = useTranslation("packagecate-page");
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);

  const [isError, setIsError] = useState({ title: false, cate_type: false, });

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    if (editData.title.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, title: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, title: false };
      });
    }

    if (!editData.cate_type || editData.cate_type === "") {
      setIsError((prev) => {
        return { ...prev, cate_type: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, cate_type: false };
      });
    }

    if (isValid) {
      const formData = {
        id: editData.id,
        title: editData.title,
        cate_type: editData.cate_type,
        priority: editData.priority,
      };
      saveHandler(formData);
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    updatePackageCateService(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        const maxPriority = res.data.maxPriority;
        const packageCates = res.data.packageCates?.map((pac) => {
          return {
            ...pac,
            pin: pac.pin ? true : false,
            display: pac.display ? true : false,
          };
        });
        setPackageCateData(packageCates);
        setFilteredData(packageCates);
        setMaxPriority(maxPriority);

        SwalUI({ status: res.data.status, description: res.data.description });
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="packagecate-edit-modal">
          <section id="packagecate-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} />{" "}
                    {t("แก้ไขหมวดหมู่แพ็คเกจ")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="packagecate-details">
                    <h3 className="packagecate-detail-title">{t("รายละเอียด")}</h3>
                    <div className="input-xs-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={editData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-xs-half">
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="type-select">ประเภทหมวดหมู่</InputLabel>
                        <Select
                          labelId="type-select"
                          label="ประเภทหมวดหมู่"
                          value={editData.cate_type}
                          error={isError.cate_type}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                cate_type: e.target.value,
                              };
                            })
                          }
                        >
                          <MenuItem value="true">true</MenuItem>
                          <MenuItem value="dtac">dtac</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-xs-half">
                      <div className="input-group">
                        <ButtonUI
                          color="error"
                          onClick={(e) => priorityHandler(false)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </ButtonUI>
                        <span className="title">
                          {t("Priority")} {editData.priority}
                        </span>
                        <ButtonUI onClick={(e) => priorityHandler(true)}>
                          <FontAwesomeIcon icon={faAdd} />
                        </ButtonUI>
                      </div>
                    </div>
                  </div>
                </Box>

                <div className="btn-action">
                  <ButtonUI
                    // loader={true}
                    isLoading={isFetching}
                    onClick={editValidators}
                    className="btn-save"
                    on="save"
                    width="md"
                  >
                    {t("Save")}
                  </ButtonUI>
                  <ButtonUI
                    onClick={() => setOpen(false)}
                    icon={<FontAwesomeIcon icon={faRedo} />}
                    className="btn-cancel"
                    on="cancel"
                    width="md"
                  >
                    {t("Cancel")}
                  </ButtonUI>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
