import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faYinYang, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import { svPredictNumbcate } from "../../../services/productServices/berpredict.service";

import DataGridPredictNumbCate from "./datagrid/DataGridPredictNumbCate";
import PredictNumbCateAddModal from "./PredictNumbCateAddModal";
import PredictNumbCateEditModal from "./PredictNumbCateEditModal";

import "./predictnumcate.scss";

export default function PredictNumbCatePage() {
  const { t } = useTranslation("predictnumbcate-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [spawnActive, setSpawnActive] = useState(true);
  const dispatch = useDispatch();

  const [predictNumbCateData, setPredictNumbCateData] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svPredictNumbcate().then((res) => {
      if (res.status === 200) {
        const result = res.data.data;
        setPredictNumbCateData(result.predictCates);
        setFilteredData(result.predictCates);
        setMaxPriority(result.maxPriority);
      } else {
        console.log(res);
      }

      setSpawnActive(false);
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const setOpenAddHandle = () => {
    setOpenAdd(true);
  };

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  return (
    <section id="predictnumbcate-page">
      <HeadPageComponent
        h1={t("ค้นหาเบอร์จากความหมาย")}
        icon={<FontAwesomeIcon icon={faYinYang} />}
        breadcrums={[{ title: "ค้นหาเบอร์จากความหมาย", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <ButtonUI
              onClick={setOpenAddHandle}
              style={{ width: "120px" }}
              className="btn-add-predictnumbcate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มแพ็คเกจ")}
            </ButtonUI>
          </div>
        </div>

        {/* Package Table Here */}
        <DataGridPredictNumbCate
          setRefreshData={setRefreshData}
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setProductEdit={setProductEdit}
          setFilteredData={setFilteredData}
          setPredictNumbCateData={setPredictNumbCateData}
        />

        {/* packageProduct Modal Add Here */}
        {openAdd && (
          <PredictNumbCateAddModal
            maxPriority={maxPriority}
            setRefreshData={setRefreshData}
            open={openAdd}
            setOpen={setOpenAdd}
            setFilteredData={setFilteredData}
            setPredictNumbCateData={setPredictNumbCateData}
          />
        )}

        {/* package product Modal Edit Here */}
        {openEdit && (
          <PredictNumbCateEditModal
            setRefreshData={setRefreshData}
            productEdit={productEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            setFilteredData={setFilteredData}
            setPredictNumbCateData={setPredictNumbCateData}
          />
        )}
      </div>
    </section>
  );
}
