import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HeadPageComponent from "../../../../components/layout/headpage/headpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRedo, faMobile } from "@fortawesome/free-solid-svg-icons";
import ButtonUI from "../../../../components/ui/button/button";
import { svGetLuckyNetWorkData } from "../../../../services/productServices/berlucky.service";
import { appActions } from "../../../../store/app-slice";
import DataGridBerNetwork from "./DataGridBerNetwork";

function LuckyNetWork() {
  const dispatch = useDispatch();
  const { t } = useTranslation("berlucky-page");
//   const language = useSelector((state) => state.app.language);
  const [spawnActive, setSpawnActive] = useState(true);
  const [refreshData, setRefreshData] = useState(0);
  const [bernet, setBernet] = useState([]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svGetLuckyNetWorkData().then((res) => {
      if (res.status === 200) {
        console.log(res.data.bernetworks);
        setBernet(res.data.bernetworks);
      }
      else {
        setBernet([]);
      }
      setSpawnActive(false)
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData]);

  const refreshDataHandler = () => {
    setSpawnActive(true);
    setRefreshData(refreshData + 1);
  };
  return (
    <section id="luckynetwork-page">
      <HeadPageComponent
        h1={t("ตั้งค่าเครือข่าย")}
        icon={<FontAwesomeIcon icon={faMobile} />}
        breadcrums={[{ title: "ตั้งค่าเครือข่าย", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshDataHandler}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
          </div>
        </div>

        {/* Lucky network Table Here */}
        <DataGridBerNetwork 
        filteredData={bernet} 
        setRefreshData={setRefreshData} 
        />
      </div>
    </section>
  );
}

export default LuckyNetWork;
