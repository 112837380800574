import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ButtonUI from "../../../../components/ui/button/button";
import BerLuckyNetworkModaEditl from "./BerLuckyNetworkModaEditl";

function DataGridBerNetwork({ filteredData,setRefreshData }) {
  const { t } = useTranslation("berlucky-page");
  const webPath = useSelector((state) => state.app.webPath);
  const [spawnActive, setSpawnActive] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [productEdit, setProductEdit] = useState(null);
  const columns = [
    {
      //   width: 150,
      flex: 1 / 2,
      field: "network_id",
      headerName: "ID ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1,
      field: "network_name",
      headerName: "ชื่อเครือขาย",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1,
      field: "thumbnail",
      headerName: "รูปเครือข่าย",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          <figure>
            <img src={webPath + params.row.thumbnail} alt="" height={30} width={40}/>
          </figure>
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1/2,
      field: "action",
      headerName: "จัดการ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const editHandle = (row) => {
    console.log(row);
    setProductEdit(row);
    setOpenEditModal(true);
  };

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            rowHeight={75}
            getRowId={(row) => row.network_id}
            //getRowHeight={() => "auto"} // set row height auto
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>

      {/* Edit Modal Here */}
      {openEditModal && (
        <BerLuckyNetworkModaEditl
          t={t}
          webpath={webPath}
          open={openEditModal}
          setOpen={setOpenEditModal}
          productEdit={productEdit}
          setRefreshData={setRefreshData}
        />
      )}

      {/* Create Modal */}
    </Fragment>
  );
}

export default DataGridBerNetwork;
