import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import ButtonUI from "../../../components/ui/button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svDeleteBankInformation } from "../../../services/bankinfo.service";
const modalSwal = withReactContent(Swal);

function DataGridBank({
  filteredData,
  setOpenEdit,
  setBankEdit,
  setRefreshData,
}) {
  const { t } = useTranslation("movecate-page");
  const webPath = useSelector((state) => state.app.webPath);
  console.log(filteredData);

  const columns = [
    {
      flex: 1 / 2,
      field: "id",
      headerName: "id ธนาคาร",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 2,
      field: "bank_name",
      headerName: "ชื่อธนาคาร",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 2,
      field: "bank_account",
      headerName: "บัญชีธนาคาร",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 2,
      field: "bank_number",
      headerName: "เลขบัญชีธนาคาร",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 2,
      field: "bank_image",
      headerName: "รูปธนาคาร",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          <figure>
            <img
              src={webPath + params.row.bank_image}
              alt=""
              height={50}
              width={60}
            />
          </figure>
        </>
      ),
    },
    {
      flex: 1,
      field: "action",
      headerName: "จัดการ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          <div className="box-action">
            <ButtonUI
              onClick={() => editHandle(params.row)}
              on="edit"
              className="btn-custom onEdit"
              icon={<FontAwesomeIcon icon={faEdit} />}
              width={"xxs"}
              // style={btnStyle}
            >
              {t("")}
            </ButtonUI>
            <ButtonUI
              onClick={() => deleteHandle(params.row)}
              on="delete"
              className="btn-custom onDelete"
              icon={<FontAwesomeIcon icon={faTrash} />}
              width={"xxs"}
              // style={btnStyle}
            >
              {t("")}
            </ButtonUI>
          </div>
        </>
      ),
    },
  ];

  const editHandle = (row) => {
    setBankEdit(row);
    setOpenEdit(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteBankInformation(row.id).then((res) => {
            const result = res.data;
            if (res.status === 200) {
              setRefreshData((prev) => prev + 1);
              SwalUI({
                status: result.status,
                description: result.description,
              });
            } else {
              SwalUI({
                status: result.status,
                description: result.description,
              });
            }
          });
        }
      });
  };

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            rowHeight={200}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}

export default DataGridBank;
