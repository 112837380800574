import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Autocomplete, Checkbox, Modal, Switch } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ButtonUI from "../../../components/ui/button/button";
import { updateFiberProductService } from "../../../services/productServices/fiber.service";
import { appActions } from "../../../store/app-slice";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import "./fiber.scss";
// import "./slide-edit.scss";

const modalSwal = withReactContent(Swal);
const displayLabel = { inputProps: { "aria-label": "display switch" } };
const fixedIPLabel = { inputProps: { "aria-label": "display switch" } };
const editDataDefault = {
  id: null,
  imageName: "",
  token: "",
  image: "",
  imageTitle: "",
  imageAlt: "",
  title: "",
  description: "",
  display: true,
  pageId: 0,
  type: 1,
  positionId: 1,
  priority: 1,
  link: "",
  redirect: "",
  h1: "",
  h2: "",
  dateDisplay: "",
  dateHidden: "",
  language: "",
  editor: "",
  benefit_ids: "",
};

export default function FiberModalEdit(props) {
  const {
    t,
    open,
    setOpen,
    productEdit,
    benefitData,
    privilegeData,
    fiberCateData,
    fiberCate,
    setFiberProductData,
    setFiteredProduct,
    setMaxPriority,
  } = props;
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);

  const [previews, setPreviews] = useState({ src: "", file: null, name: null });
  const [isError, setIsError] = useState({
    thumbnail: false,
    title: false,
    price_per_month: false,
    duration: false,
    fiberCate: false,
    download_speed: false,
    upload_speed: false,
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    const selectedBenefitIds = productEdit.benefit_ids?.split(",");
    const selectedPrivilegeIds = productEdit.privilege_ids?.split(",");

    const bOption = benefitData.filter((b) =>
      selectedBenefitIds?.includes(b.id.toString())
    );
    const pOption = privilegeData.filter((p) =>
      selectedPrivilegeIds?.includes(p.id.toString())
    );

    setSelectedBenefits(bOption);
    setSelectedPrivileges(pOption);
  }, []);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    if (editData.title.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, title: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, title: false };
      });
    }

    if (!editData.price_per_month || editData.price_per_month === 0) {
      setIsError((prev) => {
        return { ...prev, price_per_month: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, price_per_month: false };
      });
    }

    if (!editData.duration) {
      setIsError((prev) => {
        return { ...prev, duration: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, duration: false };
      });
    }

    if (!editData.fiber_cate_id) {
      setIsError((prev) => {
        return { ...prev, fiberCate: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, fiberCate: false };
      });
    }

    if (!editData.download_speed || editData.download_speed === 0) {
      setIsError((prev) => {
        return { ...prev, download_speed: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, download_speed: false };
      });
    }

    if (!editData.upload_speed || editData.upload_speed === 0) {
      setIsError((prev) => {
        return { ...prev, upload_speed: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, upload_speed: false };
      });
    }

    if (isValid) {
      saveHandler();
    }
  };

  const saveHandler = () => {
    setIsFetching(true);
    // dispatch(appActions.isSpawnActive(true));
    updateFiberProductService(editData, language).then((res) => {
      setOpen(false);
      if (res.data.status) {
        const fiberProduct = res.data.fiberProduct;
        setFiberProductData(fiberProduct);
        if (fiberCate) {
          const product = fiberProduct?.filter(
            (p) => p.fiber_cate_id === fiberCate
          );
          setFiteredProduct(product);
        } else {
          setFiteredProduct(fiberProduct);
        }

        const priorities = fiberProduct?.map((p) => p.priority);
        const maxPriority = Math.max(...priorities);
        setMaxPriority(maxPriority);

        SwalUI({ status: res.data.status, description: res.data.description });
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }

      setIsFetching(false);
      // dispatch(appActions.isSpawnActive(false));
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="fiber-edit-modal">
          <section id="slide-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("FiberEdit")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="slide-details">
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={editData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อสินค้า"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={editData.details}
                        title={editData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียดสินค้า"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              more_details: e.target.value,
                            };
                          })
                        }
                        value={editData.more_details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-moredetails"
                        label="รายละเอียดเพิ่มเติม"
                        size="small"
                      />
                    </div>
                    <div
                      className="input-xl-half"
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              price_per_month: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.price_per_month,
                            };
                          })
                        }
                        value={editData.price_per_month}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.price_per_month}
                        id="ad-price"
                        label="ราคา/เดือน"
                        size="small"
                      />
                      <TextField
                        style={{ width: "50%" }}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              duration: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.duration,
                            };
                          })
                        }
                        value={editData.duration}
                        className="text-field-custom"
                        fullWidth={false}
                        error={isError.duration}
                        id="ad-price"
                        label="ระยะสัญญา (เดือน)"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="benefit-select">
                          หมวดหมู่ไฟเบอร์
                        </InputLabel>
                        <Select
                          labelId="benefit-select"
                          label="หมวดหมู่ไฟเบอร์"
                          value={editData.fiber_cate_id}
                          error={isError.fiberCate}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                fiber_cate_id: e.target.value,
                              };
                            })
                          }
                        >
                          {fiberCateData &&
                            fiberCateData?.map((cate) => (
                              <MenuItem key={cate.id} value={cate.id}>
                                {cate.cate_title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              download_speed: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.download_speed,
                            };
                          })
                        }
                        value={editData.download_speed}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.download_speed}
                        id="ad-download"
                        label="ความเร็วดาวน์โหลด Mbps"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              upload_speed: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.upload_speed,
                            };
                          })
                        }
                        value={editData.upload_speed}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.upload_speed}
                        id="ad-download"
                        label="ความเร็วอัพโหลด Mbps"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              special_price: !isNaN(e.target.value)
                                ? e.target.value
                                : prevState.special_price,
                            };
                          })
                        }
                        value={editData.special_price}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-specialprice"
                        label="ราคาพิเศษ"
                        size="small"
                      />
                    </div>
                    <div className="input-xl-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              special_details: e.target.value,
                            };
                          })
                        }
                        value={editData.special_details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-specialdetails"
                        label="เงื่อนไขราคาพิเศษ"
                        size="small"
                      />
                    </div>

                    <div className="input-full">
                      <Autocomplete
                        multiple
                        size="small"
                        id="checkboxes-tags-demo"
                        options={benefitData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedBenefits}
                        onChange={(evt, value) => {
                          const setIds = value
                            .map((value) => value.id)
                            .join(",");
                          setSelectedBenefits(value);
                          setEditData((prev) => {
                            return { ...prev, benefit_ids: "," + setIds + "," };
                          });
                        }}
                        renderOption={(props, option, { selected }) => {
                          // const selected = selectedBenefits.some(benefit => benefit.id === option.id)
                          return (
                            <li {...props} key={option.id}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                value={option.id}
                              />
                              {option.title}
                            </li>
                          );
                        }}
                        style={{ width: "100%", margin: 0 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="สิทธิประโยชน์"
                            placeholder=""
                            style={{
                              width: "100%",
                              marginLeft: 0,
                              marginBottom: "5px",
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="input-full">
                      <Autocomplete
                        multiple
                        size="small"
                        id="checkboxes-tags-demo"
                        options={privilegeData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedPrivileges}
                        onChange={(evt, value) => {
                          const setIds = value
                            .map((value) => value.id)
                            .join(",");
                          setSelectedPrivileges(value);
                          setEditData((prev) => {
                            return {
                              ...prev,
                              privilege_ids: "," + setIds + ",",
                            };
                          });
                        }}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props} key={option.id}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                value={option.id}
                              />
                              {option.title}
                            </li>
                          );
                        }}
                        style={{ width: "100%", margin: 0 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="สิทธิพิเศษ"
                            placeholder=""
                            style={{
                              width: "100%",
                              marginLeft: 0,
                              marginBottom: "5px",
                            }}
                          />
                        )}
                      />
                    </div>

                    <div
                      className="input-full"
                      style={{ display: "flex", gap: "1rem" }}
                    >
                      <div className="input-group">
                        <ButtonUI
                          color="error"
                          onClick={(e) => priorityHandler(false)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </ButtonUI>
                        <span className="title">
                          {t("Priority")} {editData.priority}
                        </span>
                        <ButtonUI onClick={(e) => priorityHandler(true)}>
                          <FontAwesomeIcon icon={faAdd} />
                        </ButtonUI>
                      </div>
                      {/* <div className="group">
                        <span>{t("ModalFixedIPStatus")}</span>
                        <Switch
                          {...fixedIPLabel}
                          checked={editData.fixed_ip}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                fixed_ip: e.target.checked,
                              };
                            })
                          }
                        />
                      </div> */}
                      <div className="group">
                        <span>{t("ModalDisplayStatus")}</span>
                        <Switch
                          {...displayLabel}
                          checked={editData.display}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                display: e.target.checked,
                              };
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Box>

                <div className="btn-action">
                  <ButtonUI
                    // loader={true}
                    isLoading={isFetching}
                    onClick={editValidators}
                    className="btn-save"
                    on="save"
                    width="md"
                  >
                    {t("Save")}
                  </ButtonUI>
                  <ButtonUI
                    onClick={() => setOpen(false)}
                    icon={<FontAwesomeIcon icon={faRedo} />}
                    className="btn-cancel"
                    on="cancel"
                    width="md"
                  >
                    {t("Cancel")}
                  </ButtonUI>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
