import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBoxOpen, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import { svPrepaidSims } from "../../../services/productServices/prepaid.service";

import DataGridPrepaidProduct from "./datagrid/DataGridPrepaidProduct";
import PrepaidAddModal from "./PrepaidAddModal";
import PrepaidEditModal from "./PrepaidEditModal";

import "./prepaid.scss";

export default function PrepaidPage() {
  const { t } = useTranslation("prepaidsim-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [cateId, setCateId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const [spawnActive, setSpawnActive] = useState(true);

  const [prepaidCateData, setPrepaidCateData] = useState([]);
  const [prepaidSimData, setPrepaidSimData] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svPrepaidSims().then((res) => {
      if (res.status === 200) {
        const result = res.data;
        setPrepaidCateData(result.cates);
        setPrepaidSimData(result.sims);
        setMaxPriority(result.maxPriority);

        if (cateId) {
          const product = result.sims?.filter(
            (p) => p.prepaid_cate_id === cateId
          );
          setFilteredData(product);
        } else {
          setFilteredData(result.sims);
        }
      } else {
        console.log(res);
      }

      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const handlePrepaidCateChange = (value) => {
    setCateId(value);
    if (!value || value === "") {
      setFilteredData(prepaidSimData);
    } else {
      const product = prepaidSimData?.filter(
        (p) => p.prepaid_cate_id === value
      );
      setFilteredData(product);
    }
  };

  const setOpenAddHandle = () => {
    handlePrepaidCateChange("");
    setOpenAdd(true);
  };

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  return (
    <section id="prepaidsim-page">
      <HeadPageComponent
        h1={t("ซิมเติมเงิน")}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "ซิมเติมเงิน", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="select-filter-page">
                {t("หมวดหมู่ซิมเติมเงิน")}
              </InputLabel>
              <Select
                labelId="select-filter-page"
                autoWidth
                id="filter-page-control"
                label="Page Control"
                className="input-page"
                size="small"
                onChange={(e) => handlePrepaidCateChange(e.target.value)}
                value={cateId}
              >
                <MenuItem value="">{t("None")}</MenuItem>
                {prepaidCateData &&
                  prepaidCateData?.map((cate) => (
                    <MenuItem key={cate.id} value={cate.id}>
                      {cate.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <ButtonUI
              onClick={setOpenAddHandle}
              style={{ width: "120px" }}
              className="btn-add-prepaidsim"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มสินค้า")}
            </ButtonUI>
          </div>
        </div>

        {/* Product Table Here */}
        <DataGridPrepaidProduct
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setProductEdit={setProductEdit}
          setRefreshData={setRefreshData}
        />

        {/* Product Modal Add Here */}
        {openAdd && (
          <PrepaidAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            prepaidCateData={prepaidCateData}
            setRefreshData={setRefreshData}
            maxPriority={maxPriority}
          />
        )}

        {/* Product Modal Edit Here */}
        {openEdit && (
          <PrepaidEditModal
            productEdit={productEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            prepaidCateData={prepaidCateData}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </section>
  );
}
