import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Modal, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import "animate.css";

import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import ButtonUI from "../../../../components/ui/button/button";
import { svDeletePrepaidCate } from "../../../../services/productServices/prepaid.service";
import { svDeleteBerluckyCate } from "../../../../services/productServices/berlucky.service";

const modalSwal = withReactContent(Swal);

function DataGridBerluckyCate({
  filteredData,
  setOpenEdit,
  setProductEdit,
  setRefreshData,
}) {
  const { t } = useTranslation("berluckycate-page");
  const webPath = useSelector((state) => state.app.webPath);

  const previewImg = (src) => {
    if (src) {
      modalSwal.fire({
        imageUrl: webPath + src,
        // imageWidth: 424,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        showClass: {
          popup: `
            animate__animated
            animate__bounceInDown
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__bounceOutDown
            animate__faster
          `,
        },
      });
    }
  };

  const columns = [
    {
      //   width: 150,
      flex: 1/2,
      field: "thumbnail",
      headerName: "ภาพหมวดหมู่",
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <figure>
            <Box
              className={`move-img ${param.row.thumbnail ? "hover" : ""}`}
              onClick={() => previewImg(param.row.thumbnail)}
              component="img"
              sx={{
                width: 60,
              }}
              alt="The house from the offer."
              src={param.row.thumbnail ? webPath + param.row.thumbnail : "/images/no-image.png"}
            />
          </figure>
        </>
      ),
    },
    {
      //   width: 150,
      flex: 2,
      field: "bercate_name",
      headerName: "ชื่อหมวดหมู่",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p>{param.row.bercate_name} <span style={{ color: 'red' }}>{`[${param.row.bercate_total}]`}</span> </p>
        </>
      )
    },
    {
      //   width: 150,
      flex: 1,
      field: "bercate_id",
      headerName: "รหัสหมวดหมู่",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <span style={{ color: param.row.status === 0 ? 'red' : '' }}>{`[ ${param.row.bercate_id} ] ${param.row.status === 0 ? 'manual' : ''}`}</span>
      )
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1/2,
      field: "recommended",
      headerName: "หมวดหมู่แนะนำ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.recommended ? true : false}
            onChange={(e) => updateRec(e.target.checked, param.row.bercate_id)}
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1/2,
      field: "status",
      headerName: "ระบบอัตโนมัติ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
        {[1,2,3,4].includes(param.row.bercate_id)&&(<Switch
            defaultChecked={param.row.status ? true : false}
            onChange={(e) => updateStatus(e.target.checked, param.row.bercate_id)}
            color="warning"
            disabled
          />)||( <Switch
            defaultChecked={param.row.status ? true : false}
            onChange={(e) => updateStatus(e.target.checked, param.row.bercate_id)}
            color="warning"
          />)}
         
        {/* <Switch
            defaultChecked={param.row.status ? true : false}
            onChange={(e) => updateStatus(e.target.checked, param.row.bercate_id)}
            color="warning"
          /> */}
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1/2,
      field: "bercate_pin",
      headerName: "Pin",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.bercate_pin ? true : false}
            onChange={(e) => updatePin(e.target.checked, param.row.bercate_id)}
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: 140,
      flex: 1/2,
      field: "bercate_display",
      headerName: "แสดงผล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.bercate_display ? true : false}
            onChange={(e) => updateDisplay(e.target.checked, param.row.bercate_id)}
          />
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการสินค้า",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
              { params.row.bercate_id !== 0 && ![1, 2, 3, 4].includes(params.row.bercate_id) && (
                <ButtonUI
                  onClick={() => deleteHandle(params.row)}
                  on="delete"
                  className="btn-custom onDelete"
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  width={"xxs"}
                >
                  {t("")}
                </ButtonUI>
              )}
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const updateStatus = (checked, _id) => {
    axios
      .patch(`berluckycate/updatestatus/${_id}`, { status: checked })
      .then((res) => {
        console.log(res);
      });
  };

  const updatePin = (checked, _id) => {
    axios
      .patch(`berluckycate/updatepin/${_id}`, { bercate_pin: checked })
      .then((res) => {
        console.log(res);
      });
  };

  const updateRec = (checked, _id) => {
    console.log(checked, _id);
    // axios
    //   .patch(`berluckycate/updaterec/${_id}`, { recommended: checked })
    //   .then((res) => {
    //     console.log(res);
    //   });
  };

  const updateDisplay = (checked, _id) => {
    axios.patch(`berluckycate/updatedisplay/${_id}`, { bercate_display: checked }).then(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  };

  const editHandle = (row) => {
    setProductEdit(row);
    setOpenEdit(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteBerluckyCate(row.bercate_id).then((res) => {
            if (res.status === 200) {
              setRefreshData((prev) => prev + 1);

              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            } else {
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            }
          });
        }
      });
  };
  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            getRowId={(row) => row.bercate_id}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            rowHeight={100}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}

export default DataGridBerluckyCate;
