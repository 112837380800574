import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Modal, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ButtonUI from "../../../components/ui/button/button";
import FiberModalEdit from "./FiberModalEdit";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { deleteFiberProductService } from "../../../services/productServices/fiber.service";

const modalSwal = withReactContent(Swal);

function DataGridFiber({
  filteredProduct,
  benefitData,
  privilegeData,
  fiberCateData,
  fiberCate,
  setFiteredProduct,
  setFiberProductData,
  setMaxPriority,
}) {
  const { t } = useTranslation("fiber-page");
  const webPath = useSelector((state) => state.app.webPath);
  const [showImg, setShowImg] = useState(null);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [productEdit, setProductEdit] = useState(null);

  const editHandle = (row) => {
    const preProduct = {
      ...row,
      imageTitle: row.thumbnail_title,
      imageAlt: row.thumbnail_alt,
      image: row.thumbnail_link,
      imageName: row.thumbnail_title,
      fixed_ip: row.fixed_ip?true:false,
      display: row.display?true:false,
      defaults: row.defaults?true:false,
    };
    setProductEdit(preProduct);
    setOpenEditModal(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteFiberProductService(row.id).then((res) => {
            const result = res.data;
            if (result.status) {
              const fiberProduct = result.fiberProduct;
              setFiberProductData(fiberProduct);
              if (fiberCate) {
                const product = fiberProduct?.filter(
                  (p) => p.fiber_cate_id === fiberCate
                );
                setFiteredProduct(product);
              } else {
                setFiteredProduct(fiberProduct);
              }

              const priorities = fiberProduct?.map((p) => p.priority);
              const maxPriority = Math.max(...priorities);
              setMaxPriority(maxPriority);
              SwalUI({
                status: result.status,
                description: result.description,
              });
            } else {
              SwalUI({
                status: result.status,
                description: result.description,
              });
            }
          });
        }
      });
  };

  const columns = [
    {
      //   width: 150,
      flex: 2,
      field: "title",
      headerName: "ชื่อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 2,
      field: "details",
      headerName: "รายละเอียด",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "price_per_month",
      headerName: "ราคา/เดือน",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 140,
      flex: 1,
      field: "duration",
      headerName: "ระยะสัญญา (เดือน)",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1.5,
      field: "cate_title",
      headerName: "หมวดหมู่ไฟเบอร์",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 140,
      flex: 1,
      field: "download_speed",
      headerName: "ความเร็วดาวน์โหลด Mbps",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 140,
      flex: 1,
      field: "upload_speed",
      headerName: "ความเร็วอัพโหลด Mbps",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการสินค้า",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            rowHeight={75}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredProduct}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>

      {/* Edit Modal Here */}
      {openEditModal && (
        <FiberModalEdit
          t={t}
          open={openEditModal}
          setOpen={setOpenEditModal}
          productEdit={productEdit}
          benefitData={benefitData}
          privilegeData={privilegeData}
          fiberCateData={fiberCateData}
          fiberCate={fiberCate}
          setFiteredProduct={setFiteredProduct}
          setFiberProductData={setFiberProductData}
          setMaxPriority={setMaxPriority}
        />
      )}

      {/* Create Modal */}
    </Fragment>
  );
}

export default DataGridFiber;
