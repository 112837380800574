import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Modal, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import ButtonUI from "../../../../components/ui/button/button";
import { deleteMoveCateService } from "../../../../services/productServices/move.service";

const modalSwal = withReactContent(Swal);

function DataGridMoveCate({
  filteredData,
  setOpenEdit,
  setCateEdit,
  setMaxPriority,
  setFilteredData,
  setMoveCateData,
}) {
  const { t } = useTranslation("movecate-page");

  const columns = [
    {
      //   width: 150,
      flex: 1,
      field: "title",
      headerName: "ชื่อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "priority",
      headerName: "ลำดับความสำคัญ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "pin",
      headerName: "Pin",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.pin ? true : false}
            onChange={(e) => updatePin(e.target.checked, param.row.id)}
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: 140,
      flex: 1,
      field: "display",
      headerName: "แสดงผล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.display ? true : false}
            onChange={(e) => updateDisplay(e.target.checked, param.row.id)}
          />
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการหมวดหมู่",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const updatePin = (checked, _id) => {
    axios.patch(`move/cate/updatepin/${_id}`, { pin: checked }).then((res) => {
      console.log(res);
    });
  };

  const updateDisplay = (checked, _id) => {
    axios.patch(`move/cate/updatedisplay/${_id}`, { display: checked }).then(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  };

  const editHandle = (row) => {
    setCateEdit(row);
    setOpenEdit(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteMoveCateService(row.id).then((res) => {
            console.log(res);
            if (res.status === 200) {
              const maxPriority = res.data.maxPriority;
              const moveCates = res.data.moveCates;
              setMoveCateData(moveCates);
              setFilteredData(moveCates);
              setMaxPriority(maxPriority);

              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            } else {
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            }
          });
        }
      });
  };
  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            rowHeight={75}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}

export default DataGridMoveCate;
