import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Modal, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import ButtonUI from "../../../../components/ui/button/button";
import BerluckyEditModal from "../BerluckyEditModal";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";

import { svDeleteBerlucky } from "../../../../services/productServices/berlucky.service";
import { appActions } from "../../../../store/app-slice";

const modalSwal = withReactContent(Swal);

function DataGridBerluckyProduct({
  filteredProduct,
  packageOptions,
  setRefreshData,
  setSpawnActive,
  bercateOption,
  setBerluckyProductData,
  setFiteredProduct,
  berluckyCate,
  berluckyProductData,
  bernetworksOption,
  berluckyDuplicateProductsData,
}) {
  const webPath = useSelector((state) => state.app.webPath);
  const dispatch = useDispatch();
  const { t } = useTranslation("fiber-page");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [productEdit, setProductEdit] = useState(null);

  const editHandle = (row) => {
    const editData = {
      product_id: row.product_id,
      product_comment: row.product_comment,
      product_price: row.product_price,
      product_discount: row.product_discount,
      product_network: row.product_network,
      product_package: row.product_package,
      product_phone: row.product_phone,
      product_sumber: row.product_sumber,
      default_cate: row.default_cate.join(","),
    };
    setProductEdit(editData);
    setOpenEditModal(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteBerlucky(row.product_id).then((res) => {
            if (res.status === 200) {
              const productAll = berluckyProductData?.filter(
                (p) => p.product_id !== row.product_id
              );
              setBerluckyProductData(productAll);
              dispatch(appActions.setBerluckyAll(productAll));

              setTimeout(() => {
                setRefreshData(prev => prev + 1)
              }, 1000)

              modalSwal.fire({
                position: "center",
                width: 450,
                icon: "success",
                title: "Successful",
                text: res.data.description,
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              modalSwal.fire({
                position: "center",
                width: 450,
                icon: "error",
                title: "Failed",
                text: res.data.description,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
        }
      });
  };

  const columns = [
    {
      //   width: 150,
      flex: 1,
      field: "product_id",
      headerName: "ลำดับ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1,
      field: "product_phone",
      headerName: "หมายเลข",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "product_sumber",
      headerName: "ผลรวม",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "product_price",
      headerName: "ราคา/บาท",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 140,
      flex: 1,
      field: "product_discount",
      headerName: "ส่วนลด(%)",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1 / 2,
      field: "monthly_status",
      headerName: "เบอร์รายเดือน",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.monthly_status === "yes" ? true : false}
            onChange={(e) =>
              patchUpdate(
                e.target.checked,
                param.row.product_id,
                "updatemonthlystatus",
                "monthly_status"
              )
            }
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1 / 2,
      field: "product_pin",
      headerName: "VIP",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.product_pin === "yes" ? true : false}
            onChange={(e) =>
              patchUpdate(
                e.target.checked,
                param.row.product_id,
                "updatepin",
                "product_pin"
              )
            }
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1 / 2,
      field: "sold",
      headerName: "Sold",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.product_sold === "yes" ? true : false}
            onChange={(e) =>
              patchUpdate(
                e.target.checked,
                param.row.product_id,
                "updatesold",
                "product_sold"
              )
            }
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: 140,
      flex: 1 / 2,
      field: "product_display",
      headerName: "แสดงผล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.product_display === "yes" ? true : false}
            onChange={(e) =>
              patchUpdate(
                e.target.checked,
                param.row.product_id,
                "updatedisplay",
                "product_display"
              )
            }
          />
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการสินค้า",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const patchUpdate = (checked, _id, slug = "", key = "") => {
    axios.patch(`berlucky/${slug}/${_id}`, { [key]: checked }).then(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  };

  const even =berluckyDuplicateProductsData.map(object => object.product_phone);
  const getRowClassName = (params) => {
    const duplicateProduct = berluckyDuplicateProductsData.find(
      (product) => product.product_phone === params.row.product_phone
    );
  
    // กำหนด className ตามผลลัพธ์ของการเปรียบเทียบ
    if (duplicateProduct) {
      return 'even-row'; // คืนค่า 'duplicate-row' สำหรับแถวที่มีเบอร์โทรศัพท์ซ้ำ
    } else {
      return 'table-rows'; // คืนค่า 'unique-row' สำหรับแถวที่มีเบอร์โทรศัพท์ไม่ซ้ำ
    }
  };

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            rowHeight={75}
            getRowId={(row) => row.product_id}
            getRowClassName={getRowClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredProduct}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFirstButton: true, // Correct spelling
                showLastButton: true, // Correct spelling
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>

      {/* Edit Modal Here */}
      {openEditModal && (
        <BerluckyEditModal
          t={t}
          open={openEditModal}
          setOpen={setOpenEditModal}
          productEdit={productEdit}
          packageOptions={packageOptions}
          bercateOption={bercateOption}
          bernetworksOption={bernetworksOption}
          setRefreshData={setRefreshData}
          setSpawnActive={setSpawnActive}
          setBerluckyProductData={setBerluckyProductData}
          setFiteredProduct={setFiteredProduct}
          berluckyCate={berluckyCate}
          berluckyProductData={berluckyProductData}
        />
      )}
    </Fragment>
  );
}

export default DataGridBerluckyProduct;
