import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMinus, faRedo } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import { svCreatePreNetwork } from "../../../../services/productServices/prepaid.service";

import PreviewImageUI from "../../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../../components/ui/button/button";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";

function PreNetworkAddModal({ open, setOpen, setRefreshData }) {
  const { t } = useTranslation("prepaidcate-page");
  const [previews, setPreviews] = useState({ src: "", file: null, name: null });
  const [isError, setIsError] = useState({
    networkname: false,
    thumbnail: false,
  });
  const initProductData = {
    networkname: "",
    thumbnail: "",
    display: true,
  };

  const dispatch = useDispatch();
  const [addData, setAddData] = useState(initProductData);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  const setPreviewHandler = (data) => {
    setAddData({ ...addData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      thumbnail: previews.file === null || !previews.file,
      networkname: addData.networkname.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("thumbnail_name", addData.imageName);
      }

      formData.append("networkname", addData.networkname);
      formData.append("display", addData.display ? 1 : 0);
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
     /* Display the key/value pairs*/
//   for (var pair of formData.entries()) {
//     console.log(pair[0]+ ', ' + pair[1]); 
//   }
//   return false;
    svCreatePreNetwork(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };
  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={(e) => setOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="prepaidnet-add-modal">
        <section id="prepaidcate-add-page">
          <div className="card-control">
            <div className="card-head">
              <div className="head-action">
                <h2 className="head-title">
                  <FontAwesomeIcon icon={faAdd} /> {t("เพิ่มเครือข่าย")}
                </h2>
              </div>
            </div>
            <div className="card-body" ref={scrollRef}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
              >
                <FieldsetUI
                  className={`image-settingforprenet ${
                    isError.thumbnail ? "error" : ""
                  }`}
                  label={t("Image Info")}
                >
                  <PreviewImageUI
                    className="add-image"
                    previews={previews}
                    setPreviews={setPreviewHandler}
                  />
                </FieldsetUI>
                <div className="prepaidcate-details">
                  <h3 className="prepaidcate-detail-title">
                    {t("รายละเอียด")}
                  </h3>
                  <div className="input-full">
                    <TextField
                      onChange={(e) =>
                        setAddData((prevState) => {
                          return {
                            ...prevState,
                            networkname: e.target.value,
                          };
                        })
                      }
                      value={addData.networkname}
                      className="text-field-custom"
                      fullWidth={true}
                      error={isError.networkname}
                      id="ad-network_name"
                      label="ชื่อเครือข่าย"
                      size="small"
                    />
                  </div>

                  <div className="group-settings">
                    <h3 className="post-detail-title">{t("Settings")}</h3>
                    <div className="setting-controls">
                      <div className="switch-formforprenet">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) =>
                                  setAddData({
                                    ...addData,
                                    display: e.target.checked,
                                  })
                                }
                                checked={addData.display ? true : false}
                              />
                            }
                            label={t("การแสดงผล")}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
            <div className="btn-action">
              <ButtonUI
                // loader={true}
                isLoading={isFetching}
                onClick={addValidators}
                className="btn-save"
                on="save"
                width="md"
              >
                {t("บันทึก")}
              </ButtonUI>
              <ButtonUI
                onClick={() => setOpen(false)}
                icon={<FontAwesomeIcon icon={faRedo} />}
                className="btn-cancel"
                on="cancel"
                width="md"
              >
                {t("ยกเลิก")}
              </ButtonUI>
            </div>
          </div>
        </section>
      </Box>
    </Modal>
  );
}

export default PreNetworkAddModal;
