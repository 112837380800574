import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Autocomplete, Checkbox, Modal, Switch } from "@mui/material";

import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { createMoveCateService } from "../../../services/productServices/move.service";

function TravelCateAddModal({
  open,
  setOpen,
  setFilteredData,
  setMoveCateData,
  setMaxPriority,
  maxPriority,
}) {
  const { t } = useTranslation("move-page");
  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    details: "",
    description: "",
    priority: maxPriority + 1,
  });

  const [isError, setIsError] = useState({ title: false });

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setFormData((prevState) => {
        return { ...prevState, priority: formData.priority + 1 };
      });
    } else if (formData.priority > 1) {
      setFormData((prevState) => {
        return { ...prevState, priority: formData.priority - 1 };
      });
    }
  };

  const createValidators = () => {
    let isValid = true;

    if (formData.title.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, title: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, title: false };
      });
    }

    if (isValid) {
      saveHandler();
    }
  };

  const saveHandler = () => {
    setIsFetching(true);
    createMoveCateService(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        const maxPriority = res.data.maxPriority;
        const moveCates = res.data.moveCates;
        setMoveCateData(moveCates);
        setFilteredData(moveCates);
        setMaxPriority(maxPriority);

        SwalUI({ status: res.data.status, description: res.data.description });
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="packagecate-edit-modal">
          <section id="packagecate-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} />{" "}
                    {t("เพิ่มหมวดหมู่ย้ายค่าย")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="packagecate-details">
                    <h3 className="packagecate-detail-title">
                      {t("รายละเอียด")}
                    </h3>
                    <div className="input-xs-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={formData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-xs-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return { ...prevState, details: e.target.value };
                          })
                        }
                        value={formData.details || ""}
                        className="text-field-custom"
                        fullWidth={true}
                        id="ad-details"
                        label="รายละเอียด"
                        size="small"
                      />
                    </div>
                    <div className="input-xs-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              description: e.target.value,
                            };
                          })
                        }
                        value={formData.description || ""}
                        className="text-field-custom"
                        fullWidth={true}
                        id="ad-description"
                        label="คำอธิบาย"
                        size="small"
                      />
                    </div>
                    <div className="input-xs-half">
                      <div className="input-group">
                        <ButtonUI
                          color="error"
                          onClick={(e) => priorityHandler(false)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </ButtonUI>
                        <span className="title">
                          {t("Priority")} {formData.priority}
                        </span>
                        <ButtonUI onClick={(e) => priorityHandler(true)}>
                          <FontAwesomeIcon icon={faAdd} />
                        </ButtonUI>
                      </div>
                    </div>
                  </div>
                </Box>

                <div className="btn-action">
                  <ButtonUI
                    // loader={true}
                    isLoading={isFetching}
                    onClick={createValidators}
                    className="btn-save"
                    on="save"
                    width="md"
                  >
                    {t("บันทึก")}
                  </ButtonUI>
                  <ButtonUI
                    onClick={() => setOpen(false)}
                    icon={<FontAwesomeIcon icon={faRedo} />}
                    className="btn-cancel"
                    on="cancel"
                    width="md"
                  >
                    {t("ยกเลิก")}
                  </ButtonUI>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default TravelCateAddModal;
