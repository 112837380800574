import axios from "axios";
import { useSelector } from "react-redux";

/* Move cate */
export const getMoveCateService = () => {
  return axios
    .get(`move/cate/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const createMoveCateService = (formData) => {
  return axios
    .post(`move/cate/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const deleteMoveCateService = (id) => {
  return axios
    .delete(`move/cate/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const updateMoveCateService = (formData) => {
  return axios
    .post(`move/cate/update/${formData.id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

/* Move product */
export const getMoveProductService = () => {
  return axios
    .get(`move/product/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const createMoveProductService = (formData) => {
  return axios
    .post(`move/product/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const updateMoveProductService = (id, formData) => {
  return axios
    .post(`move/product/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const deleteMoveProductService = (id) => {
  return axios
    .delete(`move/product/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};
