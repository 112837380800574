import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { createPackageProductService } from "../../../services/productServices/package.service";

import "./package.scss";
import CKeditorComponent from "../../../components/ui/ckeditor/ckeditor";
const modalSwal = withReactContent(Swal);

export default function PackageAddModal({
  open,
  setOpen,
  setFilteredData,
  packageCateData,
  setPackageProductData,
  cateId,
}) {
  const { t } = useTranslation("packagecate-page");
  const [isFetching, setIsFetching] = useState(false);
  const [ckValue, setCkValue] = useState("");

  const [isError, setIsError] = useState({
    title: false,
    price: false,
    total_price: false,
    packageCate: false,
    package_code: false,
    type: false,
    package_type: false,
    lifetime: false,
  });
  const [formData, setFormData] = useState({
    title: "",
    details: "",
    details_content: "",
    price: "",
    total_price: "",
    package_cate_id: "",
    type: "",
    lifetime: "",
    package_type: "",
    package_code: "",
    recommended: false,
    display: true,
  });

  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, details_content: ckValue };
    });
  }, [ckValue]);

  const setPriceHandle = (value) => {
    if (isNaN(value)) return false;

    const vat = ((value * 7) / 100).toFixed(2);
    setFormData((prev) => {
      return {
        ...prev,
        price: value,
        vat: vat,
        total_price: value ? parseFloat(value) + parseFloat(vat) : "",
      };
    });
  };

  const addValidators = () => {
    let isValid = true;

    if (formData.title.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, title: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, title: false };
      });
    }

    if (parseInt(formData.price) === 0 || !formData.price) {
      setIsError((prev) => {
        return { ...prev, price: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, price: false };
      });
    }

    if (!formData.package_cate_id || formData.package_cate_id === "") {
      setIsError((prev) => {
        return { ...prev, packageCate: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, packageCate: false };
      });
    }

    if (!formData.type || formData.type === "") {
      setIsError((prev) => {
        return { ...prev, type: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, type: false };
      });
    }

    if (!formData.package_type || formData.package_type === "") {
      setIsError((prev) => {
        return { ...prev, package_type: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, package_type: false };
      });
    }

    if (formData.package_code.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, package_code: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, package_code: false };
      });
    }

    if (formData.lifetime.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, lifetime: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, lifetime: false };
      });
    }

    if (isValid) {
      saveHandler();
    }
  };

  const saveHandler = () => {
    setIsFetching(true);
    createPackageProductService(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);
      if (res.status === 200) {
        const packages = res.data.packages;
        setPackageProductData(packages);
        setFilteredData(packages);

        SwalUI({ status: res.data.status, description: res.data.description });
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="packageproduct-edit-modal">
          <section id="packagecate-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("เพิ่มแพ็คเกจ")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="packagecate-details">
                    <h3 className="packagecate-detail-title">
                      {t("รายละเอียด")}
                    </h3>
                    <div className="input-sm-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={formData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อแพ็คเกจ"
                        size="small"
                      />
                    </div>
                    <div className="input-sm-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return { ...prevState, details: e.target.value };
                          })
                        }
                        value={formData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        id="ad-details"
                        label="รายละเอียด"
                        size="small"
                      />
                    </div>
                    <div className="input-sm-half">
                      <TextField
                        onChange={(e) => setPriceHandle(e.target.value)}
                        value={formData.price}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.price}
                        id="ad-price"
                        label="ราคา/บาท"
                        size="small"
                      />
                    </div>
                    <div className="input-sm-half">
                      <TextField
                        disabled
                        value={formData.total_price}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.total_price}
                        id="ad-totalPrice"
                        label="ราคา(รวมvat 7% )/บาท"
                        size="small"
                      />
                    </div>
                    <div className="input-sm-half">
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="package-cate-select">
                          หมวดหมู่แพ็คเกจ
                        </InputLabel>
                        <Select
                          labelId="package-cate-select"
                          label="หมวดหมู่แพ็คเกจ"
                          value={formData.package_cate_id}
                          error={isError.packageCate}
                          onChange={(e) =>
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                package_cate_id: e.target.value,
                              };
                            })
                          }
                        >
                          {packageCateData &&
                            packageCateData?.map((cate) => (
                              <MenuItem key={cate.id} value={cate.id}>
                                {cate.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-sm-half">
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="type-select">ประเภทแพ็คเกจ</InputLabel>
                        <Select
                          labelId="type-select"
                          label="ประเภทแพ็คเกจ"
                          value={formData.type}
                          error={isError.type}
                          onChange={(e) =>
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                type: e.target.value,
                              };
                            })
                          }
                        >
                          <MenuItem value="true">true</MenuItem>
                          <MenuItem value="dtac">dtac</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-xl-half" style={{ display: "flex", gap: ".5rem" }}>
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              lifetime: !isNaN(e.target.value) ? e.target.value : prevState.lifetime,
                            };
                          })
                        }
                        value={formData.lifetime}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.lifetime}
                        id="ad-lifetime"
                        label="ระยะเวลาใช้งาน/วัน"
                        size="small"
                      />
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="package-type-select">
                          ต่ออายุ
                        </InputLabel>
                        <Select
                          labelId="package-type-select"
                          label="ต่ออายุ"
                          value={formData.package_type}
                          error={isError.package_type}
                          onChange={(e) =>
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                package_type: e.target.value,
                              };
                            })
                          }
                        >
                          <MenuItem value="ต่ออายุอัตโนมัติ">
                            ต่ออายุอัตโนมัติ
                          </MenuItem>
                          <MenuItem value="แบบรายครั้ง">แบบรายครั้ง</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-sm-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              package_code: e.target.value,
                            };
                          })
                        }
                        value={formData.package_code}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.package_code}
                        id="ad-price"
                        label="รหัสแพ็คเกจ"
                        size="small"
                        multiline
                      />
                    </div>

                    <div className="ck-edit-packagecontent">
                      <label className="ck-edit-package">Content</label>
                      <CKeditorComponent
                        ckNameId="ck-edit-package"
                        value={ckValue}
                        onUpdate={setCkValue}
                        maximize={true}
                      />
                    </div>

                    <div className="input-xs-half">
                      <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) =>
                                  setFormData((prev) => {
                                    return {
                                      ...prev,
                                      recommended: e.target.checked,
                                    };
                                  })
                                }
                                checked={formData.recommended}
                                color="warning"
                              />
                            }
                            label="แพ็คเกจแนะนำ"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="input-xs-half">
                      <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) =>
                                  setFormData((prev) => {
                                    return {
                                      ...prev,
                                      display: e.target.checked,
                                    };
                                  })
                                }
                                checked={formData.display}
                              />
                            }
                            label="การแสดงผล"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Box>

                <div className="btn-action">
                  <ButtonUI
                    // loader={true}
                    isLoading={isFetching}
                    onClick={addValidators}
                    className="btn-save"
                    on="save"
                    width="md"
                  >
                    {t("Save")}
                  </ButtonUI>
                  <ButtonUI
                    onClick={() => setOpen(false)}
                    icon={<FontAwesomeIcon icon={faRedo} />}
                    className="btn-cancel"
                    on="cancel"
                    width="md"
                  >
                    {t("Cancel")}
                  </ButtonUI>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
