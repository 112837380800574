import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateMoment from "../../../components/ui/date-moment/date-moment";
import AnotherAddModla from "../another-modal/AnotherAddModal";
import AnotherEditModla from "../another-modal/AnotherEditmodal";
import ContentCardUI from "../../../components/ui/content-card/content-card";

import {
  faEyeSlash,
  faFolderOpen,
  faMapPin,
  faLanguage,
  faLink,
  faPenNib,
  faStopwatch,
  faStar,
  faHeart,
  faYinYang,
} from "@fortawesome/free-solid-svg-icons";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { TablePagination } from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svDeletePostByToken } from "../../../services/post.service";
import { appActions } from "../../../store/app-slice";
import { useTranslation } from "react-i18next";
const modalSwal = withReactContent(Swal);

function AnotherTab(props) {
  const {
    anotherModalAdd,
    setAnotherModalAdd,
    menuList,
    anotherTab,
    anotherData,
    isRowDisplay,
    pageControl,
    category,
    textSearch,
  } = props;
  const { t } = useTranslation("another-page");

  const isSuerperAdmin = useSelector(
    (state) => state.auth.userPermission.superAdmin
  );
  const [totalData, setTotalData] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [limited, setLimited] = useState({ begin: 0, end: rowsPerPage });
  const [filteredData, setFilteredData] = useState([]);
  const [items, setItems] = useState(null);
  const [anotherModalEdit, setAnotherModalEdit] = useState({
    isEdit: false,
    isOpen: false,
  });

  const tabLists = [
    {
      value: "0",
      title: t("ทั้งหมด"),
      icon: <FontAwesomeIcon icon={faFolderOpen} />,
    },
    {
      value: "1",
      title: t("DISPLAY"),
      icon: <FontAwesomeIcon icon={faHeart} />,
    },
    {
      value: "2",
      title: t("ปักหมุด"),
      icon: <FontAwesomeIcon icon={faStar} />,
    },
    {
      value: "3",
      title: t("ปิดการมองเห็น"),
      icon: <FontAwesomeIcon icon={faEyeSlash} />,
    },
  ];

  useEffect(() => {
    if (category.length > 0) {
      setAnotherFilterData().then((res) => {
        setTotalData(res.length);
        setFilteredData(res.slice(limited.begin, limited.end));
      });
    }
  }, [
    anotherTab,
    anotherData,
    page,
    rowsPerPage,
    pageControl,
    category,
    textSearch,
  ]);

  const setAnotherFilterData = async () => {
    const filtered = await anotherData.filter((f) => {
      /* กรอง Tab */
      if (anotherTab === "1" && f.status_display === 0) {
        return false;
      } else if (anotherTab === "2" && f.pin === 0) {
        return false;
      } else if (anotherTab === "3" && f.status_display === 1) {
        return false;
      } 
      /* กรอง Page */
      if (pageControl !== "") {
        let category = f.category.slice(1, -1).split(",");
        if (!category.includes(`${pageControl}`)) {
          return false;
        }
      }
      return f;
    });

    const maped = await filtered.map((d) => {
      let exp = d.category.slice(1, -1).split(",");
      const cateLists = exp.map((cid) => category[parseInt(cid)]);
      return { ...d, cateLists };
    });

    if (!textSearch || textSearch.trim() === "") {
      return maped;
    } else {
      const fitered = maped?.filter(
        (item) =>
          item && item.title.toLowerCase().includes(textSearch.toLowerCase())
      );
      return fitered;
    }
  };

  const handleTabChange = (event, newValue) => {
    props.setAnotherTab(newValue);
  };

  /* Pagination */
  const handleChangePage = (event, newPage) => {
    setLimited({
      begin: newPage * rowsPerPage,
      end: (newPage + 1) * rowsPerPage,
    });
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    let rowPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowPage);
    setLimited({ begin: 0, end: rowPage });
    setPage(0);
  };

  const addHandler = (item) => {
    setItems(item);
    setAnotherModalEdit({
      isEdit: false,
      isOpen: true,
    });
  };

  const editHandler = (item) => {
    setItems(item);
    setAnotherModalEdit({
      isEdit: true,
      isOpen: true,
    });
  };
  const deleteHandler = (item) => {
    let buttonIcon = item.is_maincontent === 1 ? "question" : "warning";
    let text = item.is_maincontent === 1 ? " ( Main Content )" : " Content";

    modalSwal
      .fire({
        icon: buttonIcon,
        title: "Delete" + text,
        text: `Do you want to delete "${item.title}" ?`,
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeletePostByToken(item.id, item.language).then((res) => {
            SwalUI({ status: res.status, description: res.description });
            if (res.status) {
              props.setRefreshData((prev) => prev + 1);
            }
          });
        }
      });
  };

  return (
    <Fragment>
    <Box className="another-tab-section" sx={{ width: "100%" }}>
      <TabContext value={anotherTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
          >
            {tabLists.map((tab) => (
              <Tab
                className="another-tab-header"
                value={tab.value}
                key={tab.value}
                icon={tab.icon}
                label={t(tab.title)}
              />
            ))}
          </TabList>
        </Box>
        {tabLists.map((tab) => (
          <TabPanel
            className={`another-tab-body ${
              isRowDisplay ? "asRow" : "asColumn"
            }`}
            value={tab.value}
            key={tab.value}
          >
            <div className="item-list">
              {filteredData.map((item, index) => (
                <ContentCardUI
                  key={index}
                  onAddClick={() => addHandler(item)}
                  onEditClick={() => editHandler(item)}
                  onDeleteClick={() => deleteHandler(item)}
                  mainContent={item.is_maincontent}
                  allowDelete={item.category === ",39," ? false  : true}
                  className="another-card-content"
                  data={{
                    alt: item.thumbnail_alt,
                    image: item.thumbnail_link,
                    language: item.language,
                  }}
                  isRowDisplay={isRowDisplay}
                >
                  <h3 className="title">
                    {isSuerperAdmin && (
                      <span className={"id"} title="ref id">
                        [ {item.id} ]
                      </span>
                    )}
                    {item.title}
                    {item.is_maincontent ? (
                      <span className={"id"} title="ref id">
                        {" "}
                        ( Main Content )
                      </span>
                    ) : (
                      <></>
                    )}
                  </h3>
                  <p className="desc">{item.description}</p>
                  <p className="cate">
                    <span className="fa-icon" title="category">
                      {" "}
                      <FontAwesomeIcon icon={faLink} />
                    </span>
                    <span>
                      {item.cateLists.map((c, index) =>
                        index > 0 ? ` , ${c.title}` : c.title
                      )}
                    </span>
                  </p>
                  <p className="display">
                    {item.date_begin_display !== null && (
                      <Fragment>
                        <span className="fa-icon" title="show">
                          <FontAwesomeIcon icon={faStopwatch} />
                        </span>
                        <span>
                          <DateMoment
                            format={"LLL"}
                            date={item.date_begin_display}
                          />
                        </span>
                      </Fragment>
                    )}
                    {item.date_end_display !== null && (
                      <Fragment>
                        <span className="fa-icon" title="hidden">
                          <FontAwesomeIcon icon={faClock} />
                        </span>
                        <span>
                          <DateMoment
                            format={"LLL"}
                            date={item.date_end_display}
                          />
                        </span>
                      </Fragment>
                    )}
                  </p>
                  <p className="editor">
                    {item.editorName && (
                      <>
                        <span className="fa-icon" title="editor">
                          <FontAwesomeIcon icon={faPenNib} />
                        </span>
                        <span>{item.editorName}</span>
                      </>
                    )}
                    <span className="fa-icon" title="editor">
                      <FontAwesomeIcon icon={faLanguage} />
                    </span>
                    <b>{item.language.toUpperCase()}</b>
                  </p>
                </ContentCardUI>
              ))}
            </div>
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Box>

    {anotherModalAdd && (
      <AnotherAddModla
        category={category}
        menuList={menuList}
        setRefreshData={props.setRefreshData}
        totalData={totalData}
        isOpen={anotherModalAdd}
        setClose={setAnotherModalAdd}
      />
    )}
    {anotherModalEdit.isOpen && (
      <AnotherEditModla
        items={items}
        category={category}
        menuList={menuList}
        setRefreshData={props.setRefreshData}
        totalData={totalData}
        setCategory={props.setCategory}
        isEdit={anotherModalEdit.isEdit}
        isOpen={anotherModalEdit.isOpen}
        setClose={setAnotherModalEdit}
      />
    )}
  </Fragment>
  );
}

export default AnotherTab;
