import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMinus,
  faAdd,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonUI from "../../components/ui/button/button";
import { useTranslation } from "react-i18next";
import { svOrderUpdate } from "../../services/orders.service";
import SwalUI from "../../components/ui/swal-ui/swal-ui";

export default function OrderEditModal(props) {
  const { open, setOpen, orderShow, setRefreshData } = props;

  const { t } = useTranslation("orders-page");
  const webPath = useSelector((state) => state.app.webPath);
  const [isFetching, setIsFetching] = useState(false);
  const [editData, setEditData] = useState(JSON.parse(JSON.stringify(orderShow))); // การเปลี่ยนแปลงใน editData จะไม่ส่งผลต่อ orderShow
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    console.log(orderShow)
  }, [])

  useEffect(() => {
    let total = 0;
    const items = editData.product_items;
    items.map((i) => {
      const disc = i.discount ? i.discount : 0;
      total += Math.floor((i.price * i.quantity) - ((i.price * disc) / 100));
    });
    setTotalPrice(total);
    setEditData((prev) => ({...prev, total_price_update: total}));

  }, [editData.product_items]);

  const onDeleteItem = (prod) => {
    const filtered = editData.product_items?.filter(
      (i) => i.item_id !== prod.item_id
    );
    const itemDelete = editData.order_items?.filter(i => i.id === prod.item_id).map(i => i.id) // เอาเฉพาะ ID ที่จะลบใน Database
    setEditData((prev) => ({ ...prev, product_items: filtered, itemDelete: itemDelete }));
  };

  const quantityHandler = (isAdding, prod) => {
    if (isAdding) {
      if (prod.quantity + 1 > prod.amount) return;
      const products = editData.product_items.map((item) => {
        if (item.item_id === prod.item_id) {
          item.quantity += 1;
        }
        return item;
      });
      setEditData((prev) => ({ ...prev, product_items: products }));
    } else if (prod.quantity > 1) {
      const products = editData.product_items.map((item) => {
        if (item.item_id === prod.item_id) {
          item.quantity -= 1;
        }
        return item;
      });
      setEditData((prev) => ({ ...prev, product_items: products }));
    }
  };

  const handleStatus = (e) => {
    if (e.target.checked) {
      setEditData((prev) => ({ ...prev, order_status: "success" }));
    } else {
      setEditData((prev) => ({ ...prev, order_status: "pending" }));
    }
  };

  const onCloseModal = () => {
    setOpen(false)
  }
  
  const editValidators = () => {
    setIsFetching(true);
    svOrderUpdate(editData).then((res) => {
      setOpen(false);
      setIsFetching(false);
      if (res.status === 201) {
        SwalUI({ status: res.data.status, description: res.data.description, timer: 1500 });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description, timer: 1500 });
      }
    });
  };
console.log(editData);
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="orders-modal">
          <div className="card-control">
            <div className="card-head">
              <div className="head-action">
                <Typography className="head-title">
                  <FontAwesomeIcon icon={faCircleInfo} />{" "}
                  {t("รายละเอียดคำสั่งซื้อ")}
                </Typography>
              </div>
              <div className="head-right">
                <h2 className="head-title">
                  <Typography className="head-title">
                    {t("เลขคำสั่งซื้อ ")} {orderShow.order_number}
                  </Typography>
                </h2>
              </div>
            </div>
            <div className="card-body">
              <Box
                className="order-details"
                sx={{
                  width: "100%",
                  display: "grid",
                  gap: 1,
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <Typography variant="subtitle1">
                  ชื่อ - สกุล : {orderShow.firstname + " " + orderShow.lastname}
                </Typography>
                <Typography variant="subtitle1">
                  เบอร์โทรติดต่อ : {orderShow.phone_number}
                </Typography>
                <Typography variant="subtitle1">
                  อีเมล์ : {orderShow.email}
                </Typography>
                <Typography variant="subtitle1">
                  อำเภอ/เขต : {orderShow.district}
                </Typography>
                <Typography variant="subtitle1">
                  ตำบล/แขวง : {orderShow.subdistrict}
                </Typography>
                <Typography variant="subtitle1">
                  จังหวัด : {orderShow.province}
                </Typography>
                <Typography variant="subtitle1">
                  รหัสไปรษณีย์ : {orderShow.zip_code}
                </Typography>
                <Typography variant="subtitle1">
                  รายละเอียดที่อยู่ : {orderShow.address}
                </Typography>
              </Box>
              <Box className="product-details">
                <Typography variant="subtitle1">
                  รายละเอียดสินค้า : ทั้งหมด {editData.product_items?.length}{" "}
                  รายการ
                </Typography>
                <TableContainer component={Paper} className="table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ประเภทสินค้า/ซิม</TableCell>
                        <TableCell>ชื่อสินค้า/เบอร์</TableCell>
                        <TableCell>รูปสินค้า</TableCell>
                        <TableCell>ราคา (บาท)</TableCell>
                        <TableCell>ส่วนลด (%)</TableCell>
                        <TableCell>ราคาหลังหักส่วนลด (บาท)</TableCell>
                        <TableCell sx={{ width: 150 }}>จำนวน</TableCell>
                        <TableCell sx={{ width: 100 }}>จำนวนคงเหลือ</TableCell>
                        <TableCell>แก้ไข</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editData.product_items?.map((product, ind) => (
                        <TableRow
                          key={ind}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            height: "100px",
                          }}
                        >
                          <TableCell>{product.cate_name}</TableCell>
                          <TableCell>{product.title}</TableCell>
                          <TableCell>
                            {product.thumbnail_link && (
                              <img
                                src={webPath + product.thumbnail_link}
                                alt={product.thumbnail_alt}
                                style={{ maxWidth: "70px" }}
                              />
                            )}
                          </TableCell>
                          <TableCell>{product.price}</TableCell>
                          <TableCell>{product.discount}</TableCell>
                          <TableCell>{Math.floor((product.price) - ((product.price * product.discount) / 100))}</TableCell>
                          {product.type_id === 3 || orderShow.order_status === "success" ? (
                            <TableCell>{product.quantity}</TableCell>
                          ) : (
                            <TableCell>
                              <div className="input-group-settings">
                                <div className="inp">
                                  <button
                                    onClick={() =>
                                      quantityHandler(false, product)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </button>

                                  <span
                                    className="title"
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {product.quantity}
                                  </span>
                                  <button
                                    onClick={() =>
                                      quantityHandler(true, product)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faAdd} />
                                  </button>
                                </div>
                              </div>
                            </TableCell>
                          )}
                          {product.type_id === 3 ? (
                            <TableCell sx={{ color: (product.sold === "yes" || !product.sold) && "red" }}>{(product.sold === "yes" && "สินค้าขายไปแล้ว") || (!product.sold && "ไม่มีสินค้าในฐานข้อมูล")}</TableCell>
                          ) : (
                            <TableCell>{product.amount}</TableCell>
                          )}
                          <TableCell>
                            {((editData.product_items.length > 1 && orderShow.order_status === "pending") || (product.type_id === 3 && (product.sold === "yes" || !product.sold))) && (
                              <ButtonUI
                                isLoading={false}
                                onClick={() => onDeleteItem(product)}
                                className="btn-delete"
                                on="delete"
                                width="free"
                              >
                                {""}
                              </ButtonUI>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={9} align="right">
                          ราคารวม : {totalPrice + orderShow.shipping_cost}{" บาท"}
                          (ค่าจัดส่ง {orderShow.shipping_cost} บาท)
                          <br />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="group-settings">
                  {/* <h3 className="post-detail-title">{t("ตั้งค่า")}</h3> */}
                  <div className="setting-controls">
                    <div className="switch-form">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => handleStatus(e)}
                              checked={
                                editData.order_status === "success"
                                  ? true
                                  : false
                              }
                              color="success"
                            />
                          }
                          label={
                            t("สถานะคำสั่งซื้อ") + ` : ${editData.order_status}`
                          }
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </div>
                    <div className="inp-group">
                      <TextField
                        style={{ width: "100%" }}
                        value={editData.tracking_number}
                        label="เลขติดตามพัสดุ"
                        id="outlined-size-small"
                        size="small"
                        onChange={(e) =>
                          setEditData((prev) => ({
                            ...prev,
                            tracking_number: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="inp-group">
                      <FormControl
                        sx={{ width: "100%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="carry-select">ตัวเลือกขนส่ง</InputLabel>
                        <Select
                          labelId="carry-select"
                          label="ตัวเลือกขนส่ง"
                          value={editData.order_carrier}
                          error={false}
                          onChange={(e) =>
                            setEditData((prev) => ({
                              ...prev,
                              order_carrier: e.target.value,
                            }))
                          }
                        >
                          <MenuItem value={"EMS"}>จัดส่งแบบ EMS</MenuItem>
                          <MenuItem value={"Kerry"}>จัดส่งแบบ KERRY</MenuItem>
                          <MenuItem value={"Flash "}>จัดส่งแบบ Flash</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
            <div className="btn-action">
              <ButtonUI
                isLoading={isFetching}
                onClick={editValidators}
                className="btn-save"
                on="save"
                width="md"
              >
                {t("อัพเดท")}
              </ButtonUI>
              <ButtonUI
                onClick={onCloseModal}
                icon={
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ fontSize: "48px" }}
                  />
                }
                className="btn-cancel"
                on="cancel"
                width="md"
              >
                {t("ปิด")}
              </ButtonUI>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
