import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeadPageComponent from "../../components/layout/headpage/headpage";

import "./article.scss";
import {
  faAdd,
  faImages,
  faRedo,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonUI from "../../components/ui/button/button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ContentFormatButton from "../../components/ui/toggle-format/toggle-format";
import ArticleTab from "./article-tab/ArticleTab";
import { getMenuList, getPosts } from "../../services/post.service";
import { appActions } from "../../store/app-slice";

function Article() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["article-page"]);
  const pageAvailable = useSelector(
    (state) => state.app.frontOffice.pageAvailable
  );
  const language = useSelector((state) => state.app.language);
  const [articleTab, setArticleTab] = useState("0");
  const [isRowDisplay, setIsRowDisplay] = useState(false);
  const [articleData, setArticleData] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [category, setCategory] = useState([]);
  const [pageControl, setPageControl] = useState("");
  const [refreshData, setRefreshData] = useState(0);
  const [spawnActive, setSpawnActive] = useState(true);
  const [articleModalAdd, setArticleModalAdd] = useState(false);
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    getMenuList(language).then((res) => {
      if (res.status) {
        let arr = [];
        for (let obj of res.category) {
          arr[`${obj.id}`] = {
            id: obj.id,
            title: obj.cate_title,
            language: obj.language,
            level: obj.cate_level,
            rootId: obj.cate_root_id,
            parentId: obj.cate_parent_id,
            checked: false,
          };
        }
        setCategory(arr);
        
        const result = res.menu.map(d =>  {
           
          if (d && d.id === 38) {
            return { ...d, title: "บทความ"};
        }
        return { ...d};
        })
        setMenuList(result);
        console.log(menuList);
      }
    });
  }, [language]);

  useEffect(() => {
    setArticleData([]);
    dispatch(appActions.isSpawnActive(spawnActive));
    getPosts(language)
      .then((res) => {
        if (res.status) {

          const filtered = res.data.filter(item => item.category.split(',').filter(Boolean).some(cat => ['38', '39'].includes(cat)));
          // console.log(filtered);
          setArticleData(filtered);
        }
        dispatch(appActions.isSpawnActive(false));
        setSpawnActive(false);
      })
      .catch(() => {
        dispatch(appActions.isSpawnActive(false));
        setSpawnActive(false);
      });
  }, [refreshData, language]);

  const OnChangePageControlHandler = (e) => {
    setPageControl(e.target.value);
  };

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  return (
    <section id="article-page">
      <HeadPageComponent
        h1={t("บทความ")}
        icon={<FontAwesomeIcon icon={faNewspaper} />}
        breadcrums={[{ title: t("บทความ"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <ContentFormatButton
              isRowDisplay={isRowDisplay}
              setIsRowDisplay={setIsRowDisplay}
            />
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="filter-pgae">{t("การควบคุมหน้า")}</InputLabel>
              <Select
                labelId="article-pgae"
                autoWidth
                id="filter-page"
                label={t("การควบคุมหน้า")}
                className="input-page"
                size="small"
                onChange={OnChangePageControlHandler}
                value={pageControl}
              >
                <MenuItem value="">{t("ไม่มี")}</MenuItem>
                {pageAvailable &&
                  pageAvailable.map(
                    (menu) =>
                      ![8, 9, 33].includes(menu.id) && (
                        <MenuItem key={menu.id} value={menu.id}>
                          {menu.title}
                        </MenuItem>
                      )
                  )}
              </Select>
            </FormControl>
            <div className="action-right">
              <TextField
                value={textSearch}
                label="ค้นหา"
                id="outlined-size-small"
                size="small"
                onChange={(e) => setTextSearch(e.target.value)}
              />
              <ButtonUI
                onClick={() => setArticleModalAdd(true)}
                className="btn-add-article"
                on="create"
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("เพิ่มบทความ")}
              </ButtonUI>
            </div>
          </div>
        </div>

        <ArticleTab
          articleModalAdd={articleModalAdd}
          setArticleModalAdd={setArticleModalAdd}
          setRefreshData={() => setRefreshData(refreshData + 1)}
          pageControl={pageControl}
          category={category}
          setCategory={setCategory}
          menuList={menuList}
          setMenuList={setMenuList}
          articleTab={articleTab}
          setArticleTab={setArticleTab}
          isRowDisplay={isRowDisplay}
          textSearch={textSearch}
          articleData={articleData}
        />
      </div>
    </section>
  );
}

export default Article;
