import axios from "axios";

export const getFiberService = (language) => {
  return axios
    .get(`fiber/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const createFiberProductService = (formData, language) => {
  return axios
    .post(`fiber/product/create`, {...formData, language:language})
    .then((res) => res)
    .catch((err) => err);
};

export const updateFiberProductService = (formData, language) => {
  return axios
    .put(`fiber/product/update`, {...formData, language:language})
    .then((res) => res)
    .catch((err) => err);
};

export const deleteFiberProductService = (id) => {
  return axios
    .delete(`fiber/product/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const uploadTermsPDF = (formData) => {
  return axios
    .post(`fiber/product/uploadtermspdf`, formData)
    .then((res) => res)
    .catch((err) => err);
};
