import axios from "axios";
import { useSelector } from "react-redux";

// const language = useSelector((state) => state.app.language);

/* Package Cate */
export const getPackageCateService = () => {
  return axios
    .get(`package/cate/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const createPackageCateService = (formData) => {
  return axios
    .post(`package/cate/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const updatePackageCateService = (formData) => {
  return axios
    .post(`package/cate/update/${formData.id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const deletePackageCateService = (id) => {
  return axios
    .delete(`package/cate/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

/* Package Product */
export const createPackageProductService = (formData) => {
  return axios
    .post(`package/product/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const getPackageProductService = () => {
  return axios
    .get(`package/product/index`)
    .then((res) => res)
    .catch((err) => err);
};

export const updatePackageProductService = (formData) => {
  return axios
    .put(`package/product/update/${formData.id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const deletePackageProductService = (id) => {
  return axios
    .delete(`package/product/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
};