import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBoxOpen, faRedo, faGear } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "@mui/material";

import { appActions } from "../../../store/app-slice";
import { svPrepaidCates } from "../../../services/productServices/prepaid.service";
import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import DataGridPrepaidCate from "./datagrid/DataGridPrepaidCate";
import PrepaidCateAddModal from "./PrepaidCateAddModal";
import PrepaidCateEditModal from "./PrepaidCateEditModal";

import "./prepaidcate.scss";
import { Link } from "react-router-dom";


export default function PrepaidCate() {
  const { t } = useTranslation("prepaidcate-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  
  const [prepaidCateData, setPrepaidCateData] = useState([]);
  const [prepaidNetData, setPrepaidNetData] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);

  const [spawnActive, setSpawnActive] = useState(true);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svPrepaidCates().then((res) => {
      if (res.status === 200) {
        const result = res.data;
        setPrepaidCateData(result.cates);
        setPrepaidNetData(result.prenet);
        setMaxPriority(result.maxPriority);

      } else {
        console.log(res);
      }

      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const setOpenAddHandle = () => {
    setOpenAdd(true);
  };

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  return (
    <section id="prepaidcate-page">
      <HeadPageComponent
        h1={t("หมวดหมู่ซิมเติมเงิน")}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "หมวดหมู่ซิมเติมเงิน", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <h2 className="head-title">
              
              <Link to="/PreNetwork" underline="none">
                <ButtonUI
                  on="create"
                  width="lg"
                  isLoading={false}
                  icon={<FontAwesomeIcon icon={faGear} />}
                >
                  {t("ตั้งค่าเครือข่าย")}
                </ButtonUI>
              </Link>
            </h2>
            <ButtonUI
              onClick={setOpenAddHandle}
              style={{ width: "120px" }}
              className="btn-add-prepaidcate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มหมวดหมู่")}
            </ButtonUI>
          </div>
        </div>

        {/* Cate Table Here */}
        <DataGridPrepaidCate
          filteredData={prepaidCateData}
          setOpenEdit={setOpenEdit}
          setProductEdit={setProductEdit}
          setRefreshData={setRefreshData}
        />

        {/* Product Modal Add Here */}
        {openAdd && (
          <PrepaidCateAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            // prepaidCateData={prepaidCateData}
            prepaidNetData={prepaidNetData}
            setRefreshData={setRefreshData}
            maxPriority={maxPriority}
          />
        )}

        {/*  Product Modal Edit Here */}
        {openEdit && (
          <PrepaidCateEditModal
            productEdit={productEdit}
            prepaidNetData={prepaidNetData}
            open={openEdit}
            setOpen={setOpenEdit}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </section>
  );
}
