import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Modal, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import "animate.css";

import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import ButtonUI from "../../../../components/ui/button/button";
import { svDeletePrepaidCate } from "../../../../services/productServices/prepaid.service";

const modalSwal = withReactContent(Swal);

function DataGridPrepaidCate({
  filteredData,
  setOpenEdit,
  setProductEdit,
  setRefreshData,
}) {
  const { t } = useTranslation("movecate-page");
  const webPath = useSelector((state) => state.app.webPath);
  console.log(filteredData);
  const previewImg = (src) => {
    modalSwal.fire({
      imageUrl: webPath + src,
      // imageWidth: 424,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: false,
      showClass: {
        popup: `
          animate__animated
          animate__bounceInDown
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__bounceOutDown
          animate__faster
        `,
      },
    });
  };

  const columns = [
    {
      //   width: 150,
      flex: 1,
      field: "thumbnail_link",
      headerName: "ภาพหมวดหมู่",
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <figure>
            <Box
              className={`move-img ${param.row.thumbnail_link ? "hover" : ""}`}
              onClick={() => previewImg(param.row.thumbnail_link)}
              component="img"
              sx={{
                width: 150,
              }}
              alt="The house from the offer."
              src={webPath + param.row.thumbnail_link}
            />
          </figure>
        </>
      ),
    },
    {
      //   width: 150,
      flex: 1.5,
      field: "title",
      headerName: "ชื่อหมวดหมู่",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 3,
      field: "details",
      headerName: "รายละเอียด",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          <p>{params.row.details}</p>
        </div>
      ),
    },
    {
      //   width: 150,
      flex: 1.5,
      field: "prepaid_networks_name",
      headerName: "ชื่อเครือข่าย",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1 / 2,
      field: "pin",
      headerName: "Pin",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.pin ? true : false}
            onChange={(e) => updatePin(e.target.checked, param.row.id)}
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: 140,
      flex: 1 / 2,
      field: "display",
      headerName: "แสดงผล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.display ? true : false}
            onChange={(e) => updateDisplay(e.target.checked, param.row.id)}
          />
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการสินค้า",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const updatePin = (checked, _id) => {
    axios
      .patch(`prepaidcate/updatepin/${_id}`, { pin: checked })
      .then((res) => {
        console.log(res);
      });
  };

  const updateDisplay = (checked, _id) => {
    axios.patch(`prepaidcate/updatedisplay/${_id}`, { display: checked }).then(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  };

  const editHandle = (row) => {
    setProductEdit(row);
    setOpenEdit(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeletePrepaidCate(row.id).then((res) => {
            if (res.status === 200) {
              setRefreshData((prev) => prev + 1);

              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            } else {
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            }
          });
        }
      });
  };
  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            rowHeight={200}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}

export default DataGridPrepaidCate;
