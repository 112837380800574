import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Modal, Select, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { appActions } from "../../../store/app-slice";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

import { svUpdatePredicSumber } from "../../../services/productServices/berpredict.service";

function PredictSumberEditModal({
  open,
  setOpen,
  productEdit,
  setRefreshData,
}) {
  const [isError, setIsError] = useState({
    predict_sum: false,
    predict_name: false,
    predict_description: false,
  });

  const { t } = useTranslation("predictsumber-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);

  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      predict_sum: editData.predict_sum.toString().trim().length < 1,
      predict_name: editData.predict_name.toString().trim().length < 1,
      predict_description: editData.predict_description.toString().trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();

      formData.append("predict_id", editData.predict_id);
      formData.append("predict_sum", editData.predict_sum);
      formData.append("predict_name", editData.predict_name || "");
      formData.append("predict_description", editData.predict_description);

      saveHandler(formData);
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePredicSumber(editData.predict_id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="predictsumber-edit-modal">
          <section id="predictsumber-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขผลรวมเบอร์")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="predictsumber-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              predict_sum: !isNaN(e.target.value) ? e.target.value : prevState.predict_sum,
                            };
                          })
                        }
                        inputProps={
                          { readOnly: true, }
                        }
                        value={editData.predict_sum}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.predict_sum}
                        id="ad-predict_sum"
                        label="ผลรวม"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              predict_name: e.target.value,
                            };
                          })
                        }
                        value={editData.predict_name}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.predict_name}
                        id="ad-predict_name"
                        label="ความหมาย"
                        size="small"
                        multiline

                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              predict_description: e.target.value,
                            };
                          })
                        }
                        value={editData.predict_description}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.predict_description}
                        id="ad-predict_description"
                        label="คำอธิบาย"
                        size="small"
                        multiline
                      />
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default PredictSumberEditModal;
