import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Modal, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import ButtonUI from "../../../../components/ui/button/button";
import { svDeletePredictNumbcate } from "../../../../services/productServices/berpredict.service";
import axios from "axios";

const modalSwal = withReactContent(Swal);

export default function DataGridPredictGrade({
  filteredData,
  setOpenEdit,
  setProductEdit,
  setRefreshData,
}) {
  const { t } = useTranslation("packagecate-page");

  const columns = [
    {
      //   width: 150,
      flex: 1,
      field: "grade_name",
      headerName: "เกรด",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "grade_description",
      headerName: "คำอธิบาย",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "grade_max",
      headerName: "คะแนนสูงสุด",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "grade_min",
      headerName: "คะแนนต่ำสุด",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
                // style={btnStyle}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const updateDisplay = (checked, _id) => {
    axios
      .patch(`predict/numbcate/updatedisplay/${_id}`, { numbcate_display: checked })
      .then(
        (res) => console.log(res),
        (err) => console.log(err)
      );
  };

  const editHandle = (row) => {
    setProductEdit(row);
    setOpenEdit(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeletePredictNumbcate(row.numbcate_id).then((res) => {
            if (res.status === 200) {
              setRefreshData(prev => prev + 1)
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            } else {
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            }
          });
        }
      });
  };

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            rowHeight={75}
            getRowId={(row) => row.grade_id}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}
