import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRedo, faBox } from "@fortawesome/free-solid-svg-icons";

import HeadPageComponent from "../../../../components/layout/headpage/headpage";
import ButtonUI from "../../../../components/ui/button/button";
import { appActions } from "../../../../store/app-slice";
import { svGetLuckyPackageData } from "../../../../services/productServices/berlucky.service";
import DataGridLuckyPackage from "./DataGridLuckyPackage";
import LuckyPackageModalAdd from "./LuckyPackageModalAdd";

import "./luckypackage.scss";

export default function LuckyPackage() {
  const dispatch = useDispatch();
  const { t } = useTranslation("berlucky-page");
  const language = useSelector((state) => state.app.language);
  const [luckyPackageData, setLuckyPackageData] = useState([]);
  const [filteredData, setFiteredData] = useState([]);
  const [benefitData, setBenefitData] = useState([]);
  const [privilegeData, setPrivilegeData] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [maxPriority, setMaxPriority] = useState(0);
  const [spawnActive, setSpawnActive] = useState(true);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svGetLuckyPackageData().then((res) => {
      if (res.status === 200) {
        const result = res.data.data;
        const maxPriority = result.maxPriority;
        const benefits = result.benefits;
        const packages = result.packages;

        const mapedPackage = packages.map((p) => {
          const idArr = p.benefit_ids?.split(",");
          const filteredPac = benefits?.filter((b) =>
            idArr?.includes(b.id.toString())
          );

          return {
            ...p,
            packageName: filteredPac?.map((pp) => pp.title).join(", "),
            thumbnail_link: filteredPac?.map((pp) => pp.thumbnail_link),
          };
        });

        setMaxPriority(maxPriority);
        setBenefitData(benefits);
        setPrivilegeData(result.privileges);
        setLuckyPackageData(mapedPackage);
        setFiteredData(mapedPackage);
      } else {
        setLuckyPackageData([]);
        setFiteredData([]);
      }
      setSpawnActive(false)
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const luckyPackageAddHandle = () => {
    setOpenModalAdd(true);
  };

  const refreshDataHandler = () => {
    setSpawnActive(true)
    setRefreshData(refreshData + 1)
  }

  return (
    <section id="luckypackage-page">
      <HeadPageComponent
        h1={t("แพ็คเกจเบอร์มงคล")}
        icon={<FontAwesomeIcon icon={faBox} />}
        breadcrums={[{ title: "แพ็คเกจเบอร์มงคล", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshDataHandler}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>

            <div className="action-right">
              <ButtonUI
                onClick={luckyPackageAddHandle}
                style={{ width: "120px" }}
                className="btn-add-package"
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("เพิ่มแพ็คเกจ")}
              </ButtonUI>
            </div>
          </div>
        </div>

        {/* Lucky package Table Here */}
        <DataGridLuckyPackage
          filteredData={filteredData}
          benefitData={benefitData}
          privilegeData={privilegeData}
          setFiteredData={setFiteredData}
          setLuckyPackageData={setLuckyPackageData}
          setMaxPriority={setMaxPriority}
          setRefreshData={setRefreshData}
        />

        {/* Lucky package Modal Add Here */}
        {openModalAdd && (
          <LuckyPackageModalAdd
            t={t}
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            benefitData={benefitData}
            privilegeData={privilegeData}
            maxPriority={maxPriority}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </section>
  );
}
