import { faCartPlus, faRedo, faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "../../store/app-slice";
import OrderTab from "./OrderTab";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { TextField } from "@mui/material";
import ButtonUI from "../../components/ui/button/button";
import { svOrderAll } from "../../services/orders.service";

import "./orders.scss";
import OrderAddModal from "./OrderAddModal";

const Orders = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["orders-page"]);
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [tabSelect, setTabSelect] = useState("0");
  const [searchOrder, setSearchOrder] = useState("");
  const [spawnActive, setSpawnActive] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const filterData = (orders) => {
    if (orders.length > 0) {
      if (tabSelect === "0") {
        setFilteredData(orders);
        return;
      } else if (tabSelect === "1") {
        const order = orders?.filter((o) => o.order_status === "pending");
        setFilteredData(order);
        return;
      } else if (tabSelect === "2") {
        const order = orders?.filter((o) => o.order_status === "success");
        setFilteredData(order);
      }
    } else {
      setFilteredData([]);
    }
  };

  useEffect(() => {
    filterData(orderData);
  }, [tabSelect]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svOrderAll().then((res) => {
      if (res.status === 200) {
        const data = res.data.orders;
        const orderPending = res.data.order_pending;
        dispatch(appActions.getOrderPending(orderPending));
        console.log(data);
        setOrderData(data);
        filterData(data);
      } else {
        console.error(res);
        dispatch(appActions.getOrderPending(0));
      }
      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const berluckyAddHandle = () => {
    setFilteredData(orderData);
    setTabSelect("0");
    setSearchOrder("");
    setOpenModal(true)
  };

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchData = async (e) => {
    setSearchOrder(e.target.value);
    const text = e.target.value.toLowerCase();
    if (!text || text.trim() === "") {
      setFilteredData(orderData);
    } else {
      let filtered = orderData;
      if (tabSelect === "1") {
        filtered = orderData?.filter((o) => o.order_status === "pending");
      } else if (tabSelect === "2") {
        filtered = orderData?.filter((o) => o.order_status === "success");
      }

      const order = filtered?.filter(
        (item) =>
          item &&
          (item.firstname.toLowerCase().includes(text) ||
            item.lastname.toLowerCase().includes(text) ||
            item.order_number.toLowerCase().includes(text) ||
            item.phone_number.toLowerCase().includes(text) ||
            item.email.toLowerCase().includes(text) ||
            item.address.toLowerCase().includes(text))
      );
      setFilteredData(order);
    }
  };

  return (
    <section id="orders-page">
      <HeadPageComponent
        h1={"บันทึกการสั่งซื้อ"}
        icon={<FontAwesomeIcon icon={faCartPlus} />}
        breadcrums={[{ title: "บันทึกการสั่งซื้อ", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <ButtonUI
              onClick={refreshHandle}
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faRedo} />}
            >
              {t("ดึงข้อมูล")}
            </ButtonUI>
            <div className="action-right">
              <TextField
                value={searchOrder}
                label="ค้นหาคำสั่งซื้อ"
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchData(e)}
                // InputProps={{ style: { height: '36px' } }}
              />
              <ButtonUI
                onClick={berluckyAddHandle}
                style={{ width: "120px" }}
                className="btn-add-berlucky"
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("เพิ่มรายการ")}
              </ButtonUI>
            </div>
          </div>
        </div>

        <OrderTab
          filteredData={filteredData}
          filterData={filterData}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          tabSelect={tabSelect}
          setTabSelect={setTabSelect}
          setFilteredData={setFilteredData}
        />

        {openModal && (
          <OrderAddModal
            open={openModal}
            setOpen={setOpenModal}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </section>
  );
};

export default Orders;
