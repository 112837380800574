import { DataGrid } from "@mui/x-data-grid";
import { Checkbox, Modal, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import DateMoment from "../../../components/ui/date-moment/date-moment";
import ButtonUI from "../../../components/ui/button/button";
import { svDeleteOrder } from "../../../services/orders.service";

const modalSwal = withReactContent(Swal);

export default function DataGridShipping({
  filteredData,
  setRefreshData,
}) {
  const { t } = useTranslation("inbox-page");
  const columns = [
    {
      flex: 1,
      field: "order_number",
      headerName: "เลขคำสั่งซื้อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "firstname",
      headerName: "ชื่อ - สกุล ผู้ซื้อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <p>{param.row.firstname + " " + param.row.lastname}</p>
      ),
    },
    {
      flex: 1,
      field: "phone_number",
      headerName: "เบอร์โทรผู้สั่งซื้อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "total_price",
      headerName: "ราคารวม (บาท)",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "created_at",
      headerName: "วันเวลาที่ส่ง",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p>{<DateMoment format={"LLL"} date={param.row.updated_at} />}</p>
        </>
      ),
    },
    {
      flex: 1,
      field: "order_carrier",
      headerName: "ขนส่ง",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "tracking_number",
      headerName: "เลขติดตามพัสดุ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    // {
    //   //   width: 90,
    //   flex: 1,
    //   field: "action",
    //   headerName: "จัดการ",
    //   headerAlign: "left",
    //   align: "left",
    //   headerClassName: "table-columns",
    //   renderCell: (params) => (
    //     <>
    //       {"th" === "th" ? (
    //         <div className="box-action">
    //           <ButtonUI
    //             onClick={() => deleteHandle(params.row)}
    //             on="delete"
    //             className="btn-custom onDelete"
    //             icon={<FontAwesomeIcon icon={faTrash} />}
    //             width={"xxs"}
    //           >
    //             {t("")}
    //           </ButtonUI>
    //         </div>
    //       ) : (
    //         <ButtonUI
    //           onClick={() => console.log("on add anothor language")}
    //           on="add"
    //           className="btn-custom onAdd"
    //           icon={<FontAwesomeIcon icon={faPlus} />}
    //         >
    //           {t("Add")}
    //         </ButtonUI>
    //       )}
    //     </>
    //   ),
    // },
  ];

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteOrder(row.id).then((res) => {
            if (res.status === 200) {
              modalSwal.fire({
                  position: "center",
                  width: 450,
                  icon: "success",
                  title: "Successful",
                  text: res.data.description,
                  showConfirmButton: false,
                  timer: 1500,
                })
                .then(() => {
                  setRefreshData((prev) => prev + 1);
                });
            } else {
              modalSwal.fire({
                position: "center",
                width: 450,
                icon: "error",
                title: "Failed",
                text: res.data.description,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
        }
      });
  };

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            // getRowHeight={() => 'auto'} // set row height auto
            rowHeight={75}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}
