import {
  faBorderAll,
  faClock,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataGridOrders from "./datagrid/DataGridOrders";
import OrderEditModal from "./OrderEditModal";

const OrderTab = ({
  filteredData,
  filterData,
  tabSelect,
  setRefreshData,
  setTabSelect,
  setFilteredData,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [orderShow, setOrderShow] = useState(null);

  const tabLists = [
    {
      value: "0",
      title: "ทั้งหมด",
      icon: <FontAwesomeIcon icon={faBorderAll} />,
    },
    {
      value: "1",
      title: "รอดำเนินการ",
      icon: <FontAwesomeIcon icon={faClock} />,
    },
    {
      value: "2",
      title: "จัดส่งสำเร็จ",
      icon: <FontAwesomeIcon icon={faCircleCheck} />,
    },
  ];

  const handleChange = (event, newValue) => {
    setTabSelect(newValue);
  };
// console.log(filteredData);
  return (
    <Fragment>
      <Box className="orders-tab-section" sx={{ width: "100%" }}>
        <TabContext value={tabSelect}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              className={`tab-header`}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              {tabLists.map((tab) => (
                <Tab
                  className="orders-tab-head-field"
                  value={tab.value}
                  key={tab.value}
                  icon={tab.icon}
                  iconPosition="start"
                  label={t(tab.title)}
                />
              ))}
            </TabList>
          </Box>
          <DataGridOrders
            setFilteredData={setFilteredData}
            filterData={filterData}
            filteredData={filteredData}
            setOpenModal={setOpenModal}
            setOrderShow={setOrderShow}
            setRefreshData={setRefreshData}
          />
        </TabContext>
      </Box>
      {openModal && (
        <OrderEditModal
          open={openModal}
          setOpen={setOpenModal}
          orderShow={orderShow}
          setRefreshData={setRefreshData}
        />
      )}
    </Fragment>
  );
};

export default OrderTab;
