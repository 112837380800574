import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBoxOpen, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import DataGridMoveCate from "./datagrid/DataGridMoveCate";
import MoveCateAddModal from "./MoveCateAddModal";
import MoveCateEditModal from "./MoveCateEditModal";

import "./movecate.scss";

/* API Services */
import { getMoveCateService } from "../../../services/productServices/move.service";

function MoveCate() {
  const { t } = useTranslation("move-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [cateEdit, setCateEdit] = useState([]);
  const dispatch = useDispatch();

  const [moveCateData, setMoveCateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(true));
    getMoveCateService().then((res) => {
      if (res.status === 200) {
        const maxPriority = res.data.maxPriority;
        const result = res.data.moveCates;
        setMoveCateData(result);
        setFilteredData(result);
        setMaxPriority(maxPriority);
      } else {
        console.log("error");
      }
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  return (
    <section id="move-page">
      <HeadPageComponent
        h1={t("หมวดหมู่ย้ายค่าย")}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "หมวดหมู่ย้ายค่าย", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={() => setRefreshData(refreshData + 1)}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <ButtonUI
              onClick={() => setOpenAdd(true)}
              style={{ width: "120px" }}
              className="btn-add-movecate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มหมวดหมู่")}
            </ButtonUI>
          </div>
        </div>

        {/* Cate Table Here */}
        <DataGridMoveCate
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setCateEdit={setCateEdit}
          setFilteredData={setFilteredData}
          setMoveCateData={setMoveCateData}
          setMaxPriority={setMaxPriority}
        />

        {/* Movecate Modal Add Here */}
        {openAdd && (
          <MoveCateAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            setFilteredData={setFilteredData}
            setMoveCateData={setMoveCateData}
            setMaxPriority={setMaxPriority}
            maxPriority={maxPriority}
          />
        )}

        {/* Movecate Modal Edit Here */}
        {openEdit && (
          <MoveCateEditModal
            cateEdit={cateEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            setFilteredData={setFilteredData}
            setMoveCateData={setMoveCateData}
            setMaxPriority={setMaxPriority}
          />
        )}
      </div>
    </section>
  );
}

export default MoveCate;
