import { faTruckFast, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "../../store/app-slice";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { TextField } from "@mui/material";
import ButtonUI from "../../components/ui/button/button";
import { svShippingData } from "../../services/orders.service";

import "./shipping.scss";
import DataGridShipping from "./datagrid/DataGridShipping";

const ShippingPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["orders-page"]);
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [searchShipping, setSearchShipping] = useState("");
  const [spawnActive, setSpawnActive] = useState(true);
  const [shippingData, setShippingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svShippingData().then((res) => {
      if (res.status === 200) {
        const data = res.data.shippingData;
        const orderPending = res.data.order_pending;
        dispatch(appActions.getOrderPending(orderPending));
        setShippingData(data);
        setFilteredData(data);
      } else {
        console.error(res);
        dispatch(appActions.getOrderPending(0));
      }
      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchData = async (e) => {
    setSearchShipping(e.target.value);
    const text = e.target.value.toLowerCase();
    if (!text || text.trim() === "") {
      setFilteredData(shippingData)
    } else {
      let filtered = shippingData;

      const order = filtered?.filter(
        (item) =>
          item &&
          (item.firstname.toLowerCase().includes(text) ||
            item.lastname.toLowerCase().includes(text) ||
            item.order_number.toLowerCase().includes(text) ||
            item.phone_number.toLowerCase().includes(text) ||
            item.order_carrier?.toLowerCase().includes(text) ||
            item.tracking_number?.toLowerCase().includes(text))
      );
      setFilteredData(order);
    }
  };

  return (
    <section id="shipping-page">
      <HeadPageComponent
        h1={"บันทึกการจัดส่ง"}
        icon={<FontAwesomeIcon icon={faTruckFast} />}
        breadcrums={[{ title: "บันทึกการจัดส่ง", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <ButtonUI
              onClick={refreshHandle}
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faRedo} />}
            >
              {t("ดึงข้อมูล")}
            </ButtonUI>
            <div className="action-right">
              <TextField
                value={searchShipping}
                label="ค้นหา"
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchData(e)}
              />
            </div>
          </div>
        </div>

        <DataGridShipping
          shippingData={shippingData}
          filteredData={filteredData}
          setRefreshData={setRefreshData}
        />
      </div>
    </section>
  );
};

export default ShippingPage;
