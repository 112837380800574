import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

import ButtonUI from "../../../components/ui/button/button";
import { createPackageCateService } from "../../../services/productServices/package.service";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

import "./package.scss";
const modalSwal = withReactContent(Swal);

export default function PackageCateAddModal({
  open,
  setOpen,
  setFilteredData,
  setPackageCateData,
  setMaxPriority,
  maxPriority,
}) {
  const { t } = useTranslation("packagecate-page");
  const [isFetching, setIsFetching] = useState(false);

  const [isError, setIsError] = useState({ title: false });
  const [formData, setFormData] = useState({
    title: "",
    cate_type: "",
    priority: maxPriority + 1,
    pin: false,
    display: true,
  });

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setFormData((prevState) => {
        return { ...prevState, priority: formData.priority + 1 };
      });
    } else if (formData.priority > 1) {
      setFormData((prevState) => {
        return { ...prevState, priority: formData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    if (formData.title.trim().length < 1) {
      setIsError((prev) => {
        return { ...prev, title: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, title: false };
      });
    }

    if (!formData.cate_type || formData.cate_type === "") {
      setIsError((prev) => {
        return { ...prev, cate_type: true };
      });
      isValid = false;
    } else {
      setIsError((prev) => {
        return { ...prev, cate_type: false };
      });
    }

    if (isValid) {
      saveHandler();
    }
  };

  const saveHandler = () => {
    setIsFetching(true);
    createPackageCateService(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        const maxPriority = res.data.maxPriority;
        const packageCates = res.data.packageCates?.map((pac) => {
          return {
            ...pac,
            pin: pac.pin ? true : false,
            display: pac.display ? true : false,
          };
        });
        setPackageCateData(packageCates);
        setFilteredData(packageCates);
        setMaxPriority(maxPriority);

        SwalUI({ status: res.data.status, description: res.data.description });
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="packagecate-edit-modal">
          <section id="packagecate-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("เพิ่มหมวดหมู่แพ็คเกจ")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="packagecate-details">
                    <h3 className="packagecate-detail-title">
                      {t("รายละเอียด")}
                    </h3>
                    <div className="input-xs-half">
                      <TextField
                        onChange={(e) =>
                          setFormData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={formData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-xs-half">
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="type-select">ประเภทหมวดหมู่</InputLabel>
                        <Select
                          labelId="type-select"
                          label="ประเภทหมวดหมู่"
                          value={formData.cate_type}
                          error={isError.cate_type}
                          onChange={(e) =>
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                cate_type: e.target.value,
                              };
                            })
                          }
                        >
                          <MenuItem value="true">true</MenuItem>
                          <MenuItem value="dtac">dtac</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="input-xs-half">
                      <div className="input-group">
                        <ButtonUI
                          color="error"
                          onClick={(e) => priorityHandler(false)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </ButtonUI>
                        <span className="title">
                          {t("Priority")} {formData.priority}
                        </span>
                        <ButtonUI onClick={(e) => priorityHandler(true)}>
                          <FontAwesomeIcon icon={faAdd} />
                        </ButtonUI>
                      </div>
                    </div>
                    <div className="input-xs-half">
                      <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) =>
                                  setFormData((prev) => {
                                    return { ...prev, pin: e.target.checked };
                                  })
                                }
                                checked={formData.pin}
                                color="warning"
                              />
                            }
                            label="Pin"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="input-xs-half">
                      <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) =>
                                  setFormData((prev) => {
                                    return {
                                      ...prev,
                                      display: e.target.checked,
                                    };
                                  })
                                }
                                checked={formData.display}
                              />
                            }
                            label="การแสดงผล"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Box>

                <div className="btn-action">
                  <ButtonUI
                    // loader={true}
                    isLoading={isFetching}
                    onClick={editValidators}
                    className="btn-save"
                    on="save"
                    width="md"
                  >
                    {t("Save")}
                  </ButtonUI>
                  <ButtonUI
                    onClick={() => setOpen(false)}
                    icon={<FontAwesomeIcon icon={faRedo} />}
                    className="btn-cancel"
                    on="cancel"
                    width="md"
                  >
                    {t("Cancel")}
                  </ButtonUI>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
