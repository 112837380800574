import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControlLabel, FormGroup, Modal, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { appActions } from "../../../store/app-slice";
import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

import { svUpdatePredictNumbCate } from "../../../services/productServices/berpredict.service";

function PredictNumbCateEditModal({
  open,
  setOpen,
  productEdit,
  setRefreshData,
}) {
  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };
  const [isError, setIsError] = useState({
    thumbnail: false,
    numbcate_title: false,
    numbcate_name: false,
  });

  const { t } = useTranslation("predictnumbcate-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  const [previews, setPreviews] = useState(thumbnailDefault);
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = uploadPath + productEdit.thumbnail;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });
  }, []);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, numbcate_priority: editData.numbcate_priority + 1 };
      });
    } else if (editData.numbcate_priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, numbcate_priority: editData.numbcate_priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      // numbcate_title: editData.numbcate_title.trim().length < 1,
      numbcate_name: editData.numbcate_name.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
      }

      formData.append("numbcate_id", editData.numbcate_id);
      formData.append("thumbnail_link", editData.thumbnail || "");
      formData.append("numbcate_title", editData.numbcate_title || "");
      formData.append("numbcate_name", editData.numbcate_name);
      formData.append("numbcate_want", editData.numbcate_want || "");
      formData.append("numbcate_unwant", editData.numbcate_unwant || "");
      formData.append("numbcate_priority", editData.numbcate_priority);

      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePredictNumbCate(editData.numbcate_id, formData).then((res) => {
      console.log(res)
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="predictnumbcate-edit-modal">
          <section id="predictnumbcate-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขหมวดหมู่")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="edit-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                  </FieldsetUI>

                  <div className="predictnumbcate-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_title: e.target.value,
                            };
                          })
                        }
                        value={editData.numbcate_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.numbcate_title}
                        id="ad-numbcate_title"
                        label="ชื่อย่อ"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_name: e.target.value,
                            };
                          })
                        }
                        value={editData.numbcate_name}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.numbcate_name}
                        id="ad-numbcate_name"
                        label="ชื่อหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_want: e.target.value,
                            };
                          })
                        }
                        value={editData.numbcate_want}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-numbcate_want"
                        label="เลขที่ต้องการ"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              numbcate_unwant: e.target.value,
                            };
                          })
                        }
                        value={editData.numbcate_unwant}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-numbcate_unwant"
                        label="เลขที่ไม่ต้องการ"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {editData.numbcate_priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default PredictNumbCateEditModal;
