import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBoxOpen, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "@mui/material";

import HeadPageComponent from "../../../components/layout/headpage/headpage";
import ButtonUI from "../../../components/ui/button/button";
import { appActions } from "../../../store/app-slice";
import { svTravelSim } from "../../../services/productServices/travel.service";

import DataGridTravelProduct from "./datagrid/DataGridTravelProduct";
import TravelAddModal from "./TravelAddModal";
import TravelEditModal from "./TravelEditModal";

import "./travel.scss"

function TravelPage() {
  const { t } = useTranslation("travel-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [cateId, setCateId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const [benefitData, setBenefitData] = useState([]);
  const [spawnActive, setSpawnActive ] = useState(true) 

  const [travelCateData, setTravelCateData] = useState([]);
  const [travelSimData, setTravelSimData] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svTravelSim().then((res) => {
      if (res.status === 200) {
        const result = res.data.data;
        setTravelCateData(result.travelCates);
        setTravelSimData(result.travelsims);
        setBenefitData(result.benefits);
        setMaxPriority(result.maxPriority);

        if (cateId) {
          const product = result.travelsims?.filter(
            (p) => p.travel_cate_id === cateId
          );
          setFilteredData(product);
        } else {
          setFilteredData(result.travelsims);
        }
      } else {
        console.log(res);
      }

      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false)
    });
  }, [refreshData, language]);

  const handleTravelCateChange = (value) => {
    setCateId(value);
    if (!value || value === "") {
      setFilteredData(travelSimData);
    } else {
      const product = travelSimData?.filter((p) => p.travel_cate_id === value);
      setFilteredData(product);
    }
  };

  const setOpenAddHandle = () => {
    handleTravelCateChange("");
    setOpenAdd(true);
  };

  const refreshHandle = () => {
    setSpawnActive(true)
    setRefreshData(prev => prev + 1)
  }
 
  return (
    <section id="travelsim-page">
      <HeadPageComponent
        h1={t("ซิมท่องเที่ยว")}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        breadcrums={[{ title: "ซิมท่องเที่ยว", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="select-filter-page">
                {t("หมวดหมู่ซิมท่องเที่ยว")}
              </InputLabel>
              <Select
                labelId="select-filter-page"
                autoWidth
                id="filter-page-control"
                label="Page Control"
                className="input-page"
                size="small"
                onChange={(e) => handleTravelCateChange(e.target.value)}
                value={cateId}
              >
                <MenuItem value="">{t("None")}</MenuItem>
                {travelCateData &&
                  travelCateData?.map((cate) => (
                    <MenuItem key={cate.id} value={cate.id}>
                      {cate.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <ButtonUI
              onClick={setOpenAddHandle}
              style={{ width: "120px" }}
              className="btn-add-travelcate"
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t("เพิ่มสินค้า")}
            </ButtonUI>
          </div>
        </div>

        {/* Travel Table Here */}
        <DataGridTravelProduct
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setProductEdit={setProductEdit}
          setRefreshData={setRefreshData}
        />

        {/* Travel Product Modal Add Here */}
        {openAdd && (
          <TravelAddModal
            open={openAdd}
            setOpen={setOpenAdd}
            travelCateData={travelCateData}
            benefitData={benefitData}
            setRefreshData={setRefreshData}
            maxPriority={maxPriority}
          />
        )}

        {/* Travel product Modal Edit Here */}
        {openEdit && (
          <TravelEditModal
            productEdit={productEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            travelCateData={travelCateData}
            benefitData={benefitData}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </section>
  );
}

export default TravelPage;
