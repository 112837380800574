import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../store/app-slice";
import { authActions } from "../../../store/auth-slice";
import { useTranslation } from "react-i18next";
import {
  NotificationsActive,
  Mail as MailIcon,
  Inbox as InboxIcon,
} from "@mui/icons-material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./sidebar.scss";
import {
  faCaretDown,
  faListOl,
  faSignsPost,
  faSitemap,
  faNewspaper,
  faGamepad,
  faBoxOpen,
  faImages,
  faTools,
  faLanguage,
  faUserShield,
  faCircleInfo,
  faStreetView,
  faInbox,
  faComments,
  faFileCsv,
  faHome,
  faIcons,
  faEnvelope,
  faBook,
  faYinYang,
  faCircleDot,
  faTruckFast,
  faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";
import { Badge, Button } from "@mui/material";
import NavLink from "./navlink";
import { Link } from "react-router-dom";

const SidebarComponent = (props) => {
  const { t, i18n } = useTranslation("sidebar");
  const dispatch = useDispatch();
  const uPermission = useSelector((state) => state.auth.userPermission);
  const selectedLanguage = useSelector((state) => state.app.language);
  const webPath = useSelector((state) => state.app.webPath);
  const activateLanguage = useSelector((state) => state.auth.activateLanguage);
  const pagesAllow = useSelector((state) => state.app.pages);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const isDevMode = useSelector((state) => state.app.isDevMode);
  const mailInbox = useSelector((state) => state.app.mailInbox);
  const contactMs = useSelector((state) => state.app.contactMs);
  const orderPending = useSelector((state) => state.app.orderPending);

  const languageSelectHandler = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(appActions.changeLanguage(lang));
  };

  const toggleSubMenu = (event) => {
    const subMenu = event.target.closest(".has-child");
    subMenu.classList.toggle("opened");
  };

  const closeSidebarhandler = (e) => {
    /* ย่อแถบทำงานเฉพาะ width < 768 */
    let elRoot = document.querySelector("#root");
    if (elRoot && elRoot.offsetWidth <= 900) {
      props.collapseHandler(true);
    }
  };

  const openManual = () => {
    const version = Math.floor(Date.now() / 1000);
    const url = `${uploadPath}upload/manual/manual.pdf?v=${version}`;
    window.open(url, '_blank')
  }

  return (
    <aside className="aside">
      <nav>
        <Link className="sidenav-header" to="/">
          <figure className="figure-image">
            <img
              src="/images/logo/fa-iconTrue.png"
              className="website-logo"
            />
          </figure>
          <div className="website-powerby">
            <p>Simnet Unlimited</p>
            <p className="sub-website">{t("Managements")}</p>
          </div>
        </Link>

        <hr className="line-section" />
        <div className="title-section">{t("Languages")}</div>
        <div className="language-selection">
          {activateLanguage.map((lang) => (
            <Button
              variant="outlined"
              key={lang}
              onClick={(e) => languageSelectHandler(lang)}
              className={`btn-lang ${lang.toLowerCase() === selectedLanguage.toLowerCase()
                  ? "selected"
                  : ""
                }`}
            >
              {lang}
            </Button>
          ))}
        </div>

        <hr className="line-section gap " />
        <div className="sidenav-main">
          {pagesAllow.groups.notify && (
            <Fragment>
              <div className="title-section">{t("NotificationTitle")}</div>
              <ul className="nav-menu">
                {pagesAllow.dashboard && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/dashboard"
                      className="navlink"
                      title={t("dashboardPage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faGamepad} />
                      </figure>
                      <div className="menu-title">{t("dashboardPage")}</div>
                    </NavLink>
                  </li>
                )}
                {pagesAllow.messages && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/messages"
                      className={`navlink `}
                      title={t("MessagesPage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faComments} />
                      </figure>
                      <div className="menu-title">{t("MessagesPage")}</div>
                    </NavLink>
                  </li>
                )}
                {pagesAllow.orders && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/orders"
                      className={`navlink `}
                      title={t("OrderPage")}
                      liClass="menu-link"
                    >
                      <Badge color="secondary" badgeContent={orderPending}>
                        <NotificationsActive fontSize="small" />
                      </Badge>
                      <div className="menu-title">{t("OrderPage")}</div>
                    </NavLink>
                  </li>
                )}
                {pagesAllow.inbox && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/inbox"
                      className={`navlink `}
                      title={t("แจ้งรับบริการ")}
                      liClass="menu-link"
                    >
                      <Badge color="secondary" badgeContent={mailInbox}>
                        <InboxIcon fontSize="small" />
                      </Badge>
                      <div className="menu-title">{t("แจ้งรับบริการ")}</div>
                    </NavLink>
                  </li>
                )}
                {pagesAllow.contactMessage && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/contactmessage"
                      className={`navlink `}
                      title={t("ข้อความติดต่อ")}
                      liClass="menu-link"
                    >
                      <Badge color="secondary" badgeContent={contactMs}>
                        <MailIcon fontSize="small" />
                      </Badge>
                      <div className="menu-title">{t("ข้อความติดต่อ")}</div>
                    </NavLink>
                  </li>
                )}
                {pagesAllow.shipping && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/shipping"
                      className={`navlink `}
                      title={t("ShippingPage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faTruckFast} />
                      </figure>
                      <div className="menu-title">{t("ShippingPage")}</div>
                    </NavLink>
                  </li>
                )}
                {pagesAllow.subscribe && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/subscribe"
                      className={`navlink `}
                      title={t("SubscribePage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </figure>
                      <div className="menu-title">{t("SubscribePage")}</div>
                    </NavLink>
                  </li>
                )}
              </ul>
            </Fragment>
          )}

          {/* จัดการสินค้า */}
          {pagesAllow.groups.products && (
            <Fragment>
              <hr className="line-section gap" />
              <div className="title-section">{t("ManageProducts")}</div>
              <ul className="nav-menu">
                {/* เบอร์มงคล */}
                <li className={`menu-link has-child`}>
                  {/* opened */}
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title={t("BerLucky")}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <span className="collap-title">
                      <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <div className="menu-title">{t("BerLucky")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {pagesAllow.products.berluckycate && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/luckypackage"
                          className={`items `}
                          title={t("แพ็คเกจเบอร์มงคล")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">
                            {t("แพ็คเกจ")}
                          </span>
                        </NavLink>
                      )}
                      {pagesAllow.products.berluckycate && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/berluckycate"
                          className={`items `}
                          title={t("หมวดหมู่เบอร์มงคล")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">
                            {t("BerLuckyCate")}
                          </span>
                        </NavLink>
                      )}
                      {pagesAllow.products.berlucky && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/berlucky"
                          className={`items `}
                          title={t("เบอร์มงคล")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">
                            {t("BerLuckyProduct")}
                          </span>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </li>

                {/* Fiber */}
                <li className={`menu-link has-child`}>
                  {/* opened */}
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title={t("InternetFiber")}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <span className="collap-title">
                      <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <div className="menu-title">{t("InternetFiber")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {/* {true && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/product/fibercate"
                          className={`items `}
                          title={t("ManageWebContent")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">{t("InternetFiberCate")}</span>
                        </NavLink>
                      )} */}
                      {pagesAllow.products.fiber && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/fiberproduct"
                          className={`items `}
                          title={t("สินค้าไฟเบอร์")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">
                            {t("InternetFiberProduct")}
                          </span>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </li>

                {/* ซิมเติมเงิน/แพ็คเกจเสริม */}
                <li className={`menu-link has-child`}>
                  {/* opened */}
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title={t("Prepaid&Package")}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <span className="collap-title">
                      <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <div className="menu-title">{t("Prepaid&Package")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {true && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/prepaidcate"
                          className={`items `}
                          title={t("หมวดหมู่เติมเงิน")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">
                            {t("หมวดหมู่เติมเงิน")}
                          </span>
                        </NavLink>
                      )}
                      {true && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/prepaid"
                          className={`items `}
                          title={t("ซิมเติมเงิน")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">{t("ซิมเติมเงิน")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.products.packagecate && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/packagecate"
                          className={`items `}
                          title={t("PackageCate")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">{t("PackageCate")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.products.package && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/package"
                          className={`items `}
                          title={t("แพ็คเกจเสริม")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">
                            {t("แพ็คเกจเสริม")}
                          </span>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </li>

                {/* ย้ายค่าย */}
                <li className={`menu-link has-child`}>
                  {/* opened */}
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title={t("Move")}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <span className="collap-title">
                      <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <div className="menu-title">{t("Move")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {pagesAllow.products.movecate && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/movecate"
                          className={`items `}
                          title={t("MoveCate")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">{t("MoveCate")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.products.move && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/moveproduct"
                          className={`items `}
                          title={t("MoveProduct")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">{t("MoveProduct")}</span>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </li>

                {/* ซิมท่องเที่ยว */}
                <li className={`menu-link has-child`}>
                  {/* opened */}
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title={t("Travel")}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <span className="collap-title">
                      <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <div className="menu-title">{t("Travel")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {pagesAllow.products.travelcate && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/travelcate"
                          className={`items `}
                          title={t("TravelCate")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">{t("TravelCate")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.products.travel && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/travelsim"
                          className={`items `}
                          title={t("TravelProduct")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                          </span>
                          <span className="menu-title">
                            {t("TravelProduct")}
                          </span>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </Fragment>
          )}

          {/* ตั้งค่าการทำงาน */}
          {pagesAllow.groups.prediction && (
            <Fragment>
              <hr className="line-section gap" />
              <div className="title-section">{t("Prediction")}</div>
              <ul className="nav-menu">
                <li className={`menu-link has-child`}>
                  {/* opened */}
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title={t("Prediction")}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <span className="collap-title">
                      <FontAwesomeIcon icon={faYinYang} />
                    </span>
                    <div className="menu-title">{t("Prediction")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      <NavLink
                        onClick={closeSidebarhandler}
                        to="/predictnumbcate"
                        className={`items `}
                        title={t("PredictByMeaning")}
                        liClass="sub-items"
                      >
                        <span className="collap-title">
                          <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                        </span>
                        <span className="menu-title">
                          {t("PredictByMeaning")}
                        </span>
                      </NavLink>
                      <NavLink
                        onClick={closeSidebarhandler}
                        to="/grade"
                        className={`items `}
                        title={t("Grade")}
                        liClass="sub-items"
                      >
                        <span className="collap-title">
                          <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                        </span>
                        <span className="menu-title">{t("Grade")}</span>
                      </NavLink>
                      <NavLink
                        onClick={closeSidebarhandler}
                        to="/predict"
                        className={`items `}
                        title={t("Predict")}
                        liClass="sub-items"
                      >
                        <span className="collap-title">
                          <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                        </span>
                        <span className="menu-title">{t("Predict")}</span>
                      </NavLink>
                      <NavLink
                        onClick={closeSidebarhandler}
                        to="/sumber"
                        className={`items `}
                        title={t("Sumber")}
                        liClass="sub-items"
                      >
                        <span className="collap-title">
                          <FontAwesomeIcon icon={faCircleDot} size="2xs" />
                        </span>
                        <span className="menu-title">{t("Sumber")}</span>
                      </NavLink>
                    </ul>
                  </div>
                </li>
              </ul>
            </Fragment>
          )}

          {/* จัดการบทความ */}
          {pagesAllow.groups.article && (
            <Fragment>
              <hr className="line-section gap" />
              <div className="title-section">{t("ManageContent")}</div>
              <ul className="nav-menu">
                {pagesAllow.slides && (
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/slides"
                    className={`navlink `}
                    title={t("SlidesPage")}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <FontAwesomeIcon icon={faImages} />
                    </figure>
                    <div className="menu-title">{t("SlidesPage")}</div>
                  </NavLink>
                )}
                <li className={`menu-link has-child opened`}>
                  {/* opened */}
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title={t("ManageWebContent")}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <span className="collap-title">
                      <FontAwesomeIcon icon={faListOl} />
                    </span>
                    <div className="menu-title">{t("ManageWebContent")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {pagesAllow.menu && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/menu"
                          className={`items `}
                          title={t("ManageWebContent")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faSignsPost} />
                          </span>
                          <span className="menu-title">{t("MenuPage")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.category && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/category"
                          className={`items `}
                          title={t("ManageWebContent")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faSitemap} />
                          </span>
                          <span className="menu-title">
                            {t("CategoryPage")}
                          </span>
                        </NavLink>
                      )}
                      {pagesAllow.posts && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/posts"
                          className={`items `}
                          title={t("ManageWebContent")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faNewspaper} />
                          </span>
                          <span className="menu-title">{t("PostPage")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.article && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/article"
                          className={`items `}
                          title={t("ManageWebContent")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faNewspaper} />
                          </span>
                          <span className="menu-title">{t("บทความ")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.groups.promotion && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/promotion"
                          className={`items `}
                          title={t("ManageWebContent")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faNewspaper} />
                          </span>
                          <span className="menu-title">{t("โปรโมชั่น")}</span>
                        </NavLink>
                      )}
                      {pagesAllow.another && (
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/another"
                          className={`items `}
                          title={t("ManageWebContent")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faNewspaper} />
                          </span>
                          <span className="menu-title">{t("อื่น ๆ")}</span>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </Fragment>
          )}

          {pagesAllow.groups.report && (
            <Fragment>
              <hr className="line-section gap" />
              <div className="title-section">{t("ReportTitle")}</div>
              <ul className="nav-menu">
                {pagesAllow.reports && (
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/reports"
                    className={`navlink `}
                    title={t("ReportPage")}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <FontAwesomeIcon icon={faFileCsv} />
                    </figure>
                    <div className="menu-title">{t("ReportPage")}</div>
                  </NavLink>
                )}
              </ul>
            </Fragment>
          )}

          {pagesAllow.groups.system && (
            <Fragment>
              <hr className="line-section gap" />
              <div className="title-section">{t("SettingsTitle")}</div>
              <ul className="nav-menu">
                {pagesAllow.webinfo && (
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/webinfo"
                    className={`navlink `}
                    title={t("WebInfoPage")}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </figure>
                    <div className="menu-title">{t("WebInfoPage")}</div>
                  </NavLink>
                )}
                {pagesAllow.bankinfo&&(
                  <NavLink
                  onClick={closeSidebarhandler}
                  to="/bankinfo"
                  className={`navlink `}
                  title={t("BankInFoPage")}
                  liClass="menu-link">
                     <figure className="faIcon">
                      <FontAwesomeIcon icon={faBuildingColumns} />
                    </figure>
                    <div className="menu-title">{t("BankInFoPage")}</div>
                  </NavLink>
                )}
                {pagesAllow.admins &&
                  (uPermission.superAdmin ||
                    uPermission.admin ||
                    uPermission.officer) && (
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/admins"
                      className={`navlink `}
                      title={t("AdminPage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faUserShield} />
                      </figure>
                      <div className="menu-title">{t("AdminPage")}</div>
                    </NavLink>
                  )}

                {pagesAllow.languages && (
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/languages"
                    className={`navlink `}
                    title={t("LanguagePage")}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <FontAwesomeIcon icon={faLanguage} />
                    </figure>
                    <div className="menu-title">{t("LanguagePage")}</div>
                  </NavLink>
                )}

                {pagesAllow.configs && uPermission.superAdmin && (
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/configs"
                    className={`navlink `}
                    title={t("ConfigPage")}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <FontAwesomeIcon icon={faTools} />
                    </figure>
                    <div className="menu-title">{t("ConfigPage")}</div>
                  </NavLink>
                )}
                {isDevMode && (
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/componentui"
                    className={`navlink `}
                    title="UI Components"
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <FontAwesomeIcon icon={faIcons} />
                    </figure>
                    <div className="menu-title">UI Components</div>
                  </NavLink>
                )}
                <div className="menu-link">
                  <a
                    // href={`${uploadPath}upload/manual/manual.pdf`}
                    className={`navlink `}
                    target="_blank"
                    title="Manual"
                    onClick={openManual}
                  >
                    <figure className="faIcon">
                      <FontAwesomeIcon icon={faBook} />
                    </figure>
                    <div className="menu-title">{t("Manual")}</div>
                  </a>
                </div>
              </ul>
            </Fragment>
          )}
        </div>
        <hr className="line-section gap" />
      </nav>
      <ul
        className="nav-menu mini-bar"
        style={{ marginTop: "auto", paddingRight: ".5rem" }}
      >
        <li className="menu-link footerLink">
          <a
            href={webPath}
            target="_blank"
            className="navlink pink-btn "
            title={t("GoToWebSite")}
          >
            <figure className="faIcon">
              <FontAwesomeIcon icon={faHome} />
            </figure>
            <span className="menu-title">{t("GoToWebSite")}</span>
          </a>
        </li>
      </ul>
      <p className="powerBy">Backoffice version 1 </p>
    </aside>
  );
};

export default SidebarComponent;
