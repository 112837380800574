import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faMinus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { appActions } from "../../../store/app-slice";
import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../components/ui/button/button";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import CKeditorComponent from "../../../components/ui/ckeditor/ckeditor";
import { svUpdatePrepaidCate } from "../../../services/productServices/prepaid.service";

const modalSwal = withReactContent(Swal);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function PrepaidCateEditModal({ open, setOpen, productEdit, setRefreshData, prepaidNetData }) {
  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };
  const [isError, setIsError] = useState({
    thumbnail: false,
    title: false,
    networks_id:false,
  });

  const { t } = useTranslation("prepaidcate-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  const [previews, setPreviews] = useState(thumbnailDefault);
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);
  const [ckDetailsValue, setCkDetailsValue] = useState(
    productEdit.details_content
  );
  const [ckTermsValue, setCkTermsValue] = useState(productEdit.terms_content);
  const scrollRef = useRef(null);

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = uploadPath + productEdit.thumbnail_link;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });
  }, []);

  /* Set CKeditor value */
  useEffect(() => {
    setEditData((prev) => {
      return {
        ...prev,
        details_content: ckDetailsValue,
        terms_content: ckTermsValue,
      };
    });
  }, [ckDetailsValue, ckTermsValue]);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      title: editData.title.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("thumbnail", previews.file);
        formData.append("thumbnail_name", editData.imageName);
      }

      formData.append("thumbnail_link", editData.thumbnail_link || "");
      formData.append("thumbnail_title", editData.thumbnail_title || "");
      formData.append("thumbnail_alt", editData.thumbnail_alt || "");
      formData.append("id", editData.id);
      formData.append("title", editData.title);
      formData.append("details", editData.details ? editData.details : "");
      formData.append(
        "description",
        editData.description ? editData.description : ""
      );
      formData.append("networks_id", editData.networks_id ? editData.networks_id : "" );
      formData.append("details_content", ckDetailsValue ? ckDetailsValue : "");
      formData.append("terms_content", ckTermsValue ? ckTermsValue : "");
      formData.append("priority", editData.priority);
      formData.append("language", language);

      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePrepaidCate(editData.id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="prepaidcate-edit-modal">
          <section id="prepaidcate-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขหมวดหมู่")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="edit-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                    <div className="image-detail">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, imageName: e.target.value };
                          })
                        }
                        value={editData.imageName}
                        className={`text-field-custom ${
                          !previews.file ? "inp-hidden" : ""
                        }`}
                        fullWidth={true}
                        error={false}
                        id="image-name"
                        label="Image name"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_title: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-title"
                        label="Image title"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_alt: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_alt}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-tag"
                        label="Alt description"
                        size="small"
                      />
                    </div>
                  </FieldsetUI>

                  <div className="prepaidcate-details">
                    <h3 className="prepaidcate-detail-title">
                      {t("รายละเอียด")}
                    </h3>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={editData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={editData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียดหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              description: e.target.value,
                            };
                          })
                        }
                        value={editData.description}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-description"
                        label="คำอธิบายหมวดหมู่"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-xl-half">
                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        size="small"
                        className="form-control"
                      >
                        <InputLabel id="prepaidcate-select">
                          หมวดหมู่ซิมเติมเงิน
                        </InputLabel>
                        <Select
                          labelId="prepaidcate-select"
                          label="หมวดหมู่ซิมเติมเงิน"
                          value={editData.networks_id}
                          error={isError.networks_id}
                          onChange={(e) =>
                            setEditData((prevState) => {
                              return {
                                ...prevState,
                                networks_id: e.target.value,
                              };
                            })
                          }
                        >
                          {prepaidNetData &&
                            prepaidNetData?.map((net) => (
                              <MenuItem key={net.id} value={net.id}>
                                {net.network_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <h3 className="prepaidcate-detail-title">{t("Content")}</h3>
                    <div className="ck-edit-prepaidcate">
                      <label className="ck-edit-prepaidcate-details">
                        Details content
                      </label>
                      <CKeditorComponent
                        ckNameId="ck-edit-prepaidcate"
                        value={ckDetailsValue}
                        onUpdate={setCkDetailsValue}
                      />
                    </div>
                    <div className="ck-edit-prepaidcate">
                      <label className="ck-edit-prepaidcate-termsconditions">
                        ข้อกำหนดและเงื่อนไข
                      </label>
                      <CKeditorComponent
                        ckNameId="ck-edit-prepaidcate"
                        value={ckTermsValue}
                        onUpdate={setCkTermsValue}
                      />
                    </div>
                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        { false && (
                          <>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          unlimited_5g: e.target.checked,
                                        })
                                      }
                                      checked={editData.unlimited_5g ? true : false}
                                    />
                                  }
                                  label={t("5G Unlimite")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          free_wifi: e.target.checked,
                                        })
                                      }
                                      checked={editData.free_wifi ? true : false}
                                    />
                                  }
                                  label={t("Free WiFi")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          free_tiktok: e.target.checked,
                                        })
                                      }
                                      checked={editData.free_tiktok ? true : false}
                                    />
                                  }
                                  label={t("Free Tiktok")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                            <div className="switch-form">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          free_socials: e.target.checked,
                                        })
                                      }
                                      checked={editData.free_socials ? true : false}
                                    />
                                  }
                                  label={t("Free Socials")}
                                  labelPlacement="start"
                                />
                              </FormGroup>
                            </div>
                          </>
                        )}
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {editData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default PrepaidCateEditModal;
