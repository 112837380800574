import axios from "axios";

export const svOrderAll = () => {
  return axios
    .get(`order/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreateOrder = (formOrder) => {
  return axios
    .post(`order/createorder`, formOrder)
    .then((res) => res)
    .catch((err) => err);
};

export const svOrderUpdate = (formOrder) => {
  return axios
    .put(`order/update/${formOrder.id}`, formOrder)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeleteOrder = (order_id) => {
  return axios
  .delete(`order/delete/${order_id}`)
  .then((res) => res)
  .catch((err) => err);
};

export const svGetProductData = () => {
  return axios
    .get(`order/getproductdata`)
    .then((res) => res)
    .catch((err) => err);
};

export const svShippingData = () => {
  return axios
    .get(`order/shippingdata`)
    .then((res) => res)
    .catch((err) => err);
};